<app-main-menu title="{{ role.designation }}" backref="/roles"></app-main-menu>

    <div class="content mat-elevation-z8">
      <mat-form-field appearance="fill">
        <mat-label>Bezeichung</mat-label>
        <input matInput placeholder="Bezeichnung" [(ngModel)]="role.designation">
      </mat-form-field>
      <ul>
        <li *ngFor="let privilege of privileges">
          <mat-checkbox [checked]="hasPrivilege(privilege)" (change)="setPrivilege(privilege, $event.checked)">{{ privilege.designation }}</mat-checkbox><br>
        </li>
      </ul>

      <button mat-raised-button color="accent" (click)="saveRole()">Speichern</button>
      <button mat-raised-button routerLink="/roles">Abbrechen</button>
    </div>
