<mat-card class="mat-elevation-z4 card"
  [class.selected]="selected"
  (contextmenu)="handleContextMenu($event)"
  cdkOverlayOrigin #trigger="cdkOverlayOrigin"

>
  @if(type === 'file') {
    <app-img-upload
      [imgPath]="photoPath"
      [showMediaSelect]="false"
      [disabled]="mode === 'select'"
      (imgChanged)="updatePhoto(photo, $event)"
      (fileExt)="updatePhotoExt(photo, $event)"
    ></app-img-upload>
}
  @else if(type === 'folder') {
    <div class="folder-image">
      <mat-icon [inline]="true">folder</mat-icon>
    </div>
}
  <mat-card-content>
    @if(type === 'file') {
      <p noWrap>id: {{ id }}</p>
    }
    <p class="mat-line">{{ name }}</p>
  </mat-card-content>
  @if(type === 'file' && mode === 'edit' ) {
    <cdk-accordion class="example-accordion">
      <cdk-accordion-item
        #accordionItem="cdkAccordionItem"
        class="example-accordion-item"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-' + 0"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + 0">
        <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
          Metadaten
          <span class="example-accordion-item-description">
            <mat-icon>{{ accordionItem.expanded ? 'expand_more' : 'expand_less' }}</mat-icon>
          </span>
        </div>
        <div
          class="example-accordion-item-body"
          role="region"
          [style.display]="accordionItem.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-' + 0"
          [attr.aria-labelledby]="'accordion-header-' + 0">
          <app-musician-auto-input [musicianid]="authorid" (musicianChanged)="changeAuthor(photo, $event)" style="zoom: 0.8;"></app-musician-auto-input>
          <mat-form-field style="zoom: 0.8;">
            <mat-label>Datum</mat-label>
            <input matInput [matDatepicker]="recpicker" [(ngModel)]="date" (dateChange)="dateChanged(photo, $event)">
            <!--<input matInput [matDatepicker]="recpicker">-->
            <mat-hint>MM.DD.YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="recpicker"></mat-datepicker-toggle>
            <mat-datepicker #recpicker></mat-datepicker>
          </mat-form-field>

        </div>
      </cdk-accordion-item>
    </cdk-accordion>

    <mat-card-actions align="end">
      <button mat-button color="warn" (click)="removePhoto()">Löschen</button>
    </mat-card-actions>
  }


  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="ctxMenuOpened">
  <ul class="overlay-menu" #overlayTemplate>
    @if(type==='file') {
      <li (click)="menuAction()">File 1</li>
      <li (click)="menuAction()">File 2</li>
      <li (click)="menuAction()">File 3</li>
    }
    @else if(type==='folder') {
      <li (click)="menuAction()">Folder 1</li>
      <li (click)="menuAction()">Folder 2</li>
      <li (click)="menuAction()">Folder 3</li>
    }
  </ul>
  </ng-template>
</mat-card>
