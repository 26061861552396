<mat-toolbar color="primary" class="app-toolbar" [class.testserver-background]="isTestserver">
  <button mat-icon-button *ngIf="backref" (click)="back()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <!--<button mat-icon-button (click)="drawer.toggle()">
    <mat-icon>menu</mat-icon>
  </button>-->

  <span>{{ title }}</span>
  <span class="navbar-spacer"></span>
  <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon" (click)="openHelp()">
    <mat-icon>help</mat-icon>
  </button>
</mat-toolbar>

