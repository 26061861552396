import { Component, EventEmitter, Input, OnChanges, Output, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AuthenticationService, Sheet, Tune } from 'bandon-shared';
import { PhotoDirNode } from '../../photo-library/photo-browser/photo-browser.component';
import { MatFabMenu, MatFabMenuModule } from '@angular-material-extensions/fab-menu';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from '../../ModalTemplates/loading-template/loading-template.component';
import { environment } from 'src/environments/environment';
import { transliterate } from 'transliteration';
import { AddFolderDialogComponent } from 'src/app/pages/photos/photo-library/add-folder-dialog/add-folder-dialog.component';
import { AddSheetDialogComponent } from 'src/app/pages/sheets/sheets-library/add-sheet-dialog/add-sheet-dialog.component';
import { SheetFolderViewComponent } from '../sheet-folder-view/sheet-folder-view.component';
import { DirectoryTreeComponent } from '../../photo-library/directory-tree/directory-tree.component';
import { MatSidenavModule } from '@angular/material/sidenav';

const EMPTY_SHEETS_FOLDER = 'Empty-Sheets'
const RHYTHM_SHEETS_FOLDER = 'Rhythm-Sheets'
const PARTS_SHEETS_FOLDER = 'Parts-Sheets'

export interface SheetAddEvent {
  sheet: Sheet;
  trackids: number[];
}

@Component({
    selector: 'app-sheet-browser',
    templateUrl: './sheet-browser.component.html',
    styleUrl: './sheet-browser.component.scss',
    standalone: true,
    imports: [MatSidenavModule, DirectoryTreeComponent, SheetFolderViewComponent, MatFabMenuModule]
})
export class SheetBrowserComponent implements OnInit, OnDestroy, OnChanges {

  @Input() mode: 'select' | 'edit' = 'select';
  @Input() tune: Tune | undefined = undefined;

  @Output() onSelect: EventEmitter<Sheet> = new EventEmitter();
  @Output() onSheetAdd: EventEmitter<SheetAddEvent> = new EventEmitter();

  sheets: Sheet[] = [];

  directoryTreeData: PhotoDirNode[] = [];
  selectedPath: PhotoDirNode | undefined;

  fabButtonsRandom: MatFabMenu[] = [
    {
      id: 0,
      icon: 'file_copy',
    },
    {
      id: 1,
      icon: 'folder'
    },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.refreshSheets();
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.generateTuneFolders();
  }

  refreshSheets(): void {
    //Get all Tunes
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Sheet[]>(environment.apiURL+"/sheets", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (photos) => {
          photos.forEach( p => {
            p.author = { id: p.authorid, surname: '', firstname: '', instrument: {
                id: -1, designation: '', img: '', img_dark: '', instrumentorder: 1
              }
            }
          })
          this.sheets.length = 0;
          this.sheets = photos;
          this.updateTreeData()
          this.generateTuneFolders();
          loadingDialog.close();
        }
      );

  }

  generateTuneFolders() {
    if(this.tune && this.tune.title && this.tune.id) {
      //Check or generate Folders
      const basePath = `${this.tune.id}-${this.tune.title}/`;
      this.checkOrCreateFolder(basePath);
      this.checkOrCreateFolder(`${basePath}${EMPTY_SHEETS_FOLDER}/`);
      this.checkOrCreateFolder(`${basePath}${RHYTHM_SHEETS_FOLDER}/`);
      this.checkOrCreateFolder(`${basePath}${PARTS_SHEETS_FOLDER}/`);
      const baseNode = this.getNodeToPath(this.directoryTreeData, basePath.substring(0, basePath.lastIndexOf('/')));
      if(baseNode) {
        this.selectPath(baseNode)
      } else {
        console.log('Error: no baseNode found, ', basePath);
      }
    }
    this.sortTree(this.directoryTreeData);
  }

  commitUpdate(sheet: Sheet, result: any = undefined) {
    this.snackBar.open('Speichert Daten')
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    if(sheet.newFile) {
      let filename = transliterate(sheet.imgSaveFilename!)
      formData.append('file', sheet.newFile, filename);
      sheet.newFile = undefined;

      let newPath = sheet.path.slice(0, sheet.path.lastIndexOf('/')+1).concat(filename);
      sheet.path = newPath;
    }

//    console.log(sheet);
    formData.append('sheet', JSON.stringify(sheet));

    if(sheet.id>0) {
      this.httpClient.post<Sheet>(environment.apiURL+`/sheets/${sheet.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          if(result.trackids) {
            console.log(sheet, result.trackids);
          }

          this.snackBar.dismiss();
          this.refreshSheets();
          },
        error: error => {
          console.log(error);
          this.snackBar.dismiss();
          this.refreshSheets();
          }
      });
    } else {
      this.httpClient.put<Sheet>(environment.apiURL+`/sheets`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          if(result.trackids) {
            this.onSheetAdd.emit({sheet: resp, trackids: result.trackids});
          }

          this.snackBar.dismiss();
          this.refreshSheets();
          },
        error: error => {
          console.log(error);
          this.snackBar.dismiss();
          this.refreshSheets();
          }
      });
    }
  }

  updateTreeData() {
    const newDirectoryTree: PhotoDirNode[] = [];
    newDirectoryTree.push({name: '/', children: [], path: ''})

    this.sheets.forEach( s => {
      //Generate Photo Directory tree
      const parthParts = s.path.split('/');
      let parentNode = newDirectoryTree;
      parthParts.forEach((part, index) => {
        if(index<parthParts.length-1) {
          let searchPath = '';
          for(let i=0; i<index; i++) {
            searchPath = this.addFolderToPath(searchPath, parthParts[i]);
          }
          searchPath = this.addFolderToPath(searchPath, part);
          const exNode = parentNode.find(n => n.name===part);

          if(exNode) {
            if(!exNode.children) {
              exNode.children = [];
            }
            parentNode = exNode.children;
          } else {
            const newNode: PhotoDirNode = {name: part, children: [], path: searchPath};
            parentNode.push(newNode)
            parentNode = newNode.children!;
          }

        }
      });
    })
    this.directoryTreeData = newDirectoryTree;
  }

  selectPath(node: PhotoDirNode) {
    this.selectedPath = node;
  }

  selectSheet(sheet: Sheet) {
    this.onSelect.emit(sheet)
  }

  assignSheet(sheet: Sheet) {
    let openPath = '';
    if(this.selectedPath) {
      openPath = this.selectedPath?.path+'/';
    }
    let mode = 'single';
    if(sheet.type?.id === 1) {
      mode = 'empty';
    } else if(sheet.type?.id === 2) {
      mode = 'rhythm';
    } else if(sheet.type?.id === 3) {
      mode = 'part';
    }
    let assign = true

    const dialogRef = this.dialog.open(AddSheetDialogComponent, {
      data: { path: openPath, mode, tune: this.tune, assign, sheet }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onSheetAdd.emit({sheet, trackids: result.trackids})
      }
    });
  }

  doFabAction(event: any) {
    if(event===0) {
      this.addSheet();
    } else {
      this.addFolder();
    }
  }

  addFolder() {
    let openPath = '';
    if(this.selectedPath) {
      openPath = this.selectedPath?.path+'/';
    }
    const dialogRef = this.dialog.open(AddFolderDialogComponent, {
      data: {  }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.createFolder(openPath+result+'/');
      }
    });
  }

  addSheet() {
    let openPath = '';
    if(this.selectedPath) {
      openPath = this.selectedPath?.path+'/';
    }
    let mode = 'single';
    if(openPath.includes(EMPTY_SHEETS_FOLDER)) {
      mode = 'empty';
    } else if(openPath.includes(RHYTHM_SHEETS_FOLDER)) {
      mode = 'rhythm';
    } else if(openPath.includes(PARTS_SHEETS_FOLDER)) {
      mode = 'part';
    }

    const dialogRef = this.dialog.open(AddSheetDialogComponent, {
      data: { path: openPath, mode, tune: this.tune }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let authorid = -1;
        if(result.author) {
          authorid = result.author.id;
        }
        const sheet: Sheet = {
          id: -1,
          path: result.path+result.filename,
          authorid: authorid,
          author: result.author,
          date: new Date(),
          newFile: result.file,
          imgSaveFilename: result.filename,
          type: { id: result.typid, designation: ''}
        }
        this.commitUpdate(sheet, result)
      }
    });
  }

  private addFolderToPath(basePath: string, folderName: string): string {
    if(basePath) {
      // Ensure basePath ends with a slash
      basePath = basePath.endsWith('/') ? basePath : basePath + '/';

      // Ensure folderName does not start with a slash
      folderName = folderName.startsWith('/') ? folderName.substring(1) : folderName;

      return basePath + folderName;
    }
    return folderName;
  }

  private checkOrCreateFolder(path: string): PhotoDirNode {
    if(!this.checkIfFolderExists(path)) {
      return this.createFolder(path);
    }
    return this.getNodeToPath(this.directoryTreeData, path.substring(0, path.lastIndexOf('/')))!;
  }

  private checkIfFolderExists(path: string): boolean {
    let out = false;
    this.sheets.forEach(s => {
      if(s.path.substring(0, s.path.lastIndexOf('/')+1)===path) {
        out = true;
      }
    });
    return out;
  }

  private createFolder(path: string): PhotoDirNode {
    let sheet: Sheet = { id: -1, path, date: new Date(), authorid: -1
    }
    this.sheets.push(sheet)
    this.updateTreeData();
    return this.getNodeToPath(this.directoryTreeData, path.substring(0, path.lastIndexOf('/')))!;
  }

  private getNodeToPath(startPoint: PhotoDirNode[], path: string): PhotoDirNode | undefined {
    for (const node of startPoint) {
      if(node.path === path) {
        return node;
      } else if(node.children && node.children.length>0) {
        return this.getNodeToPath(node.children, path);
      }
    }
    return undefined;
  }

  private sortTree(startPoint: PhotoDirNode[]) {
    startPoint.sort((p1, p2) => p1.name.localeCompare(p2.name))
    startPoint.forEach(p => {
      if(p.children) {
        this.sortTree(p.children);
      }
    });
  }
}
