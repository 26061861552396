<app-main-menu title="Beteiligte"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Alle Beteiligten</h1>

  <mat-form-field style="width:100%">
    <mat-label>Suchen</mat-label>
    <input matInput placeholder="Suche" [(ngModel)]="searchTerm" (input)="searchMusician($event)">
  </mat-form-field>

  <mat-list>
    <mat-list-item *ngFor="let musician of paginatedMusicians" class="musician-list-item">
      <img matListItemIcon [src]="getMusicianImg(musician)">
      <span matListItemTitle>{{ musician.firstname }} {{ musician.surname }}
        <p class="mat-list-item-content-right">
          <button mat-raised-button color="accent" routerLink="/musicians/{{ musician.id }}">
            Edit
          </button>
          <!-- <button mat-raised-button color="warn">
            Löschen
          </button> -->
        </p>
      </span>
      <span matListItemLine>{{ getInstrumentDesignation(musician) }}</span>
    </mat-list-item>
  </mat-list>

  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="musicians.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>


  <button mat-button routerLink="/musicians/-1">
    <mat-icon>add_box</mat-icon>
    Person hinzufügen
  </button>
</div>
