import { AuthenticationService, Musician } from 'bandon-shared';
import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MusiciansService } from 'src/app/services/data/musicians.service';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-musicians-list',
    templateUrl: './musicians-list.component.html',
    styleUrls: ['./musicians-list.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatListModule, NgFor, MatButtonModule, RouterLink, MatPaginatorModule, MatIconModule]
})
export class MusiciansListComponent {

  public searchTerm = '';
  public musicians: Musician[] = [];
  _searchedMusicians: Musician[] = [];

  private unsubscribe$ = new Subject<void>();

  private pageSize = 10;
  private pageIndex = 0;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private musiciansService: MusiciansService
  ) {
  }

  get paginatedMusicians(): Musician[] {
    return this.searchedMusicians.slice(this.pageIndex*this.pageSize, (this.pageIndex+1)*this.pageSize);
  }

  get searchedMusicians(): Musician[] {
    if(this.searchTerm && this.searchTerm.trim() !== '') {
      return this._searchedMusicians;
    }
    return this.musicians;
  }

  ngOnInit(): void {
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    this.musiciansService.musicians$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (musicians) => {
          this.musicians.length = 0;
          this.musicians.push(...musicians);
          loadingDialog.close()
        }
      );

    this.musiciansService.getMusicians(true);

    //Get all Tunes
/*    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Musician[]>(environment.apiURL+"/musicians", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tunes) => {
          this.musicians.length = 0;
          this.musicians.push(...tunes);
          loadingDialog.close()
        }
      );*/
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getMusicianImg(musician: Musician): string {
    if(musician.picture) {
      return environment.apiURL+"/photoImg/"+musician.picture.id;
    }
    return '';
  }

  getInstrumentDesignation(musician: Musician) {
    if(musician.instrument) {
      return musician.instrument.designation
    }
    return '';
  }

  handlePageEvent(event: PageEvent) {

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchMusician(event: any) {
    const text = event.target.value;
    this._searchedMusicians = this.musicians;
    if(text && text.trim() !== '') {
      this._searchedMusicians = this._searchedMusicians.filter((musician: Musician) => {
        let des = musician.firstname+' '+musician.surname+' '+musician.homepage+' ';
        if(musician.instrument) {
          des += musician.instrument.designation;
        }
        return (des.toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
    }
    this.pageIndex = 0;
  }
}
