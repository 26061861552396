<app-main-menu title="{{ voice.designation }}" backref="/voices"></app-main-menu>

<div class="content mat-elevation-z8">
  <mat-form-field appearance="fill">
    <mat-label>Bezeichung</mat-label>
    <input matInput placeholder="Bezeichnung" [(ngModel)]="voice.designation">
  </mat-form-field><br>

  <mat-form-field appearance="fill" >
    <mat-select [(value)]="instrumentgroupID">
      <mat-option *ngFor="let group of instrumentGroups"  [value]="group.id">
        {{ group.designation }}{{ getLinkedText(group) }}
      </mat-option>
    </mat-select>
  </mat-form-field><br>

  <!-- <mat-accordion>
    <mat-expansion-panel *ngFor="let translation of instrument.translations" >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ getLanguageDesignation(translation.languageid) }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field appereance="fill" style="width: 50%">
        <mat-label>Sprache</mat-label>
        <mat-select [(value)]="translation.languageid">
          <mat-option *ngFor="let language of languages" [value]="language.id">
            {{ language.designation }}
          </mat-option>
        </mat-select>
      </mat-form-field><br>

      <mat-form-field appearance="fill" style="width: 50%;">
        <mat-label>Bezeichnung</mat-label>
        <input matInput placeholder="Bezeichnung" [(ngModel)]="translation.designation">
      </mat-form-field>

      <button mat-raised-button color="warn" (click)="deleteTranslation(translation)">Löschen</button>
    </mat-expansion-panel>
  </mat-accordion>
  <button mat-button (click)="addTranslation()">
    <mat-icon>add_box</mat-icon>
    Übersetzung hinzufügen
  </button> -->

  <button mat-raised-button color="accent" (click)="saveVoice()">Speichern</button>
  <button mat-raised-button routerLink="/voices">Abbrechen</button>
</div>
