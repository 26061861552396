<mat-card class="container">
  <mat-card-title>
    {{ 'AUTH.DELETEUSER.DELETUSER' | translate }}
  </mat-card-title>
  <mat-card-content>
      <div *ngIf="validForm && !deleted">
        <button mat-raised-button color="primary" (click)="onSubmit()">
          {{ 'AUTH.DELETEUSER.DELETE' | translate }}
        </button>
      </div>
      <div *ngIf="!validForm && errmsg">
          {{ errmsg }}
      </div>
      <div *ngIf="!validForm && !errmsg">
          <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="deleted">
        {{ 'AUTH.DELETEUSER.DELETED' | translate }}
        <button mat-raised-button color="primary" (click)="closeTab()">
          {{ 'AUTH.DELETEUSER.CLOSE' | translate }}
        </button>
      </div>
  </mat-card-content>
</mat-card>
