<app-main-menu title="{{ instrument.designation }}" backref="/instruments"></app-main-menu>

<div class="content mat-elevation-z8">
  <mat-form-field appearance="fill">
    <mat-label>Bezeichung</mat-label>
    <input matInput placeholder="Bezeichnung" [(ngModel)]="instrument.designation">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Kurzform</mat-label>
    <input matInput placeholder="Kurzform" [(ngModel)]="instrument.abbreviation">
  </mat-form-field>
  <mat-checkbox [(ngModel)]="instrument.softwareinstr">Software-Instrument</mat-checkbox><br>

  <mat-accordion>
    <mat-expansion-panel *ngFor="let translation of instrument.translations" >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ getLanguageDesignation(translation.languageid) }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field appereance="fill" style="width: 50%">
        <mat-label>Sprache</mat-label>
        <mat-select [(value)]="translation.languageid">
          <mat-option *ngFor="let language of languages" [value]="language.id">
            {{ language.designation }}
          </mat-option>
        </mat-select>
      </mat-form-field><br>

      <mat-form-field appearance="fill" style="width: 50%;">
        <mat-label>Bezeichnung</mat-label>
        <input matInput placeholder="Bezeichnung" [(ngModel)]="translation.designation">
      </mat-form-field>

      <button mat-raised-button color="warn" (click)="deleteTranslation(translation)">Löschen</button>
    </mat-expansion-panel>
  </mat-accordion>
  <button mat-button (click)="addTranslation()">
    <mat-icon>add_box</mat-icon>
    Übersetzung hinzufügen
  </button>

  <h3>Bild</h3>
  <app-img-upload tite="Bild"
    [imgPath]="instrImgPath"
    [showMediaSelect]="false"
    (imgChanged)="changeImg($event)"
    (fileExt)="changeFileExt($event)"
    [hasCrop]="false"></app-img-upload>
  <!--<h3>Bild Dark</h3>
  <app-img-upload tite="Bild Dark-mode" [imgPath]="instrImgDarkPath" (imgChanged)="changeImgDark($event)" (fileExt)="changeFileDarkExt($event)"></app-img-upload>-->

  <button mat-raised-button color="accent" (click)="saveInstrument()">Speichern</button>
  <button mat-raised-button routerLink="/instruments">Abbrechen</button>
</div>
