<app-main-menu title="{{ user.email }}" backref="/users"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>{{ user.email }}</h1>
  <h2>{{ user.name }}</h2>
  <p>Registriert: {{ dateString }}</p>
  <h3>Userrollen</h3>
  <ul>
    <li *ngFor="let role of roles">
      <mat-checkbox [checked]="hasRole(role)" (change)="setRole(role, $event.checked)">{{ role.designation }}</mat-checkbox><br>
    </li>
  </ul>

  <h3>Einkäufe</h3>
  <mat-form-field style="width:100%">
    <mat-label>Suchen</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Suche" #purchaseInput>
    <!--<input matInput placeholder="Suche" [(ngModel)]="searchTerm" (input)="searchUser($event)" #input>-->
  </mat-form-field>
  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="purchasesSource"  matSort>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
        <td mat-cell *matCellDef="let item">{{ item.date | date: 'dd.MM.y'}}</td>
      </ng-container>

      <ng-container matColumnDef="item">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Einkauf</th>
        <td mat-cell *matCellDef="let item">
          <p *ngIf="item.collectionid>0" >{{ getCollectionTitle(item.collectionid) }}</p>
          <p *ngIf="item.tuneid>0" >{{ getTuneDesc(item.tuneid) }}</p>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [length]="userPurchases.length"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </div>


  <button mat-raised-button color="accent" (click)="saveUser()">Speichern</button>
  <button mat-raised-button routerLink="/users">Abbrechen</button>
</div>
