import { Role, Privilege, AuthenticationService } from 'bandon-shared';
import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgFor } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-edit-role',
    templateUrl: './edit-role.component.html',
    styleUrls: ['./edit-role.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, NgFor, MatCheckboxModule, MatButtonModule, RouterLink]
})
export class EditRoleComponent {
  public role: Role;

  public privileges: Privilege[] = [];

  private unsubscribe$ = new Subject<void>();
  private roleID = -1;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ){
    this.role = { id: -1, designation: ''}
  }

  ngOnInit(): void {
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    this.roleID = Number(this.activatedRoute.snapshot.paramMap.get('roleid'));
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    console.log(this.roleID);

    if(this.roleID>0) {
      console.log('load role: ', this.roleID);

      //Load Role
      this.httpClient.get<Role>(environment.apiURL+'/roles/'+this.roleID, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.role = data;

          //Load Musician Image
  //        this.tuneImgFile = this.getTuneIcon(this.tune);
          console.log('Role loaded ', this.role);
          loadingDialog.close();
        });

    } else {
      this.role = { id: -1, designation: ''}
    }

    //Load all Privileges
    this.httpClient.get<Privilege[]>(environment.apiURL+'/privileges', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.privileges = data;
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveRole() {
    this.snackBar.open('Speichert Daten')
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();

    formData.append('role', JSON.stringify(this.role));
    if(this.roleID>0) {
      this.httpClient.post<Role>(environment.apiURL+`/roles/${this.role.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.role = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/roles');
      });
    } else {
      this.httpClient.put<Role>(environment.apiURL+`/roles`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.role = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/roles');
      });
    }
  }

  hasPrivilege(privilege: Privilege): boolean {
    if(this.role) {
      let result = this.role.privileges?.filter(e => e.id==privilege.id)
      return result ? result.length>0 : false;
    }
    return false;
  }

  setPrivilege(privilege: Privilege, active: boolean) {
    if(active) {
      let result = this.role.privileges?.filter(e => e.id==privilege.id)
      if(!result || (result && result.length==0)) {
        if(!this.role.privileges) {
          this.role.privileges = []
        }
        this.role.privileges.push(privilege);
      }
    } else if(this.role.privileges) {
      const p = this.role.privileges.find(e => e.id===privilege.id);
      if(p) {
        const index = this.role.privileges?.indexOf(p);
        this.role.privileges.splice(index, 1);
      }
    }
  }
}
