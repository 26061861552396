<mat-card>
  <mat-card-header>
    <mat-card-title>Foto zuschneiden</mat-card-title>
  </mat-card-header>

  <image-cropper
  [imageChangedEvent]="data.imageChangedEvent"
  [maintainAspectRatio]="true"
  [resizeToWidth]="1024"
  [resizeToHeight]="1024"
  [aspectRatio]="selectedAspectRatio"
  [onlyScaleDown]="true"
  [hidden]="false"
  format="png"
  output="base64"
  (imageCropped)="imageCropped($event)"
></image-cropper>
<mat-form-field *ngIf="forcedAspectRatio==='free'" >
  <mat-label>Format</mat-label>
  <mat-select [(value)]="selectedAspectRatio">
    <mat-option [value]="originalAspectRatio">Original</mat-option>
    <mat-option [value]="1">1:1</mat-option>
    <mat-option [value]="2">2:1</mat-option>
    </mat-select>
</mat-form-field>

<mat-card-actions class="button-container">
  <button mat-button color="primary" (click)="cancelCropper()">Abbrechen</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="{file, imgFile}">Übernehmen</button>
</mat-card-actions>

</mat-card>

