<mat-drawer-container class="example-container">
  <mat-drawer mode="side" opened style="width: 200px">
    <app-directory-tree
      [treeData]="directoryTreeData"
      [selectedNode]="selectedPath"
      (onNodeSelect)="selectPath($event)"
    ></app-directory-tree>
  </mat-drawer>
  <mat-drawer-content>
    <app-folder-view
      [photos]="photos"
      [folders]="directoryTreeData"
      [selectedFolder]="selectedPath"
      [mode]="mode"
      (onNodeSelect)="selectPath($event)"
      (onDelete)="refreshPictures()"
      (onSelect)="selectPhoto($event)"
    ></app-folder-view>

  </mat-drawer-content>
</mat-drawer-container>

<mat-fab-menu color="primary"
  [fabButtons]="fabButtonsRandom"
  (onFabMenuItemSelected)="doFabAction($event)"
  class="fab-menu">
</mat-fab-menu>
