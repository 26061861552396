import { Injectable } from '@angular/core';

export interface IMenuItem {
  type: 'link' | 'dropDown' | 'icon' | 'separator' | 'extLink';
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  expanded?: boolean;
  needsUserAuth?: boolean;
}
export interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
  expanded?: boolean;
  needsUserAuth?: boolean;
}

export interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  menuItems: IMenuItem[] = [
    {
      name: 'Collections',
      state: '/collections',
      type: 'link',
      icon: 'library_music'
    },
    {
      name: 'Stücke',
      state: '/tunes',
      type: 'link',
      icon: 'music_note'
    },
    {
      name: 'Schaufenster',
      state: '/shop/windows',
      type: 'link',
      icon: 'storefront'
    },
    {
      name: 'Stimmen',
      type: 'dropDown',
      icon: 'mic',
      sub: [
        {
          name: 'Übersicht',
          state: '/voices',
          icon: 'mic',
          type: 'link'
        },
        {
          name: 'Sortierung',
          state: '/sorting',
          icon: 'filter_list',
          type: 'link'
        },
        {
          name: 'Instrumente',
          state: '/instruments',
          icon: 'piano',
          type: 'link'
        },
        {
          name: 'Sheets',
          state: '/sheets',
          type: 'link',
          icon: 'description'
        }
      ]
    },
    {
      name: 'Beteiligte',
      type: 'dropDown',
      icon: 'person',
      sub: [
        {
          name: 'Recording-Session',
          type: 'link',
          state: '/sessions',
          icon: 'mic'
        },
        {
          name: 'Musiker*innen',
          type: 'link',
          icon: 'person',
          state: '/musicians'
        },
/*        {
          name: 'Komponist*innen',
          type: 'link',
          icon: 'audio_file',
          state: '/composers',
        },*/
        {
          name: 'Partner',
          type: 'link',
          icon: 'handshake',
          state: '/partners'
        }
      ]
    },
    {
      name: 'Style und Fotos',
      type: 'dropDown',
      icon: 'style',
      sub: [
        {
          name: 'Genres',
          type: 'link',
          icon: 'style',
          state: '/styles'
        },
        {
          name: 'Tags',
          type: 'link',
          icon: 'sell',
          state: '/tags'
        },
        {
          name: 'Fotos',
          type: 'link',
          icon: 'image',
          state: '/photos'
        }
      ]
    },
    {
      name: 'Analytics',
      state: '/analytics',
      type: 'link',
      icon: 'query_stats'
    },
    {
      name: 'Benutzer*innen',
      type: 'dropDown',
      icon: 'group',
      sub: [
        {
          name: 'Übersicht',
          state: '/users',
          icon: 'group',
          type: 'link',
          needsUserAuth: true
        },
        {
          name: 'Rollen',
          state: '/roles',
          icon: 'badge',
          type: 'link',
          needsUserAuth: true
        },
        {
          name: 'Gruppen',
          state: '/usergroups',
          icon: 'group',
          type: 'link',
          needsUserAuth: false
        },
        {
          name: 'Testaccount',
          state: '/users/testaccount',
          icon: 'badge',
          type: 'link'
        },
        {
          name: 'Preiskategorien',
          state: '/pricing/categories',
          icon: 'price_change',
          type: 'link',
          needsUserAuth: true
        }
      ]
    },
    {
      name: 'App-Versionen',
      state: '/versions',
      icon: 'settings',
      type: 'link',
      needsUserAuth: true //TODO: add needsAppAuth?
    }
  ]
}
