import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, PricingCategory } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-pricing-cat-list',
    templateUrl: './pricing-cat-list.component.html',
    styleUrls: ['./pricing-cat-list.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatListModule, NgFor, MatButtonModule, RouterLink, MatPaginatorModule, MatIconModule]
})
export class PricingCatListComponent implements OnInit, OnDestroy {

  public searchTerm = '';
  public categories: PricingCategory[] = [];
  _searchedCategories: PricingCategory[] = [];

  private unsubscribe$ = new Subject<void>();

  private pageSize = 10;
  private pageIndex = 0;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) {
  }

  get paginatedCategories(): PricingCategory[] {
    return this.searchedCategories.slice(this.pageIndex*this.pageSize, (this.pageIndex+1)*this.pageSize);
  }

  get searchedCategories(): PricingCategory[] {
    if(this.searchTerm && this.searchTerm.trim() !== '') {
      return this._searchedCategories;
    }
    return this.categories;
  }


  ngOnInit(): void {
    //Get all Tunes
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<PricingCategory[]>(environment.apiURL+"/pricing/categories", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (categories) => {
          this.categories.length = 0;
          this.categories.push(...categories);
          console.log(categories);
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchCategory(event: any) {
    const text = event.target.value;
    this._searchedCategories = this.categories;
    if(text && text.trim() !== '') {
      this._searchedCategories = this._searchedCategories.filter((cat: PricingCategory) => {
        const des = cat.title+' '+cat.description+' '+cat.glassfysku;
        return (des.toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
    }
    this.pageIndex = 0;
  }

}
