import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService, Instrument, InstrumentGroup, Voice } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { moveItemInArray, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop'
import { NgFor, NgIf } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MainMenuComponent } from '../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-instrument-sorting',
    templateUrl: './instrument-sorting.component.html',
    styleUrls: ['./instrument-sorting.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, FlexModule, CdkDropList, NgFor, CdkDrag, NgIf]
})
export class InstrumentSortingComponent implements OnInit, OnDestroy {

  public instrGroups: InstrumentGroup[] = [];
  public instruments: Instrument[] = [];
  public voices: Voice[] = [];
  public selectedGroup: InstrumentGroup | undefined;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,

  ) {}

  get selectedVoices(): Voice[] {
    if(this.selectedGroup) {
      return this.voices.filter(v => v.instrumentgroup?.id==this.selectedGroup?.id).sort((v1, v2) => v1.voiceorder-v2.voiceorder)
    }
    return []
  }

  ngOnInit(): void {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    //Get all Instrument groups
    this.httpClient.get<InstrumentGroup[]>(environment.apiURL+"/instrumentgroups", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (groups) => {
          this.instrGroups.length = 0;
          this.instrGroups.push(...groups.sort((g1, g2) => g1.grouporder-g2.grouporder).filter(g => g.id>0));
        }
      );

    //Get all Instruments
    this.httpClient.get<Instrument[]>(environment.apiURL+"/instruments", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (instruments) => {
        this.instruments.length = 0;
        this.instruments.push(...instruments.sort((i1, i2) => i1.instrumentorder-i2.instrumentorder));
      }
    );

    //Get all Voices
    this.httpClient.get<Voice[]>(environment.apiURL+"/voices", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (voices) => {
        this.voices.length = 0;
        this.voices.push(...voices.sort((v1, v2) => v1.voiceorder-v2.voiceorder));
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  dropGroup(event: any) {
    moveItemInArray(this.instrGroups, event.previousIndex, event.currentIndex)

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    let order = 1

    for (let group of this.instrGroups) {
      group.grouporder = order;

      const formData = new FormData();

      formData.append('group', JSON.stringify(group));
      this.httpClient.post<InstrumentGroup>(environment.apiURL+`/instrumentgroups/${group.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        group = resp;
      });

      order++;
    }
  }

  dropInstrument(event: any) {
    moveItemInArray(this.instruments, event.previousIndex, event.currentIndex)

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    let order = 1

    for (let instrument of this.instruments) {
      instrument.instrumentorder = order;

      const formData = new FormData();

      formData.append('instrument', JSON.stringify(instrument));
      this.httpClient.post<Instrument>(environment.apiURL+`/instruments/${instrument.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        instrument = resp;
      });

      order++;
    }
  }

  dropVoice(event: any) {
    let voiceArr = [...this.selectedVoices]
    moveItemInArray(voiceArr, event.previousIndex, event.currentIndex)

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    let order = 1

    for (let voice of voiceArr) {
      voice.voiceorder = order;
      const formData = new FormData();

      formData.append('voice', JSON.stringify(voice));
      this.httpClient.post<Voice>(environment.apiURL+`/voices/${voice.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        voice = resp;
      });

      order++;
    }

  }

  selectGroup(group: InstrumentGroup) {
    this.selectedGroup = group
  }
}
