import { AuthenticationService, Musician, Photo } from 'bandon-shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { transliterate } from 'transliteration'
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatFabMenu, MatFabMenuModule } from '@angular-material-extensions/fab-menu';
import { AddFolderDialogComponent } from 'src/app/pages/photos/photo-library/add-folder-dialog/add-folder-dialog.component';
import { AddImageDialogComponent } from 'src/app/pages/photos/photo-library/add-image-dialog/add-image-dialog.component';
import { FolderViewComponent } from '../folder-view/folder-view.component';
import { DirectoryTreeComponent } from '../directory-tree/directory-tree.component';
import { MatSidenavModule } from '@angular/material/sidenav';

export interface PhotoDirNode {
  name: string;
  children?: PhotoDirNode[];
  path: string;
}

@Component({
    selector: 'app-photo-browser',
    templateUrl: './photo-browser.component.html',
    styleUrl: './photo-browser.component.scss',
    standalone: true,
    imports: [MatSidenavModule, DirectoryTreeComponent, FolderViewComponent, MatFabMenuModule]
})
export class PhotoBrowserComponent {

  @Input() mode: 'select' | 'edit' = 'select';

  @Output() onSelect: EventEmitter<Photo> = new EventEmitter();

  photos: Photo[] = [];

  directoryTreeData: PhotoDirNode[] = [];
  selectedPath: PhotoDirNode | undefined;

  fabButtonsRandom: MatFabMenu[] = [
    {
      id: 0,
      icon: 'file_copy',
    },
    {
      id: 1,
      icon: 'folder'
    },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.refreshPictures();
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  refreshPictures(): void {
    //Get all Tunes
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Photo[]>(environment.apiURL+"/photos", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (photos) => {
          photos.forEach( p => {
            p.author = { id: p.authorid, surname: '', firstname: '', instrument: {
                id: -1, designation: '', img: '', img_dark: '', instrumentorder: 1
              }
            }
          })
          this.photos.length = 0;
          this.photos = photos;
          this.updateTreeData()
          loadingDialog.close();
        }
      );
  }

  commitUpdate(photo: Photo) {
    this.snackBar.open('Speichert Daten')
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    if(photo.newImage) {
      let filename = transliterate(photo.imgSaveFilename!)
      formData.append('img', photo.newImage, filename);
      photo.newImage = undefined;

      let newPath = photo.path.slice(0, photo.path.lastIndexOf('/')+1).concat(filename);
      photo.path = newPath;
    }

    formData.append('photo', JSON.stringify(photo));

    if(photo.id>0) {
      this.httpClient.post<Photo>(environment.apiURL+`/photos/${photo.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.snackBar.dismiss();
          this.refreshPictures();
          },
        error: error => {
          console.log(error);
          this.snackBar.dismiss();
          this.refreshPictures();
          }
      });
    } else {
      this.httpClient.put<Photo>(environment.apiURL+`/photos`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.snackBar.dismiss();
          this.refreshPictures();
          },
        error: error => {
          console.log(error);
          this.snackBar.dismiss();
          this.refreshPictures();
          }
      });
    }
  }

  updateTreeData() {
    const newDirectoryTree: PhotoDirNode[] = [];
    newDirectoryTree.push({name: '/', children: [], path: ''})

    this.photos.forEach( p => {
      //Generate Photo Directory tree
      const parthParts = p.path.split('/');
      let parentNode = newDirectoryTree;
      parthParts.forEach((part, index) => {
        if(index<parthParts.length-1) {
          let searchPath = '';
          for(let i=0; i<index; i++) {
            searchPath = this.addFolderToPath(searchPath, parthParts[i]);
          }
          searchPath = this.addFolderToPath(searchPath, part);
          const exNode = parentNode.find(n => n.name===part);

          if(exNode) {
            if(!exNode.children) {
              exNode.children = [];
            }
            parentNode = exNode.children;
          } else {
            const newNode: PhotoDirNode = {name: part, children: [], path: searchPath};
            parentNode.push(newNode)
            parentNode = newNode.children!;
          }

        }
      });
    })
    this.directoryTreeData = newDirectoryTree;
    console.log(this.directoryTreeData);
  }


  selectPath(node: PhotoDirNode) {
    this.selectedPath = node;
  }

  selectPhoto(photo: Photo) {
    this.onSelect.emit(photo)
  }

  doFabAction(event: any) {
    if(event===0) {
      this.addImage();
    } else {
      this.addFolder();
    }
  }

  addFolder() {
    let openPath = '';
    if(this.selectedPath) {
      openPath = this.selectedPath?.path+'/';
    }
    const dialogRef = this.dialog.open(AddFolderDialogComponent, {
      data: {  }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let photo: Photo = { id: -1, path: openPath+result+'/', date: new Date(), authorid: -1
        }
        this.photos.push(photo)
        this.updateTreeData();
      }
    });
  }

  addImage() {
    let openPath = this.selectedPath?.path+'/';
    console.log(openPath);

    const dialogRef = this.dialog.open(AddImageDialogComponent, {
      data: { path: openPath }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let authorid = -1;
        if(result.author) {
          authorid = result.author.id;
        }
        const photo: Photo = {
          id: -1,
          path: result.path+result.filename,
          authorid: authorid,
          author: result.author,
          date: new Date(),
          newImage: result.file,
          imgSaveFilename: result.filename
        }
        this.commitUpdate(photo)
      }
    });
  }

  private addFolderToPath(basePath: string, folderName: string): string {
    if(basePath) {
      // Ensure basePath ends with a slash
      basePath = basePath.endsWith('/') ? basePath : basePath + '/';

      // Ensure folderName does not start with a slash
      folderName = folderName.startsWith('/') ? folderName.substring(1) : folderName;

      return basePath + folderName;
    }
    return folderName;
  }
}
