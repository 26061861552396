<mat-card>
  <mat-card-header>
    <mat-card-title>Ordner hinzufügen</mat-card-title>
  </mat-card-header>

  <div mat-dialog-content>
    <p>Ordnername:</p>
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="data.name">
    </mat-form-field>
  </div>

  <mat-card-actions class="button-container">
    <button mat-button (click)="onCancelClick()">Abbrechen</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="data.name" cdkFocusInitial>Ok</button>
  </mat-card-actions>

</mat-card>
