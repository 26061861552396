<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed': ''">
  <div class="logo-container">
    <button class="logo" (click)="toggleCollapse()">
      <img src="/assets/logo/Band-On_Logo_transparent.png">
    </button>
    @if (collapsed) {
      <div class="logo-text" @fadeInOut>band-on</div>
      <button class="btn-close" (click)="closeSidenav()">
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>

  <div class="scrollwrap scrollwarp_delayed">
    <ul class="sidenav-nav">
      @for (page of menuItems; track $index) {
        <li class="sidenav-nav-item">
          @if(page.sub && page.sub.length > 0) {
            <a class="sidenav-nav-link" (click)="handleClick(page)"
              [ngClass]="getActiveClass(page)"
            >
              <mat-icon [matTooltip]="page.name ? page.name: ''">{{ page.icon }}</mat-icon>
              @if (collapsed) {
                <span class="sidenav-link-text" @fadeInOut>{{ page.name}}</span>
                @if (page.expanded) {
                  <mat-icon class="menu-collapse-icon">expand_more</mat-icon>
                }
                @else {
                  <mat-icon class="menu-collapse-icon">expand_less</mat-icon>
                }
              }
            </a>
            @if (collapsed) {
              <app-sublevel-menu
                [data]="page"
                [collapsed]="collapsed"
                [multiple]="multiple"
                [expanded]="page.expanded"
                [showUsersMenu]="showUsersMenu"
              ></app-sublevel-menu>
            }
          }
          @else {
            <a class="sidenav-nav-link" [routerLink]="page.state"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}"
            >
              <mat-icon [matTooltip]="page.name ? page.name: ''">{{ page.icon }}</mat-icon>
              @if (collapsed) {
                <span class="sidenav-link-text" @fadeInOut>{{ page.name}}</span>
              }
            </a>
          }
        </li>
      }
      <li class="sidenav-nav-item logout-item">
        <a class="sidenav-nav-link"
          (click)="openDialog('30ms', '30ms')"
        >
          <mat-icon>logout</mat-icon>
          @if (collapsed) {
            <span class="sidenav-link-text" @fadeInOut>Logout</span>
          }
        </a>
      </li>
    </ul>
  </div>

</div>

<!--<button mat-button class="menu-button" routerLink="/collections">
  <mat-icon>library_music</mat-icon>
  <span>Collections</span>
</button>
<button mat-button class="menu-button" routerLink="/sessions">
  <mat-icon>mic</mat-icon>
  <span>Recording-Sessions</span>
</button>
<button mat-button class="menu-button"  routerLink="/tunes">
  <mat-icon>music_note</mat-icon>
  <span>Stücke</span>
</button>
<button mat-button class="menu-button" routerLink="/musicians">
  <mat-icon>person</mat-icon>
  <span>Beteiligte</span>
</button>
<button mat-button class="menu-button" routerLink="/composers">
  <mat-icon>audio_file</mat-icon>
  <span>Komponist*innen</span>
</button>
<button mat-button class="menu-button" routerLink="/instruments">
  <mat-icon>piano</mat-icon>
  <span>Instrumente</span>
</button>
<button mat-button class="menu-button" routerLink="/voices">
  <mat-icon>mic</mat-icon>
  <span>Stimmen</span>
</button>
<button mat-button class="menu-button" routerLink="/sorting">
  <mat-icon>filter_list</mat-icon>
  <span>Sortierung</span>
</button>
<button mat-button class="menu-button" routerLink="/styles">
  <mat-icon>style</mat-icon>
  <span>Genres</span>
</button>
<button mat-button class="menu-button" routerLink="/tags">
  <mat-icon>sell</mat-icon>
  <span>Tags</span>
</button>
<button mat-button class="menu-button" routerLink="/photos">
  <mat-icon>image</mat-icon>
  <span>Fotos</span>
</button>
<button mat-button class="menu-button" routerLink="/sheets">
  <mat-icon>description</mat-icon>
  <span>Sheets</span>
</button>
<button mat-button class="menu-button" routerLink="/partners">
  <mat-icon>handshake</mat-icon>
  <span>Partner</span>
</button>
<mat-divider></mat-divider>
<button mat-button class="menu-button" routerLink="/users" [hidden]="!showUsersMenu">
  <mat-icon>group</mat-icon>
  <span>Benutzer*innen</span>
</button>
<button mat-button class="menu-button" routerLink="/users/testaccount" [hidden]="!showUsersMenu">
  <mat-icon>group</mat-icon>
  <span>Testaccount</span>
</button>
<button mat-button class="menu-button" routerLink="/roles" [hidden]="!showUsersMenu">
  <mat-icon>badge</mat-icon>
  <span>Rollen</span>
</button>
<button mat-button class="menu-button" routerLink="/pricing/categories" [hidden]="!showUsersMenu">
  <mat-icon>price_change</mat-icon>
  <span>Preiskategorien</span>
</button>
<button mat-button class="menu-button" routerLink="/shop/windows">
  <mat-icon>storefront</mat-icon>
  <span>Schaufenster</span>
</button>
<mat-divider [hidden]="!showUsersMenu"></mat-divider>
<button mat-button class="menu-button" routerLink="/analytics">
  <mat-icon>query_stats</mat-icon>
  <span>Analytics</span>
</button>
<mat-divider></mat-divider>
<button mat-button (click)="logout()" class="menu-button">
  <mat-icon>logout</mat-icon>
  <span>Logout</span>
</button>-->
