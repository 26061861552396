import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, Sheet } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NgFor } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-sheet-select',
    templateUrl: './sheet-select.component.html',
    styleUrls: ['./sheet-select.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatButtonModule, NgFor, MatListModule, MatIconModule, MatGridListModule, MatDialogClose, MatCardModule, PdfViewerModule, MatDialogActions]
})
export class SheetSelectComponent implements OnInit, OnDestroy {

  private sheets: Sheet[] = [];

  private unsubscribe$ = new Subject<void>();

  private shownPath: string = '';
  private openFolders: string[] = [];

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<SheetSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  get hierarchyLevelsCount(): number {
    let hierarchyCount = this.openFolders.length+1;
    return hierarchyCount;
  }

  get shownSheets(): Sheet[] {
    let out: Sheet[] = [];
    if(this.sheets) {
      out = this.sheets.filter(s => s.path.includes(this.shownPath) && s.id>0);
    }
    return out;
  }

  ngOnInit(): void {
    this.refreshSheets();
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  refreshSheets(): void {
    //Get all Sheets
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Sheet[]>(environment.apiURL+"/sheets", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (sheets) => {
          this.sheets.length = 0;
          this.sheets = sheets;
          this.sheets.forEach(s => this.loadSheet(s))
        }
      );
  }

  loadSheet(sheet: Sheet) {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get(environment.apiURL+"/sheetFile/"+sheet.id, {headers, responseType: 'blob'})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( file => {
          let reader = new FileReader();

        reader.onload = (e: any) => {
          sheet.sheetSrc = e.target.result;
        }

        reader.readAsArrayBuffer(file);
      }
    );
  }

  openFolder(level: number, path: string) {
    while(this.openFolders.length>level) {
      this.openFolders.pop();
    }
    this.openFolders.push(path)

    let openPath = '';
    this.openFolders.forEach(f => openPath = openPath.concat(f)+'/')
    this.shownPath = openPath;
  }

  getOpenPath(hierarchyLevel: number): string {
    let openPath = '';
    let i=0;
    while (i<hierarchyLevel) {
      if(this.openFolders.length>i) {
        openPath = openPath.concat(this.openFolders[i]+'/');
      }
      i++;
    }
    return openPath;
  }

  getFolders(hierarchyLevel: number) {
    let folders: string[] = [];
    let openPath = this.getOpenPath(hierarchyLevel)
    this.sheets.forEach(s => {
      let parts = s.path.split('/');
      if(s.path.includes(openPath) && parts.length>hierarchyLevel+1 && !folders.includes(parts[hierarchyLevel])) {
        folders.push(parts[hierarchyLevel])
      }
    })
    return folders;
  }

  showAll() {
    this.openFolders.length = 0;
    this.shownPath = '';
  }

  sheetFilename(sheet: Sheet) {
    return sheet.path.substring(sheet.path.lastIndexOf('/')+1);
  }
}
