import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Tune, Instrument, Musician, TuneMusician, AuthenticationService } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MusicianAutoInputComponent } from '../../general/musician-auto-input/musician-auto-input.component';
import { MatListModule } from '@angular/material/list';

@Component({
    selector: 'app-band-table',
    templateUrl: './band-table.component.html',
    styleUrls: ['./band-table.component.scss'],
    standalone: true,
    imports: [MatTableModule, MatListModule, MusicianAutoInputComponent, MatSelectModule, NgFor, MatOptionModule, NgIf, MatButtonModule, MatIconModule]
})
export class BandTableComponent implements OnInit, OnDestroy {

  @Input() tune!: Tune;
  @Input() disabled: boolean = false;

  displayedColumns: string[] = ['bild', 'name', 'instrument', 'delete'];

  dataSource = new MatTableDataSource<TuneMusician>();
  public instruments: Instrument[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService) {}

  ngOnInit(): void {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    //Load all possible Instruments
    this.httpClient.get<Instrument[]>(environment.apiURL+'/instruments', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.instruments = data;
    });
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }


  ngOnChanges() {
    if(this.tune.musicians) {
      this.dataSource.data = this.tune.musicians;
    }
  }

  addMusician() {
    const newMusician: Musician = { id:-1, surname: '', firstname: '', instrument: {id:-1, designation: '', img: '', img_dark: '', instrumentorder: 1}};
    const newInstrument: Instrument = { id: -1, designation: '', img: '', img_dark: '', instrumentorder: 1}
    const newTuneMusician: TuneMusician = { tunemusician: newMusician, tminstrument: newInstrument };
    if (!this.tune.musicians) {
      this.tune.musicians = [];
    }
    this.tune?.musicians.push(newTuneMusician);
    this.ngOnChanges()
  }

  deleteMusician(musician: TuneMusician) {
    if (this.tune && this.tune.musicians) {
      const index : number = this.tune.musicians.indexOf(musician);
      this.tune.musicians.splice(index, 1);
      this.ngOnChanges()
    }
  }

  changeMusician(musician: TuneMusician, changedMusician: Musician) {
    musician.tunemusician.id = changedMusician.id;
    musician.tunemusician.firstname = changedMusician.firstname;
    musician.tunemusician.surname = changedMusician.surname;
    musician.tunemusician.image = changedMusician.image;
    musician.tunemusician.instrument = changedMusician.instrument;

    if(changedMusician.instrument) {
      musician.tminstrument.id = changedMusician.instrument.id;

    }
  }

  getMusicianImg(musician: TuneMusician): string {
    if (musician.tunemusician.picture) {
      return environment.apiURL+'/photoImg/'+musician.tunemusician.picture.id;
    }
    return '';
  }

  getInstrumentIcon(instrument: Instrument): string {
    return environment.apiURL+"/instrImg/"+instrument.img;
  }

}
