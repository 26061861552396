import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Sheet } from 'bandon-shared';
import { PhotoDirNode } from '../../photo-library/photo-browser/photo-browser.component';
import { SheetItemViewComponent } from '../sheet-item-view/sheet-item-view.component';
import { FlexModule } from '@angular/flex-layout/flex';

export interface FolderViewItem {
  name: string;
  path: string;
  type: 'folder' | 'file';
  item: Sheet | PhotoDirNode;
}


@Component({
    selector: 'app-sheet-folder-view',
    templateUrl: './sheet-folder-view.component.html',
    styleUrl: './sheet-folder-view.component.scss',
    standalone: true,
    imports: [FlexModule, SheetItemViewComponent]
})
export class SheetFolderViewComponent implements OnInit, OnChanges {

  @Input() folders: PhotoDirNode[] = [];
  @Input() sheets: Sheet[] = [];
  @Input() selectedFolder: PhotoDirNode | undefined;
  @Input() mode: 'select' | 'edit' = 'select';

  @Output() onNodeSelect: EventEmitter<PhotoDirNode> = new EventEmitter();
  @Output() onDelete: EventEmitter<PhotoDirNode> = new EventEmitter();
  @Output() onSelect: EventEmitter<Sheet> = new EventEmitter();
  @Output() onAssign: EventEmitter<Sheet> = new EventEmitter();

  ctxMenuOpened = false;

  filteredItems: FolderViewItem[] = [];
  columnWidth: string = "25%";

  menuTopLeftPosition =  {x: '0', y: '0'}

  selectedItem: FolderViewItem | undefined;

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.setGridColumns(); // Call the function initially
    this.observeScreenSizeChanges(); // Observe screen size changes
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterSheets();
  }

  filterSheets() {
    this.filteredItems = [];

    const filteredSheets = this.sheets.filter(s => {
      return this.selectedFolder && this.getPath(s.path) === this.selectedFolder?.path;
    });
    const filteredFolders = this.folders.filter(f => {
      return this.selectedFolder && this.getPath(f.path) === this.selectedFolder?.path;
    });

    filteredFolders.forEach(f => {
      if(f.children) {
        f.children.forEach(c => {
          this.filteredItems.push({
            name: c.name,
            path: c.path,
            type: 'folder',
            item: c
          });
        });
      }
    })
    filteredSheets.forEach(s => {
      this.filteredItems.push({
        name: s.path.substring(s.path.lastIndexOf('/')+1),
        path: s.path,
        type: 'file',
        item: s
      });
    });

    if(!this.selectedFolder || !this.selectedFolder.path) {
      this.folders.forEach(f => {
        this.filteredItems.push({
          name: f.name,
          path: '',
          type: 'folder',
          item: f
        })
      });
    }
  }

  handleClick(event: any, item: FolderViewItem) {
    if(this.mode==='select' && item.type === 'file') {
      this.selectedItem = item;
      this.onSelect.emit(item.item as Sheet)
    } else {
      this.onSelect.emit(undefined)
    }
  }

  handleDblClick($event: any, item: FolderViewItem) {
    if(item.type === 'folder') {
      const dir = item.item as PhotoDirNode;
      this.onNodeSelect.emit(dir);
    }
  }

  isValidItem(item: FolderViewItem) {
    if(item && item.type === 'folder' && 'name' in item.item && item.name!=='/') {
      return true;
    } else if(item && item.type === 'file' && 'id' in item.item && item.item.id>=0) {
      return true;
    }
    return false;
  }

  deleteSheet(photo: PhotoDirNode) {
    this.onDelete.emit(photo);
  }

  assignSheet(sheet: Sheet) {
    this.onAssign.emit(sheet);
  }

  private observeScreenSizeChanges(): void {
    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
      .subscribe(result => {
        this.setGridColumns(); // Adjust columns on screen size change
      });
  }

  private setGridColumns(): void {
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.columnWidth = "100%";
    } else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
      this.columnWidth = "50%";
    } else {
      this.columnWidth = "25%"; // Default for larger screens
    }
  }


  private getPath(filePath: string): string {
    const lastIndexOfSeparator = Math.max(filePath.lastIndexOf('/'), filePath.lastIndexOf('\\'));

    if (lastIndexOfSeparator !== -1) {
      return filePath.substring(0, lastIndexOfSeparator);
    } else {
      // If no separator is found, return the original path
      return filePath;
    }
  }

}
