import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthenticationService, InstrumentGroup, Language, Voice } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { environment } from 'src/environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-edit-voice',
    templateUrl: './edit-voice.component.html',
    styleUrls: ['./edit-voice.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, RouterLink]
})
export class EditVoiceComponent {
  public voice: Voice;

  public languages: Language[] = [];
  public instrumentGroups:  InstrumentGroup[] = []

  private unsubscribe$ = new Subject<void>();
  private voiceID: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ){
    this.voice = { id: -1, designation: '', voiceorder: 1000,
      instrumentgroup: { id: -1, designation: '', grouporder: 1000, linked: true, groupLevel: 1, solo: false, muted: false}}
  }

  get instrumentgroupID(): number {
    if (this.voice.instrumentgroup) {
      return this.voice.instrumentgroup.id
    }
    return -1;
  }

  set instrumentgroupID(id: number) {
    if (this.voice.instrumentgroup) {
      this.voice.instrumentgroup.id = id;
    }
  }

  ngOnInit(): void {
    this.voiceID = this.activatedRoute.snapshot.paramMap.get('voiceid');
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    if(this.voiceID>0) {
      const loadingDialog = this.dialog.open(LoadingTemplateComponent)
      console.log('load voice: ', this.voiceID);

      //Load Musician
      this.httpClient.get<Voice>(environment.apiURL+'/voices/'+this.voiceID, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.voice = data;

          //Load Musician Image
  //        this.tuneImgFile = this.getTuneIcon(this.tune);
          console.log('Voice loaded ', this.voice);
          loadingDialog.close();
        });

    } else {
      this.voice = { id: -1, designation: '', voiceorder: 1000,
        instrumentgroup: { id: -1, designation: '', grouporder: 1000, linked: true, groupLevel: 1, solo: false, muted: false}}
    }

    //Get all Languages
    this.httpClient.get<Language[]>(environment.apiURL+"/languages", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (language) => {
        this.languages.length = 0;
        this.languages.push(...language);
      }
    );

    //Load all possible IntrumentGroups
    this.httpClient.get<InstrumentGroup[]>(environment.apiURL+'/instrumentgroups', { headers })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async data => {
        this.instrumentGroups = data.filter(value => value.id>0);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveVoice() {
    this.snackBar.open('Speichert Daten')
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();

    formData.append('voice', JSON.stringify(this.voice));
    if(this.voiceID>0) {
      this.httpClient.post<Voice>(environment.apiURL+`/voices/${this.voice.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.voice = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/voices');
      });
    } else {
      this.httpClient.put<Voice>(environment.apiURL+`/voices`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.voice = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/voices');
      });
    }
  }

/*  addTranslation() {
    if (!this.instrument.translations) {
      this.instrument.translations = []
    }

    this.instrument.translations.push({languageid: 'en', designation: ''})
  }

  deleteTranslation(translation: InstrumentTitles) {
    if (this.instrument.translations) {
      const index = this.instrument.translations.indexOf(translation);
      this.instrument.translations.splice(index, 1);
    }
  }

  getLanguageDesignation(languageid: string): string {
    const language = this.languages.find(e => e.id==languageid);
    if (language) {
      return language.designation
    }
    return '';
  }*/

  getLinkedText(group: InstrumentGroup): string {
    if(group.linked) {
      return ' (linked)';
    }
    return '';
  }
}
