import { Track, Tune } from 'bandon-shared';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

export interface AudioFileData {
  file: Blob
  filename: string
}

@Component({
    selector: 'app-track-file-cell',
    templateUrl: './track-file-cell.component.html',
    styleUrls: ['./track-file-cell.component.scss'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule]
})
export class TrackFileCellComponent implements OnInit {

  @Input() tune: Tune | undefined;
  @Input() track: Track | undefined;
  @Input() disabled: boolean = false;

  @Output() fileChanged: EventEmitter<AudioFileData> = new EventEmitter<AudioFileData>();

  filename: string = '';

  ngOnInit(): void {
    if (this.track) {
      this.filename = this.track.path.substring(this.track.path.lastIndexOf('/')+1);
    }
  }


  getTrackFile(track: Track | undefined): string {
    if(track)
      return track.path.substring(track.path.lastIndexOf('/')+1);
    return "";
  }

  onFileChange(e: any) {
    const reader = new FileReader();

    if(e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      this.filename = file.name;
      if(this.track) {
        this.track.path = this.filename;
      }

      this.fileChanged.emit({ file, filename: file.name })
/*      reader.onload = () => {
        this.tuneImgFile = reader.result as string;
        this.uploadForm.patchValue({
          imgSrc: reader.result
        });*/

//      };
    }
  }
}
