import { AuthenticationService, Collection, Partner } from 'bandon-shared';
import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { TempoDeleteAlertComponent } from 'src/app/components/ModalTemplates/tempo-delete-alert/tempo-delete-alert.component';
import { ErrorDialogComponent } from 'src/app/components/ModalTemplates/error-dialog/error-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-collections-list',
    templateUrl: './collections-list.component.html',
    styleUrls: ['./collections-list.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatListModule, NgFor, MatButtonModule, RouterLink, MatPaginatorModule, MatIconModule]
})
export class CollectionsListComponent {

  public searchTerm = '';
  public collections: Collection[] = [];
  _searchedCollections: Collection[] = [];

  private unsubscribe$ = new Subject<void>();

  private pageSize = 10;
  private pageIndex = 0;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) {
  }

  get paginatedCollections(): Collection[] {
    return this.searchedCollections.slice(this.pageIndex*this.pageSize, (this.pageIndex+1)*this.pageSize);
  }

  get searchedCollections(): Collection[] {
    if(this.searchTerm && this.searchTerm.trim() !== '') {
      return this._searchedCollections;
    }
    return this.collections;
  }

  ngOnInit(): void {
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    //Get all Collections
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Collection[]>(environment.apiURL+"/collections", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (collections) => {
          console.log(collections);
          this.collections.length = 0;
          this.collections.push(...collections);
          loadingDialog.close();
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  collectionImgPath(collection: Collection) {
    if(collection.picture) {
      return environment.apiURL+"/photoImg/"+collection.picture.id;
    }
    return '';
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchCollection(event: any) {
    const text = event.target.value;
    this._searchedCollections = this.collections;
    if(text && text.trim() !== '') {
      this._searchedCollections = this._searchedCollections.filter((colletion: Collection) => {
        //TODO: Add Recording Date, Add Tags, Add Instruments
        const des = colletion.designation+' '+colletion.description;
        return (des.toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
    }
    this.pageIndex = 0;
  }

  deleteCollection(collecitonid: number) {
    const dialogRef = this.dialog.open(TempoDeleteAlertComponent, {
      data: { title: 'Achtung', message: 'Soll die Collection wirklich gelöscht werden?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result==='confirmed') {
        const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
        this.httpClient.delete<any>(environment.apiURL+'/collections/'+collecitonid, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: async data => {
            console.log(data);
            window.location.reload();
          },
          error: err => {
            this.dialog.open(ErrorDialogComponent, {
              data: { title: 'Fehler', message: err.error.error }
            });
          }
        });
      }
    });
  }
}
