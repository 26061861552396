import { Component, ViewChild } from '@angular/core';
import { MainMenuComponent } from 'src/app/components/navigation/main-menu/main-menu.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { AuthenticationService, UserGroup } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { environment } from 'src/environments/environment';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-usergroup-list',
  standalone: true,
  imports: [
    MainMenuComponent,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    RouterLink,
    MatPaginatorModule,
    MatIconModule
  ],
  templateUrl: './usergroup-list.component.html',
  styleUrl: './usergroup-list.component.scss'
})
export class UsergroupListComponent {
  public searchTerm = '';
  public groups: UserGroup[] = [];
  _searchedGroups: UserGroup[] = [];

  displayedColumns: string[] = ['name', 'description', 'edit'];
  public dataSource = new MatTableDataSource<UserGroup>(this.groups);

  private unsubscribe$ = new Subject<void>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    //Get all Tunes
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<UserGroup[]>(environment.apiURL+"/usergroups", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (groups) => {
          this.groups.length = 0;
          this.groups.push(...groups);
          this.dataSource.data = this.groups;
          loadingDialog.close();
          console.log(groups)
        }
      );

    this.dataSource.filterPredicate = (data: UserGroup, filter: string) => {
      const modelValue = data.designation+' '+data.description;
      return modelValue.toLowerCase().includes(filter);
    };
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
