import { Component } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-loading-template',
    templateUrl: './loading-template.component.html',
    styleUrls: ['./loading-template.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatProgressSpinnerModule]
})
export class LoadingTemplateComponent {
  constructor(public dialogRef: MatDialogRef<LoadingTemplateComponent>) {}

}
