import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, SimpleTuneModel } from 'bandon-shared';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatDialog } from '@angular/material/dialog';
import { IMenuItem, NavigationService } from 'src/app/services/navigation.service';
import { ErrorDialogComponent } from 'src/app/components/ModalTemplates/error-dialog/error-dialog.component';
import { TempoDeleteAlertComponent } from 'src/app/components/ModalTemplates/tempo-delete-alert/tempo-delete-alert.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-tunes-list',
    templateUrl: './tunes-list.component.html',
    styleUrls: ['./tunes-list.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatListModule, NgFor, MatButtonModule, RouterLink, NgIf, MatPaginatorModule, MatIconModule]
})
export class TunesListComponent implements OnInit, OnDestroy{

  public searchTerm = '';
  public tunes: SimpleTuneModel[] = [];
  _searchedTunes: SimpleTuneModel[] = [];

  private unsubscribe$ = new Subject<void>();

  private pageSize = 10;
  private pageIndex = 0;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private navDataServ: NavigationService
  ) {
  }

  get paginatedTunes(): SimpleTuneModel[] {
    return this.searchedTunes.slice(this.pageIndex*this.pageSize, (this.pageIndex+1)*this.pageSize);
  }

  get searchedTunes(): SimpleTuneModel[] {
    if(this.searchTerm && this.searchTerm.trim() !== '') {
      return this._searchedTunes;
    }
    return this.tunes;
  }

  get navData(): IMenuItem[] {
    return this.navDataServ.menuItems;
  }

  ngOnInit(): void {
    //Get all Tunes
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)

    this.httpClient.get<SimpleTuneModel[]>(environment.apiURL+"/tunes", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tunes) => {
          this.tunes.length = 0;
          this.tunes.push(...tunes);
          loadingDialog.close();
          console.log(tunes);
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  tuneImgPath(tune: SimpleTuneModel): string {
    if(tune.picture) {
      return environment.apiURL+"/photoImg/"+tune.picture.id;
    }
    return '';
  }

  getTuneRecDate(tune: SimpleTuneModel): string {
    let out = '';
    if (tune) {
      if (tune.recordingdate) {
        out = 'Recorded:'+this.datePipe.transform(tune.recordingdate, 'dd.MM.yyyy')
      }
    }
    return out;
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchTune(event: any) {
    const text = event.target.value;
    this._searchedTunes = this.tunes;
    if(text && text.trim() !== '') {
      this._searchedTunes = this._searchedTunes.filter((tune: SimpleTuneModel) => {
        //TODO: Add Recording Date, Add Tags, Add Instruments
        const des = tune.title+' '+tune.speeds+' '+tune.styles+' '+tune.tonalities;
        return (des.toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
    }
    this.pageIndex = 0;
  }

  deleteTune(tuneid: number) {
    const dialogRef = this.dialog.open(TempoDeleteAlertComponent, {
      data: { title: 'Achtung', message: 'Soll das Stück wirklich gelöscht werden?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result==='confirmed') {
        const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
        this.httpClient.delete<any>(environment.apiURL+'/tunes/'+tuneid, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: async data => {
            console.log(data);
            window.location.reload();
          },
          error: err => {
            this.dialog.open(ErrorDialogComponent, {
              data: { title: 'Fehler', message: err.error.error }
            });
          }
        });
      }
    });

  }
}
