<mat-card>
  <mat-card-header>
    <mat-card-title>Bild hinzufügen</mat-card-title>
  </mat-card-header>

  <div mat-dialog-content>
    <button mat-stroked-button (click)="imgInput.click()">Bild wählen</button><br>
    <input hidden="true" multiple type="file" #imgInput (change)="onImageChange($event)" accept="image/*"/>

    <mat-grid-list cols="2" rowHeight="1:1">
      <mat-grid-tile>
        <img [src]="data.imgFile" class="img-icon instr-img">
      </mat-grid-tile>
      <mat-grid-tile>
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [resizeToWidth]="1024"
          [resizeToHeight]="1024"
          [aspectRatio]="selectedAspectRatio"
          [onlyScaleDown]="true"
          [hidden]="false"
          format="png"
          output="base64"
          (imageCropped)="imageCropped($event)"
        ></image-cropper>
      </mat-grid-tile>
    </mat-grid-list>
    <p>Fotograf*in</p>
    <app-musician-auto-input (musicianChanged)="changeAuthor($event)"></app-musician-auto-input>
  </div>

  <mat-card-actions class="button-container">
    <mat-form-field >
      <mat-label>Format</mat-label>
      <mat-select [(value)]="selectedAspectRatio">
        <mat-option [value]="originalAspectRatio">Original</mat-option>
        <mat-option [value]="1">1:1</mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-button (click)="onCancelClick()">Abbrechen</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
  </mat-card-actions>
</mat-card>
