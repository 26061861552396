import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { PhotoDirNode } from '../photo-browser/photo-browser.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-directory-tree',
    templateUrl: './directory-tree.component.html',
    styleUrl: './directory-tree.component.scss',
    standalone: true,
    imports: [MatTreeModule, MatIconModule, MatButtonModule]
})
export class DirectoryTreeComponent implements OnChanges{
  @Input() treeData: PhotoDirNode[] = [];
  @Input() selectedNode: PhotoDirNode | undefined;

  @Output() onNodeSelect: EventEmitter<PhotoDirNode> = new EventEmitter();

  treeControl = new NestedTreeControl<PhotoDirNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<PhotoDirNode>();

  constructor() {
    this.updateData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
  }

  updateData() {
    this.dataSource.data = this.treeData;
  }

  hasChild = (_: number, node: PhotoDirNode) => !!node.children && node.children.length > 0;

  selectFolder(node: PhotoDirNode) {
    this.onNodeSelect.emit(node);
  }

  isNodeActive(node: PhotoDirNode) {
    return node === this.selectedNode;
  }
}
