<app-main-menu title="Stücke" ></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Alle Stücke</h1>

  <mat-form-field style="width:100%">
    <mat-label>Suchen</mat-label>
    <input matInput placeholder="Suche" [(ngModel)]="searchTerm" (input)="searchTune($event)">
  </mat-form-field>

  <mat-list>
    <mat-list-item *ngFor="let tune of paginatedTunes" class="tune-list-item">
      <img matListItemIcon [src]="tuneImgPath(tune)">
      <span matListItemTitle>{{ tune.title }}
        <div class="mat-list-item-content-right">
          <button mat-stroked-button color="warn" style="margin-right: 10px;" (click)="deleteTune(tune.id)">
            Löschen
          </button>
          <button mat-stroked-button style="margin-right: 10px;" routerLink="/tunes/{{ tune.id }}/sheets">
            Noten
          </button>
          <button mat-raised-button color="accent" routerLink="/tunes/{{ tune.id }}">
            Edit
          </button>
        </div>
      </span>
      <span matListItemLine *ngIf="tune.subtitle" >{{ tune.subtitle }}</span>
      <span matListItemLine>{{ getTuneRecDate(tune) }}; {{ tune.tonalities }}; {{ tune.speeds }}</span>
    </mat-list-item>
  </mat-list>

  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="tunes.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>


  <button mat-button routerLink="/tunes/-1">
    <mat-icon>add_box</mat-icon>
    Stück hinzufügen
  </button>
</div>
