import { Component } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SheetBrowserComponent } from '../../../components/sheet-library/sheet-browser/sheet-browser.component';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-sheets-library',
    templateUrl: './sheets-library.component.html',
    styleUrls: ['./sheets-library.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' }
    ],
    standalone: true,
    imports: [MainMenuComponent, SheetBrowserComponent]
})
export class SheetsLibraryComponent {


}
