<form>
  <mat-form-field *ngIf="!disabled" >
    <input type="text" matInput [value]="composer"
      [matAutocomplete]="auto"
      [formControl]="composerControl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="composerDisplayFn">
    <mat-option *ngFor="let option of filteredComposers | async" [value]="option">
      {{ option.firstname }} {{ option.surname }}
    </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <button mat-raised-button color="warn" type="button" (click)="removeComposer(composer)" *ngIf="!disabled">Löschen</button>
  <p *ngIf="disabled">{{ name }} {{ surname }}</p>
  <p class="error-msg">{{ errorMsg }}</p>
</form>
