import { DIALOG_DATA } from '@angular/cdk/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, Sheet } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
    selector: 'app-pdf-preview',
    templateUrl: './pdf-preview.component.html',
    styleUrls: ['./pdf-preview.component.scss'],
    standalone: true,
    imports: [PdfViewerModule]
})
export class PdfPreviewComponent implements OnDestroy {

  pdfSrc: any;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private httpClient: HttpClient,
    @Inject(DIALOG_DATA) public data: {sheet: Sheet}
  ) {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get(environment.apiURL+"/sheetFile/"+data.sheet.id, {headers, responseType: 'blob'})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( file => {
          let reader = new FileReader();

        reader.onload = (e: any) => {
          this.pdfSrc = e.target.result;
        }

        reader.readAsArrayBuffer(file);
      }
    );

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
