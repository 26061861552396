import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService, Sheet, Track, Tune } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { environment } from 'src/environments/environment';
import { SheetAddEvent } from 'src/app/components/sheet-library/sheet-browser/sheet-browser.component';
import { PdfPreviewComponent } from '../../sheets/sheets-library/pdf-preview/pdf-preview.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SavingTemplateComponent } from 'src/app/components/ModalTemplates/saving-template/saving-template.component';
import { SheetBrowserComponent } from '../../../components/sheet-library/sheet-browser/sheet-browser.component';
import { MatButtonModule } from '@angular/material/button';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-tune-sheets',
    templateUrl: './tune-sheets.component.html',
    styleUrl: './tune-sheets.component.scss',
    standalone: true,
    imports: [MainMenuComponent, MatButtonModule, SheetBrowserComponent, RouterLink]
})
export class TuneSheetsComponent implements OnInit {

  public tune: Tune;
  private tuneId: any;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ){
    this.tune = { id: -1, title: '', coordinatelat: 0, coordinatelong: 0, isdemo: false }

  }

  ngOnInit(): void {
    this.tuneId = this.activatedRoute.snapshot.paramMap.get('tuneid');
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    //Load Tune
    if(this.tuneId>0) {
      const loadingDialog = this.dialog.open(LoadingTemplateComponent)

      this.httpClient.get<Tune>(environment.apiURL+'/tunes/'+this.tuneId, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.tune = data;

          console.log('tune loaded ', this.tune);
          loadingDialog.close();
        });
    }
  }

  onSheetAdd(event: SheetAddEvent) {
    for(const tid of event.trackids) {
      const track = this.tune.tracks?.find(t => t.id === tid);
      if(track) {
        if(event.sheet.type?.id==1) {
          const existingSheets = this.getSheets(track, 1);
          for(const sheet of existingSheets) {
            const trackSheet = track.sheets?.find(s => s.id===sheet.id)
            if (trackSheet) {
              track.sheets?.splice(track.sheets.indexOf(trackSheet));
            }
          }
        }
        track.sheets?.push(event.sheet);
      }
    }
  }

  getSheets(track: Track, type: number): Sheet[] {
    const out: Sheet[] = [];
    track.sheets?.forEach(s => {
      if(s.type?.id===type) {
        out.push(s);
      }
    });
    return out;
  }

  previewFile(sheet: Sheet | undefined) {
    if(sheet) {
      const dialogRef = this.dialog.open(PdfPreviewComponent, {
        data: { sheet: sheet },
        height: '80%',
        width: '80%'
      });
    }
  }

  removeSheet(track: Track, sheet: Sheet) {
    if(track.sheets) {
      track.sheets.splice(track.sheets.indexOf(sheet), 1);
    }
  }


  saveTune() {
    this._snackBar.open('Speichert Daten')

    const savingDialog = this.dialog.open(SavingTemplateComponent)

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();

    formData.append('tune', JSON.stringify(this.tune));
    console.log(this.tune);

    if(this.tuneId>0) {
      this.httpClient.post<Tune>(environment.apiURL+`/tunes/${this.tune.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.tune = resp;
          console.log(resp);
          this._snackBar.dismiss();
          savingDialog.close();
  //        this.router.navigateByUrl('/tunes');
          window.location.reload();
        },
        error: err => {
          this._snackBar.dismiss();
          savingDialog.close();
          this._snackBar.open("Es gab einen Fehler beim Speichern", "schliessen");
        }
      });
    }
  }
}
