<app-main-menu title="{{ category.title }}" backref="/pricing/categories"></app-main-menu>

<div class="content mat-elevation-z8">
  <mat-form-field appearance="fill" style="width: 80%;">
    <mat-label>Bezeichnung</mat-label>
    <input matInput placeholder="Bezeichnung" [(ngModel)]="category.title">
  </mat-form-field>
  <mat-form-field appearance="fill" style="width: 80%;">
    <mat-label>Beschreibung</mat-label>
    <input matInput placeholder="Beschreibung" [(ngModel)]="category.description">
  </mat-form-field>
  <mat-form-field appearance="fill" style="width: 80%;">
    <mat-label>GlassfySKU</mat-label>
    <input matInput placeholder="GlassfySKU" [(ngModel)]="category.glassfysku">
  </mat-form-field><br>
  <mat-form-field>
    <mat-label>Typ</mat-label>
    <mat-select [(ngModel)]="category.type">
        <mat-option [value]="0">Stück</mat-option>
        <mat-option [value]="1">Collection</mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <button mat-raised-button routerLink="/pricing/categories" style="margin-right: 16px">Abbrechen</button>
    <button mat-raised-button color="accent" (click)="saveCategory()">Speichern</button>
  </div>
</div>
