<mat-checkbox [(ngModel)]="mainTempo" [disabled]="disabled" color="primary">Haupttempo</mat-checkbox><br>
<mat-form-field appearance="fill">
  <mat-label>Tempo</mat-label>
  <input matInput [(ngModel)]="speed" [disabled]="disabled">
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>Zeit Tempo start [ms]</mat-label>
  <input matInput [(ngModel)]="start" appTimeInput [disabled]="disabled">
</mat-form-field><br>
<button mat-icon-button (click)="fileInput.click()" type="button" *ngIf="!disabled" >
  <mat-icon>upload_file</mat-icon>
</button>
<input hidden="true" multiple type="file" #fileInput (change)="onFileChange($event)" accept="audio/mpeg3"/>
{{ filename }}<br>
<mat-form-field appearance="fill">
  <mat-label>Einzählen Schläge</mat-label>
  <input matInput [(ngModel)]="countoffbeats" [disabled]="disabled">
</mat-form-field><br>

<button mat-raised-button color="warn" style="margin: 10px" (click)="deleteTempo()" *ngIf="!disabled" >
  Tempo löschen
</button>
