<mat-form-field class="style-list" appearance="fill" *ngIf="!disabled">
  <mat-label>Genre</mat-label>
  <mat-chip-grid #stileGrid aria-label="Genre eingeben" >
    <mat-chip-row *ngFor="let style of tune.styles"
      (removed)="removeStyle(style)"
      [editable]="false">
      {{ style.designation }}
      <button matChipRemove [attr.aria-label]="'entferne '+ style.designation">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input placeholder="Stil hinzufügen"
      [matChipInputFor]="stileGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="addStyle($event)"
      [matAutocomplete]="auto"
      [formControl]="styleControl"/>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="styleDisplayFn">
      <mat-option *ngFor="let option of filteredStyles | async" [value]="option">
        {{ option.designation }}
      </mat-option>
    </mat-autocomplete>

  </mat-chip-grid>
</mat-form-field>
<div *ngIf="disabled">
  <p *ngFor="let style of tune.styles">{{ style.designation }}</p>
</div>
