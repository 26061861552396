<app-main-menu title="{{ tune.title }}" backref=".."></app-main-menu>

<div class="content mat-elevation-z8">
<!--        <form>-->

    <mat-accordion>
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Allgemeine Daten
          </mat-panel-title>
          <mat-panel-description class="error-msg">
            {{ generalErrMsg }}
          </mat-panel-description>
        </mat-expansion-panel-header>

        <!--<app-audio-file-upload (filesChanged)="onAudioFilesChanged($event)"></app-audio-file-upload>-->

        <mat-form-field appearance="fill" style="width: 80%">
          <mat-label>Titel</mat-label>
          <input matInput placeholder="Titel" [(ngModel)]="tune.title" [disabled]="dataCheckOK" libTagInput>
        </mat-form-field><br>
        <mat-form-field appearance="fill" style="width: 80%">
          <mat-label>Untertitel</mat-label>
          <input matInput placeholder="Titel" [(ngModel)]="tune.subtitle" [disabled]="dataCheckOK">
        </mat-form-field><br>

        <mat-form-field appearance="fill">
          <mat-label>Recording-Datum</mat-label>
          <input matInput [matDatepicker]="recpicker" [(ngModel)]="tune.recordingdate" [disabled]="dataCheckOK">
          <mat-hint>MM.DD.YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="recpicker" [disabled]="dataCheckOK"></mat-datepicker-toggle>
          <mat-datepicker #recpicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Veröffentlichung</mat-label>
          <input matInput [min]="minDate" [matDatepicker]="picker" [(ngModel)]="tune.publishdate" [disabled]="dataCheckOK || isReleased">
          <mat-hint>MM.DD.YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker" [disabled]="dataCheckOK"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field><br>

        <mat-form-field style="width: 50%;">
          <mat-label>Eigentümer</mat-label>
          <mat-select [(value)]="tune.ownerid">
            @for (group of groups; track $index) {
              <mat-option [value]="group.id">{{ group.designation }}</mat-option>
            }
          </mat-select>
        </mat-form-field><br>


        <button mat-raised-button color="primary" (click)="midiInput.click()" *ngIf="!dataCheckOK" >
          Import MIDI File
        </button>
        <input type="file" (change)="startMidiImport($event)" accept=".mid" #midiInput hidden="true">

        <app-img-upload title="Bild"
            [imgPath]="tuneImgPath"
            forcedAspectRatio="1"
            (imgChanged)="updateTuneImg($event)"
            (fileExt)="updateTuneImgExt($event)"
            (imgIdChanged)="updateTuneImgId($event)"
            [disabled]="dataCheckOK">
          </app-img-upload>

        <h2>Komposition</h2>
        <p *ngFor="let composer of tune.composers">
            <app-composer-auto-input [composer]="composer"
              (composerChanged)="updateComposer(composer, $event)"
              (deleteComposer)="deleteComposer(composer)"
              [disabled]="dataCheckOK"></app-composer-auto-input>
            <!--<button mat-raised-button color="warn" type="button" (click)="deleteComposer(composer)" *ngIf="!dataCheckOK">Löschen</button>-->
        </p>
        <button mat-button (click)="addComposer()" *ngIf="!dataCheckOK" >
          <mat-icon>add_box</mat-icon>
          Komponist*in hinzufügen
        </button>

        <h2>Arrangement</h2>
        <p *ngFor="let arranger of tune.arrangers">
          <app-musician-auto-input [musicianid]="arranger.id" (musicianChanged)="updateArranger(arranger, $event)" [disabled]="dataCheckOK"></app-musician-auto-input>
          <button mat-raised-button color="warn" type="button" (click)="deleteArranger(arranger)" *ngIf="!dataCheckOK" >Löschen</button>
        </p>
        <button mat-button (click)="addArranger()" *ngIf="!dataCheckOK" >
          <mat-icon>add_box</mat-icon>
          Arrangeur*in hinzufügen
        </button>

        <h2>Cover-Text</h2>
        <!--<p>Um einen Formatierten Text einzugeben diese Seite (<a href="https://wordtohtml.net/" target="_blank">https://wordtohtml.net/</a>)
          verwenden und den HTML-Code hier einfügen:</p>
        <mat-form-field style="width: 100%">
          <mat-label>Cover-Text</mat-label>
          <textarea matInput maxlength="10000" [(ngModel)]="tune.covertext" [disabled]="dataCheckOK"></textarea>
        </mat-form-field>-->

        <div class="NgxEditor__Wrapper">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
          <ngx-editor [editor]="editor" [(ngModel)]="tune.covertext" [disabled]="dataCheckOK" [placeholder]="'Type here...'"></ngx-editor>
        </div>

        <mat-accordion>
          <mat-expansion-panel *ngFor="let translation of tune.translations" >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ getLanguageDesignation(translation.languageid) }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field appearance="fill" style="width: 80%">
              <mat-label>Titel</mat-label>
              <input matInput placeholder="Titel" [(ngModel)]="translation.title" [disabled]="dataCheckOK">
            </mat-form-field><br>
            <mat-form-field appearance="fill" style="width: 80%">
              <mat-label>Untertitel</mat-label>
              <input matInput placeholder="Titel" [(ngModel)]="translation.subtitle" [disabled]="dataCheckOK">
            </mat-form-field><br>


            <mat-form-field appereance="fill" style="width: 50%">
              <mat-label>Sprache</mat-label>
              <mat-select [(value)]="translation.languageid" [disabled]="dataCheckOK">
                <mat-option *ngFor="let language of languages" [value]="language.id">
                  {{ language.designation }}
                </mat-option>
              </mat-select>
            </mat-form-field><br>

            <!--<p>Um einen Formatierten Text einzugeben diese Seite (<a href="https://wordtohtml.net/" target="_blank">https://wordtohtml.net/</a>)
              verwenden und den HTML-Code hier einfügen:</p>
            <mat-form-field style="width: 100%">
              <mat-label>Cover-Text</mat-label>
              <textarea matInput maxlength="10000" [(ngModel)]="translation.covertext" [disabled]="dataCheckOK"></textarea>
            </mat-form-field>-->

            <div class="NgxEditor__Wrapper">
              <ngx-editor-menu [editor]="getTranslationEditor(translation)" [toolbar]="toolbar"> </ngx-editor-menu>
              <ngx-editor [editor]="getTranslationEditor(translation)" [(ngModel)]="translation.covertext" [disabled]="dataCheckOK" [placeholder]="'Type here...'"></ngx-editor>
            </div>


  <!--           <p>Um einen Formatierten Text einzugeben diese Seite (<a href="https://wordtohtml.net/" target="_blank">https://wordtohtml.net/</a>)
              verwenden und den HTML-Code hier einfügen:</p>
            <mat-form-field style="width: 100%">
              <mat-label>Cover-Text</mat-label>
              <textarea matInput maxlength="2000" [(ngModel)]="translation.covertext"></textarea>
            </mat-form-field>
    -->

            <button mat-raised-button color="warn" (click)="deleteTranslation(translation)">Löschen</button>
          </mat-expansion-panel>
        </mat-accordion>
        <button mat-button (click)="addTranslation()" *ngIf="!dataCheckOK" >
          <mat-icon>add_box</mat-icon>
          Übersetzung hinzufügen
        </button>

        <h3>Tags</h3>
        <app-tags-input-field [tune]="tune" [disabled]="dataCheckOK"></app-tags-input-field>

        <h2>Preiskategorie</h2>
        <mat-accordion *ngIf="hasPricingHistory" >
          <mat-expansion-panel expanded="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Alte Preise
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-list-item *ngFor="let item of pricinghistory">
                <span matListItemTitle>{{ getCatTitle(item.pricingcategoryid) }}</span>
                <span matListItemLine>{{ item.start| date: 'd.M.y' }}</span>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>
          <mat-expansion-panel expanded="true" *ngIf="newPrice" >
            <mat-expansion-panel-header>
              <mat-panel-title>
                Neuer Preis
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-list-item>
                <span matListItemTitle>{{ getCatTitle(newPrice!.pricingcategoryid) }}</span>
                <span matListItemLine>{{ newPrice!.start| date: 'd.M.y' }}</span>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion><br>
        <mat-checkbox [(ngModel)]="tune.isdemo" [disabled]="dataCheckOK" color="primary">ist Demo</mat-checkbox><br>
        <p *ngIf="!hasPricingHistory">Kein Preis definiert</p>
        <button mat-raised-button color="primary" type="button" (click)="openPriceChange()" *ngIf="!tune.isdemo" >Anpassen</button>

        <h2>Stückvorschau</h2>
        <input hidden="true" type="file" #previewInput (change)="onPreviewChange($event)" accept="audio/mpeg3"/>
        <audio controls *ngIf="hasPreview" >
          <source [src]="previewURL" type="audio/mpeg">
        </audio><br>
        <button mat-raised-button color="primary" type="button" (click)="previewInput.click()" *ngIf="!dataCheckOK" >Neue Stückvorschau</button>

        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Urheber-Daten
            </mat-panel-title>
            <mat-panel-description class="warning-msg">
              {{ ipWarning }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field appearance="fill" style="width: 80%">
            <mat-label>Suisa-Werknummer</mat-label>
            <input matInput placeholder="Titel" [(ngModel)]="tune.suisanr" [disabled]="dataCheckOK">
          </mat-form-field><br>
          <mat-form-field appearance="fill" style="width: 80%">
            <mat-label>Urheber</mat-label>
            <input matInput placeholder="Titel" [(ngModel)]="tune.proprietor" [disabled]="dataCheckOK">
          </mat-form-field><br>
          <mat-form-field appearance="fill" style="width: 80%">
            <mat-label>Verlag</mat-label>
            <input matInput placeholder="Titel" [(ngModel)]="tune.publisher" [disabled]="dataCheckOK">
          </mat-form-field><br>
          <mat-form-field appearance="fill" style="width: 80%">
            <mat-label>Label</mat-label>
            <input matInput placeholder="Titel" [(ngModel)]="tune.label" [disabled]="dataCheckOK">
          </mat-form-field><br>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Produktionspartner
            </mat-panel-title>
          </mat-expansion-panel-header>
          <section class="example-section">
            @for (partner of partners; track $index) {
              <mat-checkbox class="example-margin"
                [checked]="isProductionPartner(partner)"
                (change)="setProductionPartner(partner, $event.checked)">
                {{ partner.designation }}
              </mat-checkbox><br>
            }
          </section>

        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Genres
            </mat-panel-title>
            <mat-panel-description class="error-msg">
            </mat-panel-description>
          </mat-expansion-panel-header>

          <app-styles-input-field [tune]="tune" [disabled]="dataCheckOK"></app-styles-input-field>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Tonarten
            </mat-panel-title>
            <mat-panel-description class="error-msg">
              {{ tonalitiesErrMsg }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngFor="let tonality of tunetonalities">
            <mat-form-field appearance="fill">
              <mat-label>Tonart</mat-label>
              <mat-select [(value)]="tonality.id" [disabled]="dataCheckOK">
                <mat-option *ngFor="let tonality of tonalities"  [value]="tonality.id">
                  {{ tonality.designation }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-raised-button color="warn" type="button" (click)="removeTonality(tonality)" *ngIf="!dataCheckOK" >Löschen</button>
            <br>
          </div>
          <mat-form-field appearance="fill"  >
            <mat-label>Neue Tonart</mat-label>
            <mat-select (selectionChange)="addTonality()" [(value)]="newTonality" #newTonalitySelect [disabled]="dataCheckOK">
              <mat-option *ngFor="let tonality of tonalities"  [value]="tonality">
                {{ tonality.designation }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Tempi
            </mat-panel-title>
            <mat-panel-description class="error-msg">
              {{ tempiErrMsg }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div *ngFor="let tempo of tunetempi">
            <app-tempo-card [tempo]="tempo" (delete)="deleteTempo(tempo)" (fileChanged)="updateCountoffFile(tempo, $event)" [disabled]="dataCheckOK"></app-tempo-card>
            <mat-divider></mat-divider>
          </div>
          <button mat-button (click)="addTempo()" *ngIf="!dataCheckOK" >
            <mat-icon>add_box</mat-icon>
            Neues Tempo hinzufügen
          </button>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Tracks
            </mat-panel-title>
            <mat-panel-description class="error-msg">
              {{ tracksErrMsg }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div style="width:100%;overflow-x:scroll;">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
              <ng-container matColumnDef="path">
                <th mat-header-cell *matHeaderCellDef>File</th>
                <td mat-cell *matCellDef="let track">
                  <app-track-file-cell [track]="track" (fileChanged)="updateTrackAudio(track, $event)" [disabled]="dataCheckOK"></app-track-file-cell>
                </td>
              </ng-container>

              <ng-container matColumnDef="tonality">
                <th mat-header-cell *matHeaderCellDef>Tonart</th>
                <td mat-cell *matCellDef="let track">
                  <mat-form-field appearance="fill" style="width:100px" *ngIf="tunetonalities.length>1">
                    <mat-select [(value)]="track.tonality.id" [disabled]="dataCheckOK">
                      <mat-option *ngFor="let tonality of tunetonalities" [value]="tonality.id">
                        {{ tonality.designation }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <p *ngIf="tunetonalities.length==1">
                    {{ tunetonalities[0].designation }}
                  </p>
            </ng-container>

              <ng-container matColumnDef="speed">
                <th mat-header-cell *matHeaderCellDef>Tempo</th>
                <td mat-cell *matCellDef="let track">
                <mat-form-field appearance="fill" style="width:100px" *ngIf="tunetempi.length>1" >
                  <mat-select [(value)]="track.speed" [disabled]="dataCheckOK">
                    <mat-option *ngFor="let speed of tunetempi"  [value]="speed">
                      {{ speed.speed }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <p *ngIf="tunetempi.length==1">
                  {{ tunetempi[0].speed }}
                </p>
              </ng-container>

              <ng-container matColumnDef="instrumentgroup">
                <th mat-header-cell *matHeaderCellDef>Section</th>
                <td mat-cell *matCellDef="let track">
                  <mat-form-field appearance="fill" style="width:180px">
                    <mat-select [(value)]="track.instrumentgroup.id" [disabled]="dataCheckOK">
                      <mat-option *ngFor="let group of instrumentGroups"  [value]="group.id">
                        {{ group.designation }}{{ getLinkedText(group) }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="instrument">
                <th mat-header-cell *matHeaderCellDef>Instrument</th>
                <td mat-cell *matCellDef="let track">
                  <mat-form-field appearance="fill">
                    <mat-select [(value)]="track.instrument.id" [disabled]="dataCheckOK">
                      <mat-option *ngFor="let instrument of instruments"  [value]="instrument.id">
                        <img [src]="getInstrumentIcon(instrument)" class="instrument-icon">
                        {{ instrument.designation }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="voice">
                <th mat-header-cell *matHeaderCellDef>Stimme</th>
                <td mat-cell *matCellDef="let track">
                  <mat-form-field appearance="fill" style="width:150px" >
                    <mat-select [(value)]="track.voice.id" [disabled]="dataCheckOK">
                      <mat-option *ngFor="let voice of getVoices(track)"  [value]="voice.id">
                        {{ voice.designation }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="mainvoice">
                <th mat-header-cell *matHeaderCellDef>Hauptstimme</th>
                <td mat-cell *matCellDef="let track">
                  <mat-checkbox class="example-margin" color="primary"
                      [checked]="track.mainvoice === 1" [disabled]="dataCheckOK">
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="level">
                <th mat-header-cell *matHeaderCellDef>Level</th>
                <td mat-cell *matCellDef="let track">
                  <mat-form-field appearance="fill" style="width:150px" >
                    <mat-select [(value)]="track.level.id" [disabled]="dataCheckOK">
                      <mat-option *ngFor="let level of levels"  [value]="level.id">
                        {{ level.designation }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="musician">
                <th mat-header-cell *matHeaderCellDef>Musiker*in</th>
                <td mat-cell *matCellDef="let track">
                  <app-musician-auto-input [musicianid]="track.trackmusician?.id" (musicianChanged)="changeTrackMusician(track, $event)" [disabled]="dataCheckOK"></app-musician-auto-input>
                </td>
              </ng-container>

              <ng-container matColumnDef="sheets">
                <th mat-header-cell *matHeaderCellDef>Sheets</th>
                <td mat-cell *matCellDef="let track">
                  <div *ngFor="let sheet of track.sheets" class="sheet-button-container">
                    <button mat-button  (click)="previewSheet(sheet)">{{ sheetFilename(sheet) }}</button>
                    <button mat-icon-button (click)="removeSheet(track, sheet)" color="warn" *ngIf="!dataCheckOK" >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                  <button mat-icon-button (click)="addSheet(track)" *ngIf="!dataCheckOK" >
                    <mat-icon>add_box</mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef>Bearbeiten</th>
                <td mat-cell *matCellDef="let track">
                  <button mat-raised-button color="warn" type="button" (click)="deleteTrack(track)" *ngIf="!dataCheckOK" >Löschen</button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>

          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                  showFirstLastButtons
                  aria-label="Select page of tracks">
          </mat-paginator>

          <button mat-raised-button color="accent" type="button" (click)="addTrack()" *ngIf="!dataCheckOK" >Track hinzufügen</button>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Arrangement
            </mat-panel-title>
            <mat-panel-description class="warning-msg">
              {{ arrangementWarnMsg }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-slide-toggle [(ngModel)]="taktData" *ngIf="!dataCheckOK" color="primary">Angaben in Taktzahlen</mat-slide-toggle><br>
          <mat-form-field appearance="fill" *ngIf="taktData" >
            <mat-label>Taktart</mat-label>
            <mat-select [(ngModel)]="tuneMeter" [disabled]="dataCheckOK">
              <mat-option value="2">2/4</mat-option>
              <mat-option value="3">3/4</mat-option>
              <mat-option value="4">4/4</mat-option>
              <mat-option value="6">6/8</mat-option>
              <mat-option value="12">12/8</mat-option>
            </mat-select>
          </mat-form-field><br>

          <!--<mat-form-field appearance="fill" *ngIf="!taktData && tunetempi.length>1" >-->
          <mat-form-field appearance="fill" *ngIf="!taktData" >
              <mat-label>Tempo</mat-label>
            <mat-select [(ngModel)]="arrangementEditTSID">
              <mat-option [value]="undefined">Alle Tempi</mat-option>
              <mat-option *ngFor="let speed of tunetempi" [value]="speed">{{ speed.speed }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div style="width:100%;overflow-x:scroll;">
            <app-arrangement-parts-table [tune]="tune" [taktData]="taktData" [tempo]="shownSpeed" [disabled]="dataCheckOK"></app-arrangement-parts-table>
          </div>

        </mat-expansion-panel>

        @if (false) {
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Band
              </mat-panel-title>
              <mat-panel-description class="warning-msg">
                {{ bandWarnMsg }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <app-band-table [tune]="tune" [disabled]="dataCheckOK"></app-band-table>
          </mat-expansion-panel>

        }

    </mat-accordion>

    <div class="button-row">
      <button mat-raised-button color="primary" (click)="checkTune()">Prüfen</button>
      <button mat-raised-button color="accent" (click)="saveTune()" [disabled]="!dataCheckOK">Speichern</button>
      <button mat-raised-button (click)="cancel()">Abbrechen</button>
    </div>
<!--        </form>-->

</div>
