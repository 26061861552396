<app-main-menu title="{{ musician.firstname }} {{ musician.surname }}" backref="/musicians"></app-main-menu>

<div class="content mat-elevation-z8">
  <mat-grid-list cols="1" rowHeight="100px">
    <mat-grid-tile>
      <mat-form-field appearance="fill" style="width: 50%;">
        <mat-label>Vorname</mat-label>
        <input matInput placeholder="Vorname" [(ngModel)]="musician.firstname">
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 50%;">
        <mat-label>Nachname</mat-label>
        <input matInput placeholder="Vorname" [(ngModel)]="musician.surname">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Homepage</mat-label>
        <input matInput placeholder="Homepage" [(ngModel)]="musician.homepage">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-select [(value)]="instrumentID">
          <mat-option *ngFor="let instrument of instruments"  [value]="instrument.id">
            <img [src]="getInstrumentIcon(instrument)" class="instrument-icon">
            {{ instrument.designation }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
  <app-img-upload tite="Bild"
      [imgPath]="musicianImgPath"
      forcedAspectRatio="1"
      (imgChanged)="changeImg($event)"
      (imgIdChanged)="changeImgId($event)"
      (fileExt)="changeFileExt($event)"></app-img-upload>
  <button mat-raised-button color="accent" (click)="saveMusician()">Speichern</button>
  <button mat-raised-button routerLink="/musicians">Abbrechen</button>
</div>
