<form>
  <mat-form-field *ngIf="!disabled" >
    <input type="text" matInput [value]="musician"
      [matAutocomplete]="auto"
      [formControl]="musicianControl"
      >
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="musicianDisplayFn">
    <mat-option *ngFor="let option of filteredMusicians | async" [value]="option">
      {{ option.firstname }} {{ option.surname }}
    </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <p *ngIf="disabled">{{ musician }}</p>
  <p class="error-msg">{{ errorMsg }}</p>
</form>
