<app-main-menu title="Analytics"></app-main-menu>

<div class="content mat-elevation-z8">

  <h1>band-on Monitoring</h1>

  <mat-form-field>
    <mat-label>Gib einen Bereich ein</mat-label>
    <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker" [max]="maxDate">
      <input matStartDate formControlName="start" placeholder="Start Datum">
      <input matEndDate formControlName="end" placeholder="End Datum" (dateChange)="dateRangeChanged($event)">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    @if (dateRange.controls.start.hasError('matStartDateInvalid')) {
      <mat-error>Ungültiges Startdatum</mat-error>
    }
    @if (dateRange.controls.end.hasError('matEndDateInvalid')) {
      <mat-error>Ungültiges Enddatum</mat-error>
    }
  </mat-form-field>

  <mat-accordion>
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Website
        </mat-panel-title>
      </mat-expansion-panel-header>


      <ngx-charts-line-chart
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="visitors"
        >
      </ngx-charts-line-chart>

      <ngx-charts-bar-horizontal-2d
        [view]="view"
        [scheme]="colorScheme"
        [results]="countries"
        [gradient]="false"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="false"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        xAxisLabel="Besucher"
        yAxisLabel="Land"
        >
      </ngx-charts-bar-horizontal-2d>
    </mat-expansion-panel>

    <mat-expansion-panel expanded="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Newsletter
        </mat-panel-title>
      </mat-expansion-panel-header>

      Total {{ subscriberCount }} Empfänger
      <ngx-charts-bar-vertical
        [view]="view"
        [scheme]="colorScheme"
        [legend]="false"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [xAxis]="true"
        [yAxis]="true"
        xAxisLabel="Datum"
        yAxisLabel="Neue Empfänger"
        [results]="subscriptions"
        >
      </ngx-charts-bar-vertical>
    </mat-expansion-panel>

    <mat-expansion-panel expanded="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Apple App Store
        </mat-panel-title>
      </mat-expansion-panel-header>
      gehe zu <a href="https://appstoreconnect.apple.com" target="_blank">App Store Connect</a>
    </mat-expansion-panel>

    <mat-expansion-panel expanded="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Google Play Store
        </mat-panel-title>
      </mat-expansion-panel-header>
      gehe zu <a href="https://play.google.com/console" target="_blank">Google Play Console</a>
    </mat-expansion-panel>

    <mat-expansion-panel expanded="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          band-on
        </mat-panel-title>
      </mat-expansion-panel-header>

      <h2>Nutzer*innen</h2>
      Total {{ userCount }} Users
      <ngx-charts-bar-vertical
        [view]="view"
        [scheme]="colorScheme"
        [legend]="false"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [xAxis]="true"
        [yAxis]="true"
        xAxisLabel="Datum"
        yAxisLabel="Neue User"
        [results]="bandonUsers"
        >
      </ngx-charts-bar-vertical>

      <h2>Verkäufe (total seit 01.09.2023)</h2>

      <mat-form-field>
        <mat-label>Wähle das Jahr für den Bericht</mat-label>
        <mat-select [(value)]="selectedReportYear">
          @for (year of yearsArray; track $index) {
            <mat-option value="{{ year }}">{{ year }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="getSalesReport()" [disabled]="!selectedReportYear">Detaillierter Bericht</button>

      <ngx-charts-bar-horizontal
        [view]="view"
        [scheme]="colorScheme"
        [results]="groupedPurchases"
        [gradient]="false"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="false"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        xAxisLabel="Einheiten"
        yAxisLabel="SKU"
        >
      </ngx-charts-bar-horizontal>
      <h2>Charts</h2>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="pos">
          <th mat-header-cell *matHeaderCellDef> Position </th>
          <td mat-cell *matCellDef="let element"> {{element.pos}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <!-- PLays Column -->
        <ng-container matColumnDef="calls">
          <th mat-header-cell *matHeaderCellDef> Aufrufe </th>
          <td mat-cell *matCellDef="let element"> {{element.calls}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </mat-expansion-panel>

  </mat-accordion>

</div>
