import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, SessionModel } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { DatePipe, NgFor } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-session-list',
    templateUrl: './session-list.component.html',
    styleUrls: ['./session-list.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatListModule, NgFor, MatButtonModule, RouterLink, MatPaginatorModule, MatIconModule]
})
export class SessionListComponent {
  public searchTerm = '';
  public sessions: SessionModel[] = [];
  _searchedSessions: SessionModel[] = [];

  private unsubscribe$ = new Subject<void>();

  private pageSize = 10;
  private pageIndex = 0;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ) {
  }

  get paginatedSessions(): SessionModel[] {
    return this.searchedSessions.slice(this.pageIndex*this.pageSize, (this.pageIndex+1)*this.pageSize);
  }

  get searchedSessions(): SessionModel[] {
    if(this.searchTerm && this.searchTerm.trim() !== '') {
      return this._searchedSessions;
    }
    return this.sessions;
  }

  ngOnInit(): void {
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    //Get all Tunes
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<SessionModel[]>(environment.apiURL+"/sessions", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (sessions) => {
          this.sessions.length = 0;
          this.sessions.push(...sessions);
          loadingDialog.close();
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchSession(event: any) {
    const text = event.target.value;
    this._searchedSessions = this.sessions;
    if(text && text.trim() !== '') {
      this._searchedSessions = this._searchedSessions.filter((session: SessionModel) => {
        //TODO: Add Recording Date, Add Tags, Add Instruments
        const des = session.designation+' '+this.getDateString(session.date);
        return (des.toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
    }
    this.pageIndex = 0;
  }

  getDateString(inputString: string | undefined) {
    if (inputString) {
      const date = new Date(inputString);
      return this.datePipe.transform(date, 'dd.MM.yyyy');
    }
    return "";
  }
}
