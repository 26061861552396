import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { AuthenticationService, Partner } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { environment } from 'src/environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-partners-list',
    templateUrl: './partners-list.component.html',
    styleUrl: './partners-list.component.scss',
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatListModule, NgFor, MatButtonModule, RouterLink, MatPaginatorModule, MatIconModule]
})
export class PartnersListComponent implements OnInit, OnDestroy {

  public searchTerm = '';
  public partners: Partner[] = [];
  _searchedPartners: Partner[] = [];

  private unsubscribe$ = new Subject<void>();

  private pageSize = 10;
  private pageIndex = 0;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) {
  }

  get paginatedPartners(): Partner[] {
    return this.searchedPartners.slice(this.pageIndex*this.pageSize, (this.pageIndex+1)*this.pageSize);
  }

  get searchedPartners(): Partner[] {
    if(this.searchTerm && this.searchTerm.trim() !== '') {
      return this._searchedPartners;
    }
    return this.partners;
  }

  ngOnInit(): void {
    //Get all Partners
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Partner[]>(environment.apiURL+"/partners", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (partners) => {
          console.log(partners)
          this.partners.length = 0;
          this.partners.push(...partners);
          loadingDialog.close()
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handlePageEvent(event: PageEvent) {

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchMusician(event: any) {
    const text = event.target.value;
    this._searchedPartners = this.partners;
    if(text && text.trim() !== '') {
      this._searchedPartners = this._searchedPartners.filter((partner: Partner) => {
        let des = partner.designation;
        return (des.toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
    }
    this.pageIndex = 0;
  }

}
