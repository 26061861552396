<mat-form-field class="tag-list" appearance="fill" *ngIf="!disabled" >
  <mat-label>Tags</mat-label>
  <mat-chip-grid #tagGrid aria-label="Tag eingeben">
    <mat-chip-row *ngFor="let tag of tune.tags"
      (removed)="removeTag(tag)"
      [editable]="false">
      {{ tag.designation }}
      <button matChipRemove [attr.aria-label]="'entferne '+ tag.designation">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input placeholder="Tag hinzufügen"
      [matChipInputFor]="tagGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="addTag($event)"
      [matAutocomplete]="auto"
      [formControl]="tagControl"/>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="tagDisplayFn">
      <mat-option *ngFor="let option of filteredTags | async" [value]="option">
        {{ option.designation }}
      </mat-option>
    </mat-autocomplete>

  </mat-chip-grid>
</mat-form-field>
<div *ngIf="disabled" >
  <p *ngFor="let tag of tune.tags" >{{ tag.designation }}</p>
</div>
