<h3 *ngIf="title" >{{ title }}</h3>
<div class="image-container">
  <img [src]="imgFile" [ngClass]="{ 'img-icon': forcedAspectRatio==='1', 'img-icon-contain': forcedAspectRatio!=='1'}">
  <input hidden="true" multiple type="file" #imgInput (change)="onImageChange($event)" accept="image/*"/>

  <div *ngIf="!disabled" >
    <button mat-mini-fab class="icon-upload-button" (click)="imgInput.click()" type="button" *ngIf="showUpload">
      <mat-icon>upload_file</mat-icon>
    </button>
    <button mat-mini-fab class="icon-select-button" (click)="openMediaSelect()" type="button" *ngIf="showMediaSelect" >
      <mat-icon>photo_library</mat-icon>
    </button>
  </div>

</div>
