import { UserModel, Role, AuthenticationService } from 'bandon-shared';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Collection, PricingCategory } from 'bandon-shared';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SimpleTuneModel } from 'bandon-shared';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

export interface UserPurchaseItem {
  date: Date;
  collectionid?: number;
  tuneid?: number;
  price?: PricingCategory;
}

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, NgFor, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, NgIf, MatPaginatorModule, MatButtonModule, RouterLink, DatePipe]
})
export class EditUserComponent implements OnInit, AfterViewInit, OnDestroy{

  public userPurchases: UserPurchaseItem[] = [];
  public collections: Collection[] = [];
  public tunes: SimpleTuneModel[] = [];

  displayedColumns: string[] = ['date', 'item']
  public purchasesSource = new MatTableDataSource<UserPurchaseItem>(this.userPurchases);

  public user: UserModel;

  public roles: Role[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  private unsubscribe$ = new Subject<void>();
  private userID = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private dialog: MatDialog

  ){
    this.user = { uid: '', email: '', name: '', language: '', img: '', newsletter: true, username: ''}
  }

  get dateString(): string | null {
//    console.log(this.user)
    if (this.user.registerdate) {
      const date = new Date(this.user.registerdate);
      return this.datePipe.transform(date, 'dd.MM.yyyy');
    }
    return "";
  }

  ngOnInit(): void {
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    let uid = this.activatedRoute.snapshot.paramMap.get('userid');
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    if(uid) {
      this.userID = uid;

      //Load User
      this.httpClient.get<UserModel>(environment.apiURL+'/users/'+uid, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.user = data;
          this.userPurchases.length = 0;

          this.user.purchases?.forEach(purchase => {
            purchase.items.forEach(item=> {
              const purchaseItem: UserPurchaseItem = {
                date: purchase.purchasedate,
                collectionid: item.collectionid,
                tuneid: item.tuneid
              }
              this.userPurchases.push(purchaseItem);
            });
          });
          this.purchasesSource.data = this.userPurchases;
          console.log(data);
          loadingDialog.close();
        });
    }

    //Load all Collections
    this.httpClient.get<Collection[]>(environment.apiURL+"/collections", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (collections) => {
        this.collections.length = 0;
        this.collections.push(...collections);
      }
    );

    //Load all Tunes
    this.httpClient.get<SimpleTuneModel[]>(environment.apiURL+"/tunes", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tunes) => {
          this.tunes.length = 0;
          this.tunes.push(...tunes);
        }
      );

    //Load all Roles
    this.httpClient.get<Role[]>(environment.apiURL+'/roles', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.roles = data;
    });

    this.purchasesSource.filterPredicate = (data: UserPurchaseItem, filter: string) => {
      let modelValue = ' '+this.getDateString(data.date);
      if(data.collectionid && data.collectionid>0) {
        modelValue += ' '+this.getCollectionTitle(data.collectionid);
      }
      if(data.tuneid && data.tuneid>0) {
        modelValue += ' '+this.getTuneDesc(data.tuneid);
      }
      return modelValue.toLowerCase().includes(filter);
    };
  }

  ngAfterViewInit() {
    this.purchasesSource.paginator = this.paginator;
    this.purchasesSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveUser() {
    this.snackBar.open('Speichert Daten')
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();

    formData.append('user', JSON.stringify(this.user));
    if(this.userID) {
      this.httpClient.post<UserModel>(environment.apiURL+`/users/${this.userID}/roles`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.user = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/users');
      });
    }
  }

  hasRole(role: Role): boolean {
    if(this.user) {
      let result = this.user.roles?.filter(e => e.id==role.id)
      return result ? result.length>0 : false;
    }
    return false;
  }

  setRole(role: Role, active: boolean) {
    if(active) {
      let result = this.user.roles?.filter(e => e.id==role.id)
      if(!result || (result && result.length==0)) {
        if(!this.user.roles) {
          this.user.roles = []
        }
        this.user.roles.push(role);
      }
    } else if(this.user.roles) {
      const p = this.user.roles.find(e => e.id===role.id);
      if(p) {
        const index = this.user.roles?.indexOf(p);
        this.user.roles.splice(index, 1);
      }
    }
  }

  getCollectionTitle(collectionid: number) {
    return this.collections.find(c => c.id===collectionid)?.designation;
  }

  getTuneDesc(tuneid: number) {
    return this.tunes.find(t => t.id===tuneid)?.title;
  }

  getDateString(date: Date) {
    return this.datePipe.transform(date, 'dd.MM.yyyy');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.purchasesSource.filter = filterValue.trim().toLowerCase();

    if (this.purchasesSource.paginator) {
      this.purchasesSource.paginator.firstPage();
    }
  }
}
