import { EditSessionComponent } from './pages/recsession/edit-session/edit-session.component';
import { SessionListComponent } from './pages/recsession/session-list/session-list.component';
import { PhotoLibraryComponent } from './pages/photos/photo-library/photo-library.component';
import { EditUserComponent } from './pages/users/edit-user/edit-user.component';
import { EditRoleComponent } from './pages/users/edit-role/edit-role.component';
import { RolesListComponent } from './pages/users/roles-list/roles-list.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { TagsListComponent } from './pages/tags/tags-list/tags-list.component';
import { EditCollectionComponent } from './pages/collections/edit-collection/edit-collection.component';
import { CollectionsListComponent } from './pages/collections/collections-list/collections-list.component';
import { EditComposersComponent } from './pages/composers/edit-composers/edit-composers.component';
import { ComposersListComponent } from './pages/composers/composers-list/composers-list.component';
import { EditInstrumentComponent } from './pages/instruments/edit-instrument/edit-instrument.component';
import { InstrumentListComponent } from './pages/instruments/instrument-list/instrument-list.component';
import { EditMusicianComponent } from './pages/musicians/edit-musician/edit-musician.component';
import { MusiciansListComponent } from './pages/musicians/musicians-list/musicians-list.component';
import { TunesListComponent } from './pages/tunes/tunes-list/tunes-list.component';
import { EditTuneComponent } from './pages/tunes/edit-tune/edit-tune.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StylesListComponent } from './pages/styles/styles-list/styles-list.component';
import { UserEditGuard } from './services/auth/user-edit.guard';
import { SheetsLibraryComponent } from './pages/sheets/sheets-library/sheets-library.component';
import { PricingCatListComponent } from './pages/pricing/pricingCategories/pricing-cat-list/pricing-cat-list.component';
import { PricingCatDetailsComponent } from './pages/pricing/pricingCategories/pricing-cat-details/pricing-cat-details.component';
import { ShopWindowsComponent } from './pages/shop/shop-windows/shop-windows.component';
import { DeleteUserComponent } from './pages/auth/delete-user/delete-user.component';
import { VoicesListComponent } from './pages/voices/voices-list/voices-list.component';
import { EditVoiceComponent } from './pages/voices/edit-voice/edit-voice.component';
import { InstrumentSortingComponent } from './pages/instrument-sorting/instrument-sorting.component';
import { MainAnalyticsComponent } from './pages/analytics/main-analytics/main-analytics.component';
import { PartnersListComponent } from './pages/partners/partners-list/partners-list.component';
import { EditPartnerComponent } from './pages/partners/edit-partner/edit-partner.component';
import { TestaccountComponent } from './pages/users/testaccount/testaccount.component';
import { SideMenuComponent } from './components/navigation/side-menu/side-menu.component';
import { TuneSheetsComponent } from './pages/tunes/tune-sheets/tune-sheets.component';
import { UsergroupListComponent } from './pages/users/usergroups/usergroup-list/usergroup-list.component';
import { EditGroupComponent } from './pages/users/usergroups/edit-group/edit-group.component';
import { ChangeEmailComponent } from './pages/auth/change-email/change-email.component';
import { AppVersionListComponent } from './pages/app-version/app-version-list/app-version-list.component';
import { ConfirmEmailComponent } from './pages/auth/confirm-email copy/confirm-email.component';
import { authGuard } from 'bandon-shared';

const routes: Routes = [
  {
    path: "login", component: LoginComponent
  },
  {
    path: "analytics", component: MainAnalyticsComponent
  },
  {
    path: "auth/reset-password", component: ResetPasswordComponent
  },
  {
    path: "auth/delete-user", component: DeleteUserComponent
  },
  {
    path: "auth/change-email", component: ChangeEmailComponent
  },
  {
    path: "auth/confirm-email", component: ConfirmEmailComponent
  },
  {
    path: "collections", component: CollectionsListComponent, canActivate: [authGuard]
  },
  {
    path: "collections/:collectionid", component: EditCollectionComponent, canActivate: [authGuard]
  },
  {
    path: "composers", component: ComposersListComponent, canActivate: [authGuard]
  },
  {
    path: "composers/:composerid", component: EditComposersComponent, canActivate: [authGuard]
  },
  {
    path: "instruments", component: InstrumentListComponent, canActivate: [authGuard]
  },
  {
    path: "instruments/:instrumentid", component: EditInstrumentComponent, canActivate: [authGuard]
  },
  {
    path :"partners", component: PartnersListComponent, canActivate: [authGuard]
  },
  {
    path :"partners/:partnerid", component: EditPartnerComponent, canActivate: [authGuard]
  },
  {
    path :"photos", component: PhotoLibraryComponent, canActivate: [authGuard]
  },
  {
    path :"pricing/categories", component: PricingCatListComponent, canActivate: [authGuard, UserEditGuard]
  },
  {
    path :"pricing/categories/:categoryid", component: PricingCatDetailsComponent, canActivate: [authGuard, UserEditGuard]
  },
  {
    path: "roles", component: RolesListComponent, canActivate: [authGuard, UserEditGuard]
  },
  {
    path: "roles/:roleid", component: EditRoleComponent, canActivate: [authGuard, UserEditGuard]
  },
  {
    path: "sheets", component: SheetsLibraryComponent, canActivate: [authGuard]
  },
  {
    path: "sessions", component: SessionListComponent, canActivate: [authGuard]
  },
  {
    path: "sessions/:sessionid", component: EditSessionComponent, canActivate: [authGuard]
  },
  {
    path: "shop/windows", component: ShopWindowsComponent, canActivate: [authGuard]
  },
  {
    path: "sorting", component: InstrumentSortingComponent, canActivate: [authGuard]
  },
  {
    path: "styles", component: StylesListComponent, canActivate: [authGuard]
  },
  {
    path: "tags", component: TagsListComponent, canActivate: [authGuard]
  },
  {
    path: "tunes", component: TunesListComponent, canActivate: [authGuard]
  },
  {
    path: "tunes/:tuneid/sheets", component: TuneSheetsComponent, canActivate: [authGuard]
  },
  {
    path: "tunes/:tuneid/:collectionid", component: EditTuneComponent, canActivate: [authGuard]
  },
  {
    path: "tunes/:tuneid", component: EditTuneComponent, canActivate: [authGuard]
  },
  {
    path: "musicians", component: MusiciansListComponent, canActivate: [authGuard]
  },
  {
    path: "musicians/:musicianid", component: EditMusicianComponent, canActivate: [authGuard]
  },
  {
    path: "usergroups", component: UsergroupListComponent, canActivate: [authGuard]
  },
  {
    path: "usergroups/:groupid", component: EditGroupComponent, canActivate: [authGuard]
  },
  {
    path: "users", component: UsersListComponent, canActivate: [authGuard, UserEditGuard]
  },
  {
    path: "users/testaccount", component: TestaccountComponent, canActivate: [authGuard]
  },
  {
    path: "users/:userid", component: EditUserComponent, canActivate: [authGuard, UserEditGuard]
  },
  {
    path: "versions", component: AppVersionListComponent, canActivate: [authGuard, UserEditGuard]
  },
  {
    path: "voices", component: VoicesListComponent, canActivate: [authGuard]
  },
  {
    path: "voices/:voiceid", component: EditVoiceComponent, canActivate: [authGuard]
  },
  {
    path: '', component: LoginComponent
  },
  {
    path: '**', component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
