<app-main-menu title="{{ composer.name }} {{ composer.surname }}" backref="/composers"></app-main-menu>

<div class="content mat-elevation-z8">
  <mat-grid-list cols="2" rowHeight="100px">
    <mat-grid-tile>
      <mat-form-field appearance="fill" style="width: 50%;">
        <mat-label>Vorname</mat-label>
        <input matInput placeholder="Vorname" [(ngModel)]="composer.name">
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 50%;">
        <mat-label>Nachname</mat-label>
        <input matInput placeholder="Nachname" [(ngModel)]="composer.surname">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile rowspan="4">
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Geboren</mat-label>
        <input matInput placeholder="Geboren" [(ngModel)]="composer.born" type="number">
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Gestorben</mat-label>
        <input matInput placeholder="Gestorben" [(ngModel)]="composer.died" type="number">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <button mat-raised-button color="accent" (click)="saveComposer()">Speichern</button>
      <button mat-raised-button routerLink="/composers">Abbrechen</button>
    </mat-grid-tile>
  </mat-grid-list>
</div>
