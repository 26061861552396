import { trigger, transition, style, animate, keyframes } from '@angular/animations';
import { Component, EventEmitter, Input, Output, OnInit, HostListener, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { IMenuItem, NavigationService } from 'src/app/services/navigation.service';
import { LogoutDialogComponent } from '../../ModalTemplates/logout-dialog/logout-dialog.component';
import { SublevelMenuComponent } from './sublevel-menu.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { AuthenticationService, UserPrivilegesService } from 'bandon-shared';
import { UserDataService } from 'src/app/services/user-data.service';

export interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({opacity: 0}),
    animate('350ms',
      style({opacity: 1})
    )
  ]),
  transition(':leave', [
    style({opacity: 1}),
    animate('350ms',
      style({opacity: 0})
    )
  ])
])

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
    animations: [
        fadeInOut,
        trigger('rotate', [
            transition(':enter', [
                animate('1000ms', keyframes([
                    style({ transform: 'rotate(0deg)', offset: 0 }),
                    style({ transform: 'rotate(2turn)', offset: '1' })
                ]))
            ])
        ])
    ],
    standalone: true,
    imports: [NgClass, ExtendedModule, MatIconModule, MatTooltipModule, SublevelMenuComponent, RouterLinkActive, RouterLink]
})
export class SideMenuComponent implements OnInit {
  authService = inject(AuthenticationService)
  userService = inject(UserDataService)
  userPrivilegesService = inject(UserPrivilegesService)
  router = inject(Router)
  navDataService = inject(NavigationService)
  dialog = inject(MatDialog)

  @Output() onToggleSidenav: EventEmitter<SideNavToggle> = new EventEmitter();

  collapsed = false;
  screenWidth = 0;
  multiple: boolean = false;

  constructor(
  ) {}

  get menuItems(): IMenuItem[] {
    return this.navDataService.menuItems;
  }

  get showUsersMenu(): boolean {
    if(this.userService.user) {
      return this.userPrivilegesService.canEditUsers(this.userService.user);
    }
    return false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if(this.screenWidth <= 768) {
      this.collapsed = false;
      this.onToggleSidenav.emit({collapsed: this.collapsed, screenWidth: this.screenWidth})
    }
  }


  ngOnInit(): void {
      this.screenWidth = window.innerWidth;
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.onToggleSidenav.emit({collapsed: this.collapsed, screenWidth: this.screenWidth})
  }

  closeSidenav() {
    this.collapsed = false;
    this.onToggleSidenav.emit({collapsed: this.collapsed, screenWidth: this.screenWidth})
    for(let modelItem of this.menuItems) {
      modelItem.expanded = false;
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('login', { replaceUrl: true });
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.logout()
      }
    });
  }


  handleClick(item: IMenuItem) {
    if (!this.multiple) {
      for(let modelItem of this.menuItems) {
        if (item !== modelItem && modelItem.expanded) {
          modelItem.expanded = false;
        }
      }
    }
    if(!item.expanded) {
      this.collapsed = true;
      this.onToggleSidenav.emit({collapsed: this.collapsed, screenWidth: this.screenWidth})
    }
    item.expanded = !item.expanded;
  }

  getActiveClass(data: IMenuItem): string {
    if(data.state)
      return this.router.url.includes(data.state) ? 'active': '';
    return '';
  }
}
