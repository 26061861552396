<h1 mat-dialog-title>Sheet wählen</h1>
<div class="content">

  <button mat-button (click)="showAll()">Zeige alle</button>
  <div class="structure-container mat-elevation-z8">
    <div class="hierarchyLevel-div" *ngFor="let level of [].constructor(hierarchyLevelsCount); let i = index">
      <mat-list>
        <mat-list-item *ngFor="let item of getFolders(i)" (click)="openFolder(i, item)">
          <mat-icon matListItemIcon>folder</mat-icon>
          <div matListItemTitle>{{ item }}</div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>

  <mat-grid-list cols="4" rowHeight="1:1">
    <mat-grid-tile *ngFor="let sheet of shownSheets" [mat-dialog-close]="sheet">
      <mat-card>
        <mat-card-header>
          {{ sheetFilename(sheet) }}
        </mat-card-header>
        <mat-card-content>
          <pdf-viewer [src]="sheet.sheetSrc"
            [original-size]="false"
            style="width: 200px; height: 200px;">
          </pdf-viewer>
      </mat-card-content>
    </mat-card>
    </mat-grid-tile>
  </mat-grid-list>

</div>


<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Abbrechen</button>
</div>
