<app-main-menu title="{{ group.designation }}" backref="/usergroups"></app-main-menu>

<div class="content mat-elevation-z8">
  <mat-form-field appearance="fill" style="width: 80%">
    <mat-label>Bezeichung</mat-label>
    <input matInput placeholder="Bezeichnung" [(ngModel)]="group.designation">
  </mat-form-field>

  <mat-form-field appearance="fill" style="width: 80%">
    <mat-label>Beschreibung</mat-label>
    <textarea matInput placeholder="Beschreibung" [(ngModel)]="group.description"></textarea>
  </mat-form-field>

  <mat-form-field appearance="fill" style="width: 80%">
    <mat-label>Max. Userzahl</mat-label>
    <input matInput placeholder="Max. Userzahl" type="number" [(ngModel)]="group.userlimit">
  </mat-form-field>

  <h2>Stücke</h2>
  <div class="mat-elevation-z8" style="margin-bottom: 1rem;">
    <mat-list>
      @for (tune of paginatedTunes; track $index) {
        <mat-list-item>
          <img matListItemIcon [src]="tuneImgPath(tune)">
          <span matListItemTitle>{{ tune.title }}</span>
        </mat-list-item>
      }
    </mat-list>
    <mat-paginator
        (page)="handleTunesPageEvent($event)"
        [length]="group.tunes?.length"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
        aria-label="Select page">
    </mat-paginator>
  </div>

  <h2>Collections</h2>
  <div class="mat-elevation-z8" style="margin-bottom: 1rem;">
    <mat-list>
      @for (collection of paginatedCollections; track $index) {
        <mat-list-item>
          <img matListItemIcon [src]="collectionImgPath(collection)">
          <span matListItemTitle>{{ collection.designation }}</span>
        </mat-list-item>
      }
    </mat-list>
    <mat-paginator
        (page)="handleCollectionsPageEvent($event)"
        [length]="group.collections?.length"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
        aria-label="Select page">
    </mat-paginator>
  </div>

  <h2>Users
    @if(group.userlimit) {
      ( {{ group.users?.length }}/{{ group.userlimit }} )
    }
  </h2>
  <div class="mat-elevation-z8" style="margin-bottom: 16px;">
    <mat-form-field style="width:100%">
      <mat-label>Suchen</mat-label>
      <input matInput placeholder="Suche" [(ngModel)]="searchUserTerm" (input)="searchUser($event)">
    </mat-form-field>

    <mat-list>
      @for (user of paginatedUsers; track $index) {
        <mat-list-item class="list-item">
          <span matListItemTitle>
            {{ user.user.name }}
            @if(isOwner(user.user.uid)) {
              (Eigentümer*in)
            }
          </span>
          <span matListItemLine>
            {{ user.user.email }}
            <div class="mat-list-item-content-right">
              @if(!isOwner(user.user.uid)) {
                @if((loggedInUser && isOwner(loggedInUser.uid)) || canEditGroups) {
                  <button mat-stroked-button style="margin-right: 10px;" (click)="setOwner(user.user.uid)">
                    Eigentümer*in
                  </button>
                }
                @if(loggedInUser && (isOwner(loggedInUser.uid) || isAdmin(loggedInUser.uid)) && loggedInUser.uid!==user.user.uid) {
                  @if(isAdmin(user.user.uid)) {
                    <button mat-raised-button color="primary" style="margin-right: 10px;" (click)="changeAdmin(user.user.uid)">
                      Admin
                    </button>
                  }
                  @else {
                    <button mat-stroked-button color="primary" style="margin-right: 10px;" (click)="changeAdmin(user.user.uid)">
                      Admin
                    </button>
                  }
                }
                <button mat-stroked-button color="warn" style="margin-right: 10px;" (click)="removeUser(user.user.uid)">
                  Entfernen
                </button>
              }
            </div>
          </span>
        </mat-list-item>
      }
    </mat-list>
    <mat-paginator
      (page)="handleUsersPageEvent($event)"
      [length]="group.users?.length"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page">
  </mat-paginator>
  </div>
  @if(canAdd) {
    <button mat-button routerLink="/tunes/-1" [disabled]="!enableUserAdd" (click)="addUser(selectedUser)">
      <mat-icon>add_box</mat-icon>
      User hinzufügen
    </button>
    <mat-form-field appearance="fill" style="width:100%">
      <input matInput placeholder="Name" [matAutocomplete]="auto" [formControl]="usersControl">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="styleDisplayFn">
        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">{{ user.name }}: {{ user.email }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  }

  <div class="button-row">
    <button mat-raised-button routerLink="/usergroups" style="margin-right: 1rem;">Abbrechen</button>
    <button mat-raised-button color="accent" (click)="save()">Speichern</button>
  </div>

</div>
