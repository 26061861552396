import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { IChildItem, IMenuItem } from 'src/app/services/navigation.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({opacity: 0}),
    animate('350ms',
      style({opacity: 1})
    )
  ]),
  transition(':leave', [
    style({opacity: 1}),
    animate('350ms',
      style({opacity: 0})
    )
  ])
])

@Component({
    selector: 'app-sublevel-menu',
    template: `
    @if(collapsed && data.sub && data.sub.length > 0) {
      <ul class="sublevel-nav" [@submenu]="expanded
          ? {value: 'visible',
            params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '*'}}
          :  {value: 'hidden',
            params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', heigt: '0'}} ">
        @for (item of data.sub; track $index) {
          @if (!item.needsUserAuth || showUsersMenu) {
            <li class="sublevel-nav-item">
            @if(item.sub && item.sub.length > 0) {
              <a class="sublevel-nav-link" (click)="handleClick(item)" [ngClass]="getActiveClass(item)">
                <mat-icon>{{ item.icon }}</mat-icon>
                @if (collapsed) {
                  <span class="sublevel-link-text" @fadeInOut>{{ item.name}}</span>
                  @if (item.expanded) {
                    <mat-icon class="menu-collapse-icon">expand_more</mat-icon>
                  }
                  @else {
                    <mat-icon class="menu-collapse-icon">expand_less</mat-icon>
                  }
                }
              </a>
            }
            @if(!item.sub || (item.sub && item.sub.length === 0)) {
              <a class="sublevel-nav-link"
                [routerLink]="[item.state]"
                routerLinkActive="active-sublevel"
                [routerLinkActiveOptions]="{exact: true}"
              >
               <mat-icon class="sublevel-link-icon">{{ item.icon }}</mat-icon>
               @if(collapsed) {
                <span class="sublevel-link-text" @fadeInOut>{{ item.name }}</span>
               }
              </a>
            }
            @if(item.sub && item.sub.length > 0) {
              <app-sublevel-menu
                [collapsed]="collapsed"
                [multiple]="multiple"
                [expanded]="item.expanded"
              ></app-sublevel-menu>
            }
          </li>
          }
        }
      </ul>
    }
  `,
    styleUrls: ['./side-menu.component.scss'],
    animations: [
        fadeInOut,
        trigger('submenu', [
            state('hidden', style({
                height: '0',
                overflow: 'hidden'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible <=> hidden', [style({ overflow: 'hidden' }),
                animate('{{transitionParams}}')]),
            transition('void => *', animate(0))
        ])
    ],
    standalone: true,
    imports: [NgClass, ExtendedModule, MatIconModule, RouterLinkActive, RouterLink]
})
export class SublevelMenuComponent implements OnInit {

  @Input() data: IMenuItem = {
    name: '',
    type: 'link'
  }
  @Input() collapsed = false;
  @Input() animating: boolean | undefined;
  @Input() multiple: boolean = false;
  @Input() expanded: boolean | undefined;
  @Input() showUsersMenu: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {

  }

  handleClick(item: any): void {
    if (!this.multiple) {
      if (this.data.sub && this.data.sub.length > 0) {
        for(let item of this.data.sub) {
          if (item !== item && item.expanded) {
            item.expanded = false;
          }
        }
      }
    }
    item.expaned = !item.expanded;
  }

  getActiveClass(item: IChildItem): string {
    if(item.state) {
      return item.expanded && this.router.url.includes(item.state) ? 'active-sublevel' : '';
    }
    return '';
  }
}
