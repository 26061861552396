import { Component } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PhotoBrowserComponent } from '../../../components/photo-library/photo-browser/photo-browser.component';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';


@Component({
    selector: 'app-photo-library',
    templateUrl: './photo-library.component.html',
    styleUrls: ['./photo-library.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' }
    ],
    standalone: true,
    imports: [MainMenuComponent, PhotoBrowserComponent]
})
export class PhotoLibraryComponent {


}
