import { inject, Injectable } from '@angular/core';
import { AuthenticationService } from 'bandon-shared';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {
  authService = inject(AuthenticationService)
  userService = inject(UserDataService)

  constructor(
  ) {
  }

  async init() {
    await this.authService.checkAuthenticated();
    return Promise.resolve().then(() => {});
  }
}
