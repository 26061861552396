import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Photo } from 'bandon-shared';
import { MatButtonModule } from '@angular/material/button';
import { PhotoBrowserComponent } from '../../../components/photo-library/photo-browser/photo-browser.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-photo-select',
    templateUrl: './photo-select.component.html',
    styleUrls: ['./photo-select.component.scss'],
    standalone: true,
    imports: [MatCardModule, PhotoBrowserComponent, MatButtonModule, MatDialogClose]
})
export class PhotoSelectComponent implements OnInit, OnDestroy {

  selectedPhoto: Photo | undefined;

  private photos: Photo[] = [];

  private unsubscribe$ = new Subject<void>();

  private shownPath: string = '';
  private openFolders: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<PhotoSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  get hierarchyLevelsCount(): number {
    let hierarchyCount = this.openFolders.length+1;
    return hierarchyCount;
  }

  get shownPhotos(): Photo[] {
    let out: Photo[] = [];
    if(this.photos) {
      out = this.photos.filter(p => p.path.includes(this.shownPath) && p.id>0);
    }
    return out;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSelect(photo: Photo) {
    this.selectedPhoto = photo;
  }

}
