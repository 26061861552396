<app-main-menu title="Benutzer*innen"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Alle Benutzer*innen</h1>

  <mat-form-field style="width:100%">
    <mat-label>Suchen</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Suche" #input>
    <!--<input matInput placeholder="Suche" [(ngModel)]="searchTerm" (input)="searchUser($event)" #input>-->
  </mat-form-field>

    <table mat-table [dataSource]="dataSource" matSort>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> E-Mail </th>
        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>

      <!-- Registerdate Column -->
      <ng-container matColumnDef="registerdate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Registriert </th>
        <td mat-cell *matCellDef="let row"> {{ getDateString(row.registerdate) }} </td>
      </ng-container>

      <!-- Roles Column -->
      <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Rollen </th>
        <td mat-cell *matCellDef="let row"> {{ getRolesString(row) }} </td>
      </ng-container>

      <!-- Edit Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Edit</th>
        <td mat-cell *matCellDef="let row">
          <button mat-raised-button color="accent" routerLink="/users/{{ row.uid }}">Edit</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

  <mat-paginator
    [length]="users.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>
</div>
