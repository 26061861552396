import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, PricingCategory } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-pricing-cat-details',
    templateUrl: './pricing-cat-details.component.html',
    styleUrls: ['./pricing-cat-details.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatSelectModule, MatOptionModule, MatButtonModule, RouterLink]
})
export class PricingCatDetailsComponent implements OnInit, OnDestroy {
  public category: PricingCategory;

  private unsubscribe$ = new Subject<void>();
  private categoryID: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar
  ){
    this.category = { id: -1, title: '', description: '', glassfysku: '', type: 0, reductions: [] }
  }

  ngOnInit(): void {
    this.categoryID = this.activatedRoute.snapshot.paramMap.get('categoryid');
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    if(this.categoryID>0) {
      //Load Musician
      this.httpClient.get<PricingCategory>(environment.apiURL+'/pricing/categories/'+this.categoryID, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.category = data;

          //Load Musician Image
  //        this.tuneImgFile = this.getTuneIcon(this.tune);
        });

    } else {
      this.category = { id: -1, title: '', description: '', glassfysku: '', type: 0, reductions: [] }
    }

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveCategory() {
    this.snackBar.open('Speichert Daten')
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();

    formData.append('category', JSON.stringify(this.category));
    if(this.categoryID>0) {
      this.httpClient.post<PricingCategory>(environment.apiURL+`/pricing/categories/${this.category.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.category = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/pricing/categories');
      });
    } else {
      this.httpClient.put<PricingCategory>(environment.apiURL+`/pricing/categories`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.category = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/pricing/categories');
      });
    }
  }
}
