<mat-card class="container">
    <mat-card-title>
        {{ 'AUTH.RESETPWD.RESETYOURPWD' | translate }}
    </mat-card-title>
    <mat-card-content>
        <form [formGroup]="formData" (ngSubmit)="onSubmit()" class="flex-form" *ngIf="validForm && !pwdSent">
            <mat-form-field appearance="fill">
                <mat-label>{{ 'AUTH.RESETPWD.PASSWORD' | translate }}</mat-label>
                <input matInput formControlName="password" type="password">
            </mat-form-field>
            <div *ngIf="password?.invalid && (password?.dirty || password?.touched)"
                class="alert alert-danger">

                <div *ngIf="(password?.invalid && password?.touched) || password?.dirty">
                    <p *ngIf="password?.errors?.['required'] || password?.errors?.['minlength']">
                        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="cancel" class="material-icons">cancel</mat-icon>
                        {{ 'AUTH.RESETPWD.MINLENGTH' | translate: { min: 8} }}
                    </p>
                    <p *ngIf="password?.errors?.['required'] || password?.errors?.['hasNumber']">
                        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="cancel" class="material-icons">cancel</mat-icon>
                        {{ 'AUTH.RESETPWD.NEEDSNUMBER' | translate: { min: 1} }}
                    </p>
                    <p *ngIf="password?.errors?.['required'] || password?.errors?.['hasCapitalCase']">
                        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="cancel" class="material-icons">cancel</mat-icon>
                        {{ 'AUTH.RESETPWD.NEEDSCAPITAL' | translate: { min: 1} }}
                    </p>
                    <p *ngIf="password?.errors?.['required'] || password?.errors?.['hasSmallCase']">
                        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="cancel" class="material-icons">cancel</mat-icon>
                        {{ 'AUTH.RESETPWD.NEEDSSMALL' | translate: { min: 1} }}
                    </p>
                    <p *ngIf="password?.errors?.['required'] || password?.errors?.['hasSpecialCharacters']">
                        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="cancel" class="material-icons">cancel</mat-icon>
                        {{ 'AUTH.RESETPWD.NEEDSSPECIAL' | translate: { min: 1} }}
                    </p>
                </div>
            </div>

            <input formControlName="mailadd" style="display:none">
            <button mat-raised-button type="submit" [disabled]="!formData.valid" color="primary">{{ 'AUTH.RESETPWD.RESET' | translate }}</button>
        </form>
        <div *ngIf="!validForm && errmsg">
            {{ errmsg }}
        </div>
        <div *ngIf="!validForm && !errmsg">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="pwdSent">
            {{ 'AUTH.RESETPWD.CONFIRM' | translate }}
            <button mat-raised-button color="primary" (click)="closeTab()">{{ 'AUTH.RESETPWD.CLOSE' | translate }}</button>
        </div>
    </mat-card-content>
</mat-card>
