import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { Musician } from 'bandon-shared';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MusicianAutoInputComponent } from '../../../../components/general/musician-auto-input/musician-auto-input.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-add-image-dialog',
    templateUrl: './add-image-dialog.component.html',
    styleUrls: ['./add-image-dialog.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatDialogContent, MatButtonModule, MatGridListModule, ImageCropperComponent, MusicianAutoInputComponent, MatFormFieldModule, MatSelectModule, MatOptionModule, MatDialogClose]
})
export class AddImageDialogComponent {

  public originalAspectRatio = 1;
  public selectedAspectRatio = 1;

  public imageChangedEvent: any;

  constructor(
    public dialogRef: MatDialogRef<AddImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  async onImageChange(e: any) {

    if(e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      this.data.filename = file.name;

      // Get the file type and name
      const fileType = file.type;
      const fileName = file.name;

      // Use a FileReader to read the content of the file
      const reader = new FileReader();
      reader.readAsDataURL(file);

      // Get the width and height of the image from the FileReader's result
      reader.onload = (e: any) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          this.originalAspectRatio = width/height;
          this.selectedAspectRatio = this.originalAspectRatio;

        };
      };
      this.imageChangedEvent = e;
    }

  }

  imageCropped(event: ImageCroppedEvent) {
    this.data.imgFile = event.base64;

    let arr = this.data.imgFile.split(',');
    let mime = arr[0].match(/:(.*);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    this.data.file = new File([u8arr], this.data.filename, {type: mime});

  }

  changeAuthor(changedAuthor: Musician) {
    if(!this.data.author) {
      this.data.author = { id: changedAuthor.id, firstname: changedAuthor.firstname,
        surname: changedAuthor.surname, image: changedAuthor.image,
        instrument: changedAuthor.instrument }
    } else {
      this.data.author.id = changedAuthor.id;
      this.data.author.firstname = changedAuthor.firstname;
      this.data.author.surname = changedAuthor.surname;
      this.data.author.image = changedAuthor.image;
      this.data.author.instrument = changedAuthor.instrument;
    }
  }
}
