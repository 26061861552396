<mat-card>
  <mat-card-header>
    <mat-card-title>Foto löschen?</mat-card-title>
  </mat-card-header>

  <div mat-dialog-content>
    <p>Sicher?</p>
  </div>
  <mat-card-actions class="button-container">
    <button mat-button (click)="onCancelClick()">Abbrechen</button>
    <button mat-raised-button color="warn" mat-dialog-close="confirmed" cdkFocusInitial>Ok</button>
  </mat-card-actions>
</mat-card>
