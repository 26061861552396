import { Component, OnInit, OnDestroy } from '@angular/core';
import { SimpleTuneModel, Photo, SessionModel, Musician, AuthenticationService } from 'bandon-shared';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe, NgFor, AsyncPipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { PhotoSelectComponent } from '../../photos/photo-select/photo-select.component';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CdkDropList } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MusicianAutoInputComponent } from '../../../components/general/musician-auto-input/musician-auto-input.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-edit-session',
    templateUrl: './edit-session.component.html',
    styleUrls: ['./edit-session.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatDatepickerModule, NgFor, MusicianAutoInputComponent, MatButtonModule, MatIconModule, CdkDropList, MatAutocompleteModule, MatOptionModule, MatGridListModule, RouterLink, AsyncPipe]
})
export class EditSessionComponent implements OnInit, OnDestroy {

  public session: SessionModel;
  public tunes: SimpleTuneModel[] = [];
  public tuneControl = new FormControl<string | SimpleTuneModel>('');
  public filteredTunes!: Observable<SimpleTuneModel[]>;
  public selectedTune?: SimpleTuneModel;
  public enableAdd = false;

  private unsubscribe$ = new Subject<void>();
  private sessionID: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ){
    this.session = { id: -1, designation: '', date: '' }
  }

  ngOnInit(): void {
    this.sessionID = this.activatedRoute.snapshot.paramMap.get('sessionid');
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    if(this.sessionID>0) {
      const loadingDialog = this.dialog.open(LoadingTemplateComponent)
//      console.log('load collection: ', this.sessionID);

      //Load Collection
      this.httpClient.get<SessionModel>(environment.apiURL+'/sessions/'+this.sessionID, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.session = data;

          //Load Musician Image
  //        this.tuneImgFile = this.getTuneIcon(this.tune);
//          console.log('Collection loaded ', this.session);
          loadingDialog.close();
        });

    } else {
      this.session = { id: -1, designation: '', date: '' }
    }

    //Get all Tunes
    this.httpClient.get<SimpleTuneModel[]>(environment.apiURL+"/tunes", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tunes) => {
          this.tunes.length = 0;
          this.tunes.push(...tunes);
        }
      );

    this.filteredTunes = this.tuneControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value: value?.title;
          return name ? this._filter(name as string) : this.tunes.slice();
        })
      )

    this.tuneControl.valueChanges.subscribe(value => {
      if (typeof(value)==='object') {
        const tune = value as SimpleTuneModel;

        this.selectedTune = tune;
        this.enableAdd = true;
//        this.musicianChanged.emit(value as Musician)
      } else {
        this.enableAdd = false;
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addTune() {
    if (this.selectedTune) {
      if(!this.session.tunes) {
        this.session.tunes = []
      }

      this.session.tunes.push(this.selectedTune)
    }
  }

  removeTune(tune: SimpleTuneModel) {
    if(this.session.tunes) {
      const index = this.session.tunes.indexOf(tune);
      this.session.tunes.splice(index, 1)
    }
  }

  styleDisplayFn(tune: SimpleTuneModel): string {
    let out = '';
    if (tune) {
      out = tune.title;
    }
    return out;
  }

  getTuneDesc(tune: SimpleTuneModel): string {
    let out = '';
    if (tune) {
      out = tune.title;
      if (tune.recordingdate) {
        out += ', Recorded:'+this.datePipe.transform(tune.recordingdate, 'dd.MM.yyyy')
      }

    }
    return out;
  }

  addPhoto() {
    const dialogRef = this.dialog.open(PhotoSelectComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('Add Photo', result);
      if(result) {
        if(!this.session.photos) {
          this.session.photos = [];
        }
        this.session.photos?.push(result);
      }
    });
  }

  removePhoto(photo: Photo) {
    if(this.session.photos) {
      this.session.photos.splice(this.session.photos.indexOf(photo), 1);
    }
  }

  getPhotoPath(photo: Photo) {
    return environment.apiURL+"/photoImg/"+photo.id;
  }

  addProducer() {
    const producer: Musician = { id: -1, firstname: '', surname: '', instrument: {
        id: -1, designation: '', img: '', img_dark: '', instrumentorder: 1
      }
    }
    if(!this.session.producers) {
      this.session.producers = [];
    }
    this.session.producers?.push(producer);
  }

  deleteProducer(producer: Musician) {
    if(this.session.producers) {
      const index = this.session.producers.indexOf(producer);
      this.session.producers.splice(index, 1);
    }
  }

  updateProducer(producer: Musician, updatedProducer: Musician) {
    producer.id = updatedProducer.id;
    producer.firstname = updatedProducer.firstname;
    producer.surname = updatedProducer.surname;
  }

  saveSession() {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    formData.append('session', JSON.stringify(this.session));
    console.log(JSON.stringify(this.session))
    if(this.sessionID>0) {
      this.httpClient.post<SessionModel>(environment.apiURL+`/sessions/${this.session.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.session = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/sessions');
      });
    } else {
      this.httpClient.put<SessionModel>(environment.apiURL+`/sessions`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.session = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/sessions');
      });
    }
  }

  private _filter(value: string): SimpleTuneModel[] {
    const filterValue = value.toLowerCase();

    return this.tunes.filter(option => (option.title).toLowerCase().includes(filterValue))
  }
}
