import { Component, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService, Language } from 'bandon-shared';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-testaccount',
    templateUrl: './testaccount.component.html',
    styleUrl: './testaccount.component.scss',
    standalone: true,
    imports: [MainMenuComponent, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatSelectModule, MatOptionModule, MatButtonModule]
})
export class TestaccountComponent implements OnInit{

  formData: FormGroup;

  public languages: Language[] = [];

  private unsubscribe$ = new Subject<void>();

  get email() { return this.formData.get('email'); }
  get copyemail() { return this.formData.get('copyemail'); }

  get name() { return this.formData.get('name'); }
  get language() { return this.formData.get('language'); }

  pwdSent = false

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
    )
  {
      this.formData = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.minLength(3)]),
        email: new FormControl('', [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        ]),
        copyemail: new FormControl('', [
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        ]),
        language: new FormControl('de'),
        mailadd: new FormControl('', [
          this.forbiddenFieldValidator()
        ])
      });
  }

  ngOnInit(): void {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    //Get all Languages
    this.httpClient.get<Language[]>(environment.apiURL+"/languages", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (language) => {
        this.languages.length = 0;
        this.languages.push(...language.filter(l => {
          return l.id==='en' || l.id==='de';
        }));
        console.log(this.languages)
      }
    );

  }

  onSubmit() {
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    let removeDate = new Date();
    removeDate.setMonth(removeDate.getMonth() + 3);
    removeDate = this.adjustDate(removeDate)

    const formData = {
      email: this.email?.value,
      name: this.name?.value,
      role: 2,
      roleRemoveDate: removeDate,
      newsletter: true,
      sendConfirmMail: true,
      generatePassword: true,
      password: '',
      emailCopy: this.copyemail?.value,
      language: this.language?.value
    };

    console.log(formData)
    this.httpClient.post<any>(environment.apiURL+"/auth/register", formData , {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.formData.reset()
          loadingDialog.close()
        },
        error: () => {
          this.snackBar.open("Es gab einen Fehler beim Speichern", "schliessen");
          loadingDialog.close()
        }
      });
  }

  forbiddenFieldValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? {forbiddenField: {value: control.value}} : null;
    };
  }

  generatePasswort() {
    return Math.random().toString(36).slice(-8);
  }

  adjustDate(date: Date) {
    const timeZoneOffset = date.getTimezoneOffset();
    return new Date(date.getTime() - timeZoneOffset * 60000);
  }

}
