import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService, Musician } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MusiciansService {

  musiciansSubject = new BehaviorSubject<Musician[]>([]);
  musicians$ = this.musiciansSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) { }

  public getMusicians(forceRefresh = false) {
    if(this.musiciansSubject.getValue().length===0 || forceRefresh) {
    //Get all Tunes
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Musician[]>(environment.apiURL+"/musicians", {headers})
      .subscribe(
        (tunes) => {
          this.musiciansSubject.next(tunes);
//          console.log('got musicians: ', tunes)
//          this.musicians.length = 0;
//          this.musicians.push(...tunes);
        }
      );
    } else {
      this.musiciansSubject.next(this.musiciansSubject.getValue())
//      console.log('didnt get Musicians')
    }
  }
}
