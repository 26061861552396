import { AuthenticationService, Style, Tune } from 'bandon-shared';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-styles-input-field',
    templateUrl: './styles-input-field.component.html',
    styleUrls: ['./styles-input-field.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormFieldModule, MatChipsModule, NgFor, MatIconModule, MatAutocompleteModule, ReactiveFormsModule, MatOptionModule, AsyncPipe]
})
export class StylesInputFieldComponent implements OnInit, OnDestroy {
  @Input() tune!: Tune
  @Input() disabled: boolean = false;


  allStyles: Style[] = [];
  public styleControl = new FormControl<string | Style>('');
  public filteredStyles!: Observable<Style[]>;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private _snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.updateStylesList();

    this.filteredStyles = this.styleControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value: value?.designation;
        return name ? this._filter(name as string) : this.allStyles.slice();
      })
    )

    this.styleControl.valueChanges.subscribe(value => {
      if (typeof(value)==='object') {
        const style = value as Style;

        if(!this.tune.styles) {
          this.tune.styles = []
        }

        if (style) {
          this.tune.styles.push(style)
        }
//        this.musicianChanged.emit(value as Musician)
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateStylesList(): void {
    //Get all Tunes
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Style[]>(environment.apiURL+"/styles", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tunes) => {
          this.allStyles.length = 0;
          this.allStyles.push(...tunes);
        }
      );
  }

  removeStyle(style: Style) {
    if(this.tune.styles) {
      const index = this.tune.styles.indexOf(style);
      this.tune.styles.splice(index, 1)
    }
  }

  addStyle(event: MatChipInputEvent): void {
    const value = (event.value || '').trim()

    if (value) {
      const existing_Style = this.allStyles.find(e => e.designation==value)
      if(!this.tune.styles) {
        this.tune.styles = []
      }

      if (existing_Style) {
        this.tune.styles.push(existing_Style)
      } else {
/*        const data = {
          designation: value
        };

        const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
        this.httpClient.put<Style>(environment.apiURL+`/styles`, data, {headers})
        .subscribe(res => {
          console.log(res);

          if(!this.tune.styles) {
            this.tune.styles = []
          }

          if (res) {
            this.tune.styles.push(res)
          }
        });*/
      }
    }

    // Clear the input value
    event.chipInput!.clear()
  }

  styleDisplayFn(style: Style): string {
    return style && style.designation ? style.designation : '';
  }

  private _filter(value: string): Style[] {
    const filterValue = value.toLowerCase();

    return this.allStyles.filter(option => (option.designation).toLowerCase().includes(filterValue))
  }

}
