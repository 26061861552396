<app-main-menu title="Preiskategorien"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Shop Schaufenster</h1>

  <ul>
    <li *ngFor="let window of shopWindows" class="window-list-item">
        <app-img-upload title="Bild"
          [imgPath]="windowImgPath(window)"
          forcedAspectRatio="2"
          (imgChanged)="updateImg(window, $event)"
          (fileExt)="updateImgExt(window, $event)"
          (imgIdChanged)="updateImgId(window, $event)">
        </app-img-upload>

        <mat-form-field>
          <mat-label>Collection</mat-label>
          <mat-select [(value)]="window.collectionid" (selectionChange)="changeWindow(window)">
            <mat-option *ngFor="let collection of collections" [value]="collection.id">
              {{ collection.designation }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field>
          <mat-label>Bezeichnung</mat-label>
          <input matInput placeholder="Style" [value]="tag.designation" (change)="changeWindow(tag, $event)">
        </mat-form-field> -->
        <button mat-raised-button color="warn" type="button" (click)="deleteWindow(window)" class="mat-list-item-content-right">Löschen</button>
    </li>
  </ul>

  <button mat-button (click)="addWindow()">
    <mat-icon>add_box</mat-icon>
    Schaufenster hinzufügen
  </button>

</div>
