<mat-card>
  <mat-card-header>
    <mat-card-title>Sheet hinzufügen</mat-card-title>
  </mat-card-header>

  <div mat-dialog-content>
    @if(!assign) {
      <button mat-stroked-button (click)="imgInput.click()">Sheet wählen</button><br>
      <input hidden="true" multiple type="file" #imgInput (change)="onSheetChange($event)" accept="application/pdf"/>

      <p>Vorschau</p>
      <pdf-viewer [src]="pdfSrc"
        [original-size]="false"
        style="width: 500px; height: 300px;">
      </pdf-viewer>
    }

    @if(!assign) {
      <p>Typ</p>
      <mat-form-field appearance="fill">
        <mat-label>Sheet-Typ</mat-label>
        <mat-select [(value)]="data.typid">
          <mat-option *ngFor="let type of sheetTypes" [value]="type.id">
            {{ type.designation }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <p>Autor*in</p>
      <app-musician-auto-input (musicianChanged)="changeAuthor($event)"></app-musician-auto-input>
    } @else {
      <p>{{ sheet?.type?.designation }}</p>
    }

    @if(tune) {
      <p>Zu folgenden Tracks hinzufügen:</p>
      @for (track of tune.tracks; track $index) {
        <p>
          <mat-checkbox [checked]="modeID===1" (change)="onCheckboxChange($event, track.id)">
            {{ track.instrument?.designation }}
            @if(track.level && track.level.id!==0) {
              - {{ track.level.designation }}
            }
            @if(track.speed) {
              - Tempo {{ track.speed.speed }}
            }
          </mat-checkbox>
        </p>
      }
    }

  </div>

  <mat-card-actions class="button-container">
    <button mat-button (click)="onCancelClick()">Abbrechen</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial [disabled]="!pdfSrc && !assign">Ok</button>
  </mat-card-actions>
</mat-card>
