<app-main-menu title="Genres"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Alle Genres</h1>

  <mat-list>
    <mat-list-item *ngFor="let style of styles" class="genre-list-item">
      <span matListItemTitle>
        <mat-form-field>
          <mat-label>Bezeichnung</mat-label>
          <input matInput placeholder="Style" [value]="style.designation" (change)="changeStyle(style, $event)">
        </mat-form-field>
        <button mat-raised-button color="warn" type="button" (click)="deleteStyle(style)" class="mat-list-item-content-right">Löschen</button>
      </span>
      <span matListItemLine></span>
    </mat-list-item>
  </mat-list>

  <button mat-button (click)="addStyle()">
    <mat-icon>add_box</mat-icon>
    Genre hinzufügen
  </button>
</div>
