import { AuthenticationService, Musician } from 'bandon-shared';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MusiciansService } from 'src/app/services/data/musicians.service';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-musician-auto-input',
    templateUrl: './musician-auto-input.component.html',
    styleUrls: ['./musician-auto-input.component.scss'],
    standalone: true,
    imports: [
      ReactiveFormsModule,
      FormsModule,
      NgIf,
      MatFormFieldModule,
      MatInputModule,
      MatAutocompleteModule,
      NgFor,
      MatOptionModule,
      AsyncPipe
    ]
})
export class MusicianAutoInputComponent {
  @Input() musicianid: number;
  @Input() disabled: boolean = false;

  @Output() musicianChanged: EventEmitter<Musician> = new EventEmitter<Musician>();

  public musicianControl = new FormControl<Musician | string | undefined>('');
  public filteredMusicians!: Observable<Musician[]>;
  public musicians: Musician[] = [];
  public musician: string = '';
  public errorMsg: string = '';

  private unsubscribe$ = new Subject<void>();


  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private musiciansService: MusiciansService
  ) {
    this.musicianid = -1;
  }

  ngOnInit(): void {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.musiciansService.musicians$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (musicians) => {
          this.musicians.length = 0;
          this.musicians.push(...musicians);
          if(this.musicianid>0) {
            const m = this.musicians.find(m => m.id===this.musicianid);
            if(m) {
              this.musician = this.musicianDisplayFn(m);
              this.musicianControl.patchValue(m)
            } else {
              this.musician = '';
              this.musicianControl.patchValue(undefined)
            }
          }
        }
      );

    this.musiciansService.getMusicians();

    this.filteredMusicians = this.musicianControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value: value?.firstname+' '+value?.surname;
        return name ? this._filter(name as string) : this.musicians.slice();
      })
    )

    this.musicianControl.valueChanges.subscribe(value => {
        if (typeof(value)==='object') {
          this.musicianChanged.emit(value as Musician)
          this.errorMsg = ""
        } else {
          this.musicianChanged.emit(undefined)
//          this.errorMsg = "Musiker*in unbekannt -> wird nicht übernommen";
        }
    })

  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const m = this.musicians.find(m => m.id===this.musicianid);
    if(m) {
      this.musician = this.musicianDisplayFn(m);
      this.musicianControl.patchValue(m)
    } else {
      this.musician = '';
      this.musicianControl.patchValue(undefined)
    }
  }


  musicianDisplayFn(musician: Musician): string {
    return musician && musician.firstname && musician.surname ? musician.firstname+' '+musician.surname : '';
  }

  private _filter(value: string): Musician[] {
    const filterValue = value.toLowerCase();

    return this.musicians.filter(option => (option.firstname+' '+option.surname).toLowerCase().includes(filterValue))
  }

}
