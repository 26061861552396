<div class="button-div">
  <button mat-raised-button color="primary" type="button" (click)="sortAndAddEnds()" *ngIf="!disabled" >Enden generieren</button>
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!-- Designation Column -->
  <ng-container matColumnDef="bezeichnung">
    <th mat-header-cell *matHeaderCellDef> Bezeichnung </th>
    <td mat-cell *matCellDef="let part">
      <mat-form-field appearance="fill">
        <mat-label>Bezeichnung</mat-label>
        <input matInput placeholder="Titel" [(ngModel)]="part.designation" [disabled]="disabled">
      </mat-form-field><br>
    </td>
  </ng-container>

  <!-- Start Column -->
  <ng-container matColumnDef="start">
    <th mat-header-cell *matHeaderCellDef> Start </th>
    <td mat-cell *matCellDef="let part">
      <mat-form-field appearance="fill" *ngIf="taktData">
        <mat-label>Takt Start</mat-label>
        <input matInput placeholder="Start" [(ngModel)]="part.barstart" [disabled]="disabled">
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="!taktData">
        <mat-label>Zeit Start [ms]</mat-label>
        <input matInput placeholder="Start" [(ngModel)]="part.timestart" appTimeInput [disabled]="disabled">
      </mat-form-field>
    </td>
  </ng-container>

  <!-- End Column -->
  <ng-container matColumnDef="ende">
    <th mat-header-cell *matHeaderCellDef> Ende </th>
    <td mat-cell *matCellDef="let part">
      <mat-form-field appearance="fill" *ngIf="taktData">
        <mat-label>Takt Ende</mat-label>
        <input matInput placeholder="Ende" [(ngModel)]="part.barend" [disabled]="disabled">
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="!taktData">
        <mat-label>Zeit Ende [ms]</mat-label>
        <input matInput placeholder="Ende" [(ngModel)]="part.timeend" appTimeInput [disabled]="disabled">
      </mat-form-field>
    </td>
  </ng-container>

  <!-- Pickup Column -->
  <ng-container matColumnDef="pickup">
    <th mat-header-cell *matHeaderCellDef> Auftakt </th>
    <td mat-cell *matCellDef="let part">
      <mat-form-field appearance="fill" *ngIf="taktData">
        <mat-label>Schläge Auftakt</mat-label>
        <input matInput placeholder="Auftakt" [(ngModel)]="part.pickup" [disabled]="disabled">
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="!taktData">
        <mat-label>Zeit Auftakt [ms]</mat-label>
        <input matInput placeholder="Auftakt" [(ngModel)]="part.timepickup" appTimeInput [showZero]="true" [disabled]="disabled">
      </mat-form-field>
    </td>
  </ng-container>

  <!-- Meter Column -->
  <ng-container matColumnDef="meter">
    <th mat-header-cell *matHeaderCellDef> Neue Taktart </th>
    <td mat-cell *matCellDef="let part">
      <mat-form-field appearance="fill" *ngIf="!taktData" >
        <mat-label>Taktart</mat-label>
        <mat-select [(value)]="part.meterid" [disabled]="disabled">
          <mat-option *ngFor="let meter of meters"  [value]="meter.id">
            {{ meter.nn }}/{{ meter.dd }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <!-- Tempo Column -->
  <ng-container matColumnDef="tempo">
    <th mat-header-cell *matHeaderCellDef> Neues Tempo </th>
    <td mat-cell *matCellDef="let part">
      <mat-form-field appearance="fill" *ngIf="!taktData">
        <mat-label>Tempo</mat-label>
        <input matInput placeholder="Auftakt" [(ngModel)]="part.tempo" [disabled]="disabled">
      </mat-form-field>
    </td>
  </ng-container>

  <!-- Tonaltiy Column -->
  <ng-container matColumnDef="tonality">
    <th mat-header-cell *matHeaderCellDef> Neue Tonart </th>
    <td mat-cell *matCellDef="let part">
      <mat-form-field appearance="fill"  *ngIf="!taktData">
        <mat-label>Neue Tonart</mat-label>
        <mat-select [(value)]="part.tonalityid" [disabled]="disabled">
          <mat-option *ngFor="let tonality of tonalities"  [value]="tonality.id">
            {{ tonality.designation }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <!-- Barnumber Column -->
  <ng-container matColumnDef="barnumber">
    <th mat-header-cell *matHeaderCellDef> Neue Taktzahl </th>
    <td mat-cell *matCellDef="let part">
      <mat-form-field appearance="fill" *ngIf="!taktData">
        <mat-label>Taktzahl</mat-label>
        <input matInput placeholder="Auftakt" [(ngModel)]="part.barstartnumber" [disabled]="disabled">
      </mat-form-field>
    </td>
  </ng-container>

  <!-- ShowBarSlider Column -->
  <ng-container matColumnDef="showbarslider">
    <th mat-header-cell *matHeaderCellDef> Zeige Takte </th>
    <td mat-cell *matCellDef="let part">
      <mat-checkbox [(ngModel)]="part.showbarslider" color="primary">Zeige Takte</mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef>Bearbeiten</th>
    <td mat-cell *matCellDef="let track">
      <button mat-raised-button color="warn" type="button" (click)="deletePart(track)" *ngIf="!disabled" >Löschen</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<button mat-button (click)="addPart()" *ngIf="!disabled" >
  <mat-icon>add_box</mat-icon>
  Neuer Teil hinzufügen
</button>

<!--<input type="file" (change)="midiTest($event)" accept=".mid">-->

