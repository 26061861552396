import { Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { Color, ScaleType, LineChartModule, BarChartModule } from '@swimlane/ngx-charts';
import { parse } from 'date-fns';
import { DatePipe, formatDate } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { AuthenticationService } from 'bandon-shared';

interface TuneChartEntry {
  pos: number;
  title: string;
  calls: number;
}

@Component({
    selector: 'app-main-analytics',
    templateUrl: './main-analytics.component.html',
    styleUrl: './main-analytics.component.scss',
    standalone: true,
    imports: [
      MainMenuComponent,
      MatFormFieldModule,
      MatDatepickerModule,
      ReactiveFormsModule,
      MatExpansionModule,
      LineChartModule,
      BarChartModule,
      MatTableModule,
      MatButtonModule,
      MatSelectModule
    ]
})
export class MainAnalyticsComponent {

  dateRange = new FormGroup({
    start: new FormControl<Date | null>(this.getTwoWeeksAgo()),
    end: new FormControl<Date | null>(new Date())
  })
  maxDate = new Date();

  dateStart = this.getTwoWeeksAgo();
  dateEnd = new Date();

  visitors = [];
  countries = [];

  subscriberCount = 0;
  subscriptions = [];

  userCount = 0;
  bandonUsers = [];

  groupedPurchases = [];

  displayedColumns: string[] = ['pos', 'title', 'calls'];
  dataSource = new MatTableDataSource<TuneChartEntry>();


  view: any[2] = [700, 400];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Datum';
  yAxisLabel: string = 'Besucher';
  timeline: boolean = true;

  schemeType: string = 'linear';


  colorScheme: Color = {
    name: 'myScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#b90329', '#d40d53'],
  };

  selectedReportYear = new Date().getFullYear()-1;
  yearsArray: number[] = [];


  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    const currentYear: number = new Date().getFullYear();

    // Create an array of years from 2000 until the current year
    for (let year = 2023; year <= currentYear; year++) {
      this.yearsArray.push(year);
    }
    this.selectedReportYear = this.yearsArray[this.yearsArray.length-2];

    this.refreshAnalytics();
  }

  refreshAnalytics() {
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)

    const startString = this.datePipe.transform(this.dateStart, 'yyyy-MM-dd');
    const endString = this.datePipe.transform(this.dateEnd, 'yyyy-MM-dd');

    //Get Google Analytics data
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    let params = new HttpParams()
      .append("start_date", startString!)
      .append("end_date", endString!);

    this.httpClient.get<any>(environment.apiURL+"/analytics/google", {headers, params})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (report) => {
          let visitors: any[] = [];
          let newVisitors: any[] = [];
          let series = { "name": "Besucher", "series": visitors }
          let newSeries = { "name": "Neue Besucher", "series": newVisitors }

          let countries: any[] = []

          for(const row of report.rows) {
            const dateValue: string = row.dimension_values[0].value;
            const activeUsers: number = Number(row.metric_values[0].value);
            const newUsers: number = Number(row.metric_values[1].value);
            const countryValue: string = row.dimension_values[1].value;
//            console.log(`${dateValue}: ${activeUsers}, ${country}`)
            const year = dateValue.substring(0,4);
            const month = dateValue.substring(4,6);
            const day = dateValue.substring(6,8);
            const dateString = `${day}.${month}.${year}`;
            let visitor: any = visitors.find(v => v.name===dateString);
            let newVisitor: any = newVisitors.find(v => v.name===dateString);
            if(visitor) {
              const newUserCount = visitor.value + activeUsers
              visitor.value = newUserCount;
            } else {
              visitors.push({
                "name": dateString, "value": activeUsers
              });
            }
            if(newVisitor) {
              let newUserCount = newVisitor.value + newUsers
              newVisitor.value = newUserCount;
            } else {
              newVisitors.push({
                "name": dateString, "value": newUsers
              });
            }


            let country: any = countries.find(v => v.name===countryValue);
            if(country) {
              country.series[0].value += activeUsers;
            } else {
              countries.push({
                "name": countryValue,
                "series": [{ "name": "Besucher", "value": activeUsers}]
              });
            }
          }
          visitors.sort((d1, d2)=> {
            let date1 = parse(d1.name, "dd.MM.yyyy", new Date());
            let date2 = parse(d2.name, "dd.MM.yyyy", new Date())
            return date1.getTime() - date2.getTime();
          });
          Object.assign(this, { visitors: [series, newSeries] })
          Object.assign(this, { countries: countries })
          loadingDialog.close();
        }
      );

    //Get CleverReach Data
    this.httpClient.get<any>(environment.apiURL+"/newsletter/subscriptions", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( data => {
      const dates: any[] = [];
      const currentDate = new Date(this.dateStart);

      while (currentDate <= this.dateEnd) {
        const dateString = this.datePipe.transform(currentDate, 'dd.MM.yyyy');

        const subscriberData = data.filter((subscriber: any) => {
          const userDate = new Date(subscriber.registered*1000);
          return this.areDatesEqual(userDate, currentDate);
        });

        if (subscriberData) {
          dates.push({ name: dateString, value: subscriberData.length });
        } else {
          dates.push({ name: dateString, value: 0 });
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }

      this.subscriberCount = data.length;
      Object.assign(this, { subscriptions: dates });
    });

    //Get Users data
    this.httpClient.get<any>(environment.apiURL+"/analytics/users", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( data => {

      const dates: any[] = [];
      const currentDate = new Date(this.dateStart);

      while (currentDate <= this.dateEnd) {
        const dateString = this.datePipe.transform(currentDate, 'dd.MM.yyyy');

        const userData = data.filter((user: any) => {
          const userDate = new Date(user.registerdate);
          return this.areDatesEqual(userDate, currentDate);
        });

        if (userData) {
          dates.push({ name: dateString, value: userData.length });
        } else {
          dates.push({ name: dateString, value: 0 });
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }

      this.userCount = data.length;
      Object.assign(this, { bandonUsers: dates });
    });

    //Get Grouped Sales data
    this.httpClient.get<any>(environment.apiURL+"/analytics/groupedsales", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( data => {
      const salegroups: any[] = [];

      for(const salegroup of data) {
        if(salegroup.collectionid) {
          salegroups.push({ name: salegroup.designation, value: salegroup.groupCount})
        } else if(salegroup.tuneid) {
          salegroups.push({ name: salegroup.title, value: salegroup.groupCount})
        }
      }
      Object.assign(this, { groupedPurchases: salegroups });

    });

    //Get Tune Charts
    this.httpClient.get<any>(environment.apiURL+"/analytics/tunecharts", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( data => {
      const entries: TuneChartEntry[] = []
      let pos = 1;
      for(const t of data) {
        let recDateString = '';
        if(t.recordingdate) {
          const recDate = new Date(t.recordingdate);
          if(this.datePipe.transform(recDate, 'dd.MM.yyyy')) {
            recDateString = `(${this.datePipe.transform(recDate, 'dd.MM.yyyy')!})`;
          }
        }
        entries.push({
          pos,
          title: `${t.title} ${recDateString}`,
          calls: t.groupCount
        });
        pos ++;
      }
      this.dataSource.data = entries;
    });
  }

  onSelect(event: any) {
//    console.log(event);
  }

  dateRangeChanged(event: any) {
    if(this.dateRange.value.start && this.dateRange.value.end) {
      this.dateStart = this.dateRange.value.start;
      this.dateEnd = this.dateRange.value.end;
      this.refreshAnalytics();
    }
  }

  getTwoWeeksAgo() {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date 2 weeks ago
    let twoWeeksAgo = new Date(currentDate);
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return twoWeeksAgo;
  }

  areDatesEqual(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  getSalesReport() {
    //Get Google Analytics data
    const url = this.selectedReportYear ? `${environment.apiURL}/analytics/salesreport?year=${this.selectedReportYear}` : `${environment.apiURL}/analytics/salesreport`;

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    this.httpClient.get(url, {headers, responseType: 'arraybuffer'})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( data => {
      console.log(data)
      const blob = new Blob([data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);

      // Open the PDF in a new tab
      window.open(pdfUrl, '_blank');
    });
  }
}
