<app-main-menu title="Sessions"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Alle Sessions</h1>

  <mat-form-field style="width:100%">
    <mat-label>Suchen</mat-label>
    <input matInput placeholder="Suche" [(ngModel)]="searchTerm" (input)="searchSession($event)">
  </mat-form-field>

  <mat-list>
    <mat-list-item *ngFor="let session of paginatedSessions" class="session-list-item">
      <span matListItemTitle>{{ session.designation }}
        <p class="mat-list-item-content-right">
          <button mat-raised-button color="accent" routerLink="/sessions/{{ session.id }}">
            Edit
          </button>
          <!-- <button mat-raised-button color="warn">
            Löschen
          </button> -->
        </p>
      </span>
      <p matListItemLine>
        {{ getDateString(session.date) }}
      </p>
    </mat-list-item>
  </mat-list>

  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="sessions.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>


  <button mat-button routerLink="/sessions/-1">
    <mat-icon>add_box</mat-icon>
    Session hinzufügen
  </button>
</div>
