import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, Musician, Sheet, SheetType, Tune } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MusicianAutoInputComponent } from '../../../../components/general/musician-auto-input/musician-auto-input.component';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-add-sheet-dialog',
    templateUrl: './add-sheet-dialog.component.html',
    styleUrls: ['./add-sheet-dialog.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatDialogContent, MatButtonModule, PdfViewerModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MusicianAutoInputComponent, MatCheckboxModule, MatDialogClose]
})
export class AddSheetDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('imgInput', { static: false }) imgInput!: ElementRef;

  public sheetChangedEvent: any;

  public sheetTypes: SheetType[] = [];

  public pdfSrc = '';

  private unsubscribe$ = new Subject<void>();

  private trackIds: number [] = [];

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<AddSheetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  get mode(): string {
    if(this.data && this.data.mode) {
      return this.data.mode;
    }
    return 'single'
  }

  get modeID(): number {
    if(this.mode) {
      if(this.mode==='empty') {
        return 1;
      } else if(this.mode==='rhythm') {
        return 2;
      } else if(this.mode==='part') {
        return 3;
      }
    }
    return -1;
  }

  get tune(): Tune | undefined {
    if(this.data && this.data.tune) {
      return this.data.tune;
    }
    return undefined;
  }

  get assign(): boolean {
    if(this.data && this.data.assign) {
      return true;
    }
    return false;
  }

  get sheet(): Sheet | undefined {
    if(this.data && this.data.sheet) {
      return this.data.sheet;
    }
    return undefined;
  }

  ngOnInit(): void {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<SheetType[]>(environment.apiURL+"/sheettypes", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (sheettypes) => {
        this.sheetTypes = sheettypes;
      }
    );

    this.data.typid = this.modeID;
    if(this.modeID == 1 && this.tune) {
      this.tune.tracks?.forEach(t => this.trackIds.push(t.id));
    }
    this.data.trackids = this.trackIds;
  }

  ngAfterViewInit(): void {
    console.log(this.imgInput)
    if(!this.assign) {
      this.imgInput.nativeElement.click();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  async onSheetChange(e: any) {

    if(e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      this.data.filename = file.name;
      this.data.file = file;

      this.sheetChangedEvent = e;

      let reader = new FileReader();

      reader.onload = (e: any) => {
        this.pdfSrc = e.target.result;
      }

      reader.readAsArrayBuffer(file);
    }

  }

  changeAuthor(changedAuthor: Musician) {
    if(!this.data.author) {
      this.data.author = { id: changedAuthor.id, firstname: changedAuthor.firstname,
        surname: changedAuthor.surname, image: changedAuthor.image,
        instrument: changedAuthor.instrument }
    } else {
      this.data.author.id = changedAuthor.id;
      this.data.author.firstname = changedAuthor.firstname;
      this.data.author.surname = changedAuthor.surname;
      this.data.author.image = changedAuthor.image;
      this.data.author.instrument = changedAuthor.instrument;
    }
  }

  onCheckboxChange(event: any, trackid: number) {
    if(event.checked && !this.trackIds.includes(trackid)) {
      this.trackIds.push(trackid);
    } else if(this.trackIds.includes(trackid)) {
      this.trackIds.splice(this.trackIds.indexOf(trackid), 1);
    }
    this.data.trackids = this.trackIds;
  }
}
