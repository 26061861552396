<app-main-menu title="Preiskategorien"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Alle Preiskategorien</h1>

  <mat-form-field style="width:100%">
    <mat-label>Suchen</mat-label>
    <input matInput placeholder="Suche" [(ngModel)]="searchTerm" (input)="searchCategory($event)">
  </mat-form-field>

  <mat-list>
    <mat-list-item *ngFor="let cat of paginatedCategories" class="cat-list-item">
      <span matListItemTitle>{{ cat.title }}
        <button mat-raised-button color="accent" class="mat-list-item-content-right" routerLink="/pricing/categories/{{ cat.id }}">
          Edit
        </button>
      </span>
      <span matListItemLine>{{ cat.glassfysku }}: {{ cat.description }}</span>
    </mat-list-item>
  </mat-list>

  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="categories.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>


  <button mat-button routerLink="/pricing/categories/-1">
    <mat-icon>add_box</mat-icon>
    Stück hinzufügen
  </button>
</div>
