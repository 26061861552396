<div class="content">
  <div fxLayout="row wrap" fxLayoutGap="16px grid">
      @for (item of filteredItems; track $index) {
        @if(isValidItem(item)) {
          <div [fxFlex]="columnWidth"
            (click)="handleClick($event, item)"
            (dblclick)="handleDblClick($event, item)"
          >
            <app-sheet-item-view
              [item]="item"
              [mode]="mode"
              [selected]="selectedItem===item"
              (onDelete)="deleteSheet($event)"
              (onAssign)="assignSheet($event)"
            ></app-sheet-item-view>
          </div>
        }
      }
  </div>
</div>
