import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { MatFabMenuModule } from '@angular-material-extensions/fab-menu';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxEditorModule } from 'ngx-editor';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppLoaderService } from './app/services/app-loader.service';
import { LibConfig } from 'bandon-shared/lib/config/lib-config';
import { AuthInterceptor, LibConfigService } from 'bandon-shared';

const libConfig: LibConfig = {
  apiServerUrl: environment.apiURL,
  apiKey: environment.apiKey,
  loginUrl: '/login'
};

if (environment.production) {
  enableProdMode();
}

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function serviceLoader(appLoader: AppLoaderService) {
  return () => appLoader.init();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        importProvidersFrom(
          BrowserModule,
          AppRoutingModule,
          MatButtonModule,
          MatIconModule,
          MatInputModule,
          MatFormFieldModule,
          MatCardModule,
          ReactiveFormsModule,
          MatProgressSpinnerModule,
          MatStepperModule, MatSidenavModule,
          MatToolbarModule, MatListModule,
          MatDatepickerModule, MatNativeDateModule,
          MatTableModule, MatSelectModule, MatCheckboxModule,
          MatPaginatorModule, MatSortModule, MatGridListModule,
          MatExpansionModule, FormsModule, MatBadgeModule,
          MatSlideToggleModule, MatSnackBarModule,
          MatAutocompleteModule, MatChipsModule, DragDropModule,
          MatDialogModule, PdfViewerModule,
          NgxEditorModule, FlexLayoutModule, NgxChartsModule,
          MatTooltipModule, MatTreeModule, MatMenuModule,
          OverlayModule, CdkAccordionModule, MatFabMenuModule,
          HttpClient,
          TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
          })
        ),
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
        {
          provide: APP_INITIALIZER,
          useFactory: serviceLoader,
          deps: [AppLoaderService],
          multi: true
        },
        { provide: 'LIB_CONFIG', useValue: libConfig },
        { provide: LibConfigService, useClass: LibConfigService, deps: ['LIB_CONFIG'] },
        provideAnimations(),
    ]
})
  .catch(err => console.error(err));
