import { DatePipe, NgFor, AsyncPipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthenticationService, Collection, Partner, SimpleTuneModel } from 'bandon-shared';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { environment } from 'src/environments/environment';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-edit-partner',
    templateUrl: './edit-partner.component.html',
    styleUrl: './edit-partner.component.scss',
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatListModule, MatButtonModule, MatIconModule, MatAutocompleteModule, NgFor, MatOptionModule, RouterLink, AsyncPipe]
})
export class EditPartnerComponent implements OnInit, OnDestroy {

  public partner: Partner;
  public tuneSkus: SimpleTuneModel[] = [];
  public collectionSkus: Collection[] = [];

  public skuControl = new FormControl<string | SimpleTuneModel | Collection>('');

  public filteredTunes!: Observable<SimpleTuneModel[]>;
  public selectedTune?: SimpleTuneModel;

  public filteredCollections!: Observable<Collection[]>;
  public selectedCollection?: Collection;

  public enableAdd = false;

  private unsubscribe$ = new Subject<void>();
  private partnerID: any;

  private tunes: SimpleTuneModel[] =[];
  private collections: Collection[] = [];


  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ){
    this.partner = { id: -1, designation: '', skus: []};
  }

  ngOnInit(): void {
    this.partnerID = this.activatedRoute.snapshot.paramMap.get('partnerid');
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    if(this.partnerID>0) {
      const loadingDialog = this.dialog.open(LoadingTemplateComponent)

      //Load Musician
      this.httpClient.get<Partner>(environment.apiURL+'/partners/'+this.partnerID, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.partner = data;

          //Load Musician Image
  //        this.tuneImgFile = this.getTuneIcon(this.tune);
          console.log('Partner loaded ', this.partner);
          this.updateSkus();
          loadingDialog.close();
        });

    } else {
      this.partner = { id: -1, designation: '', skus: []};
    }

    //Load all Tunes
    this.httpClient.get<SimpleTuneModel[]>(environment.apiURL+"/tunes", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (tunes) => {
        this.tunes.length = 0;
        this.tunes.push(...tunes);
        this.updateSkus();
      }
    );

    //Load all Collections
    this.httpClient.get<Collection[]>(environment.apiURL+"/collections", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (collections) => {
          this.collections.length = 0;
          this.collections.push(...collections);
        }
      );

      this.filteredTunes = this.skuControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          if(value) {
            const name = typeof value === 'string' ? value: this.styleDisplayFn(value);
            return this._filterTunes(name as string);
          }
          const out: any[] = this.tunes.slice();
          return out;
        })
      )
      this.filteredCollections = this.skuControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          if(value) {
            const name = typeof value === 'string' ? value: this.styleDisplayFn(value);
            return this._filterCollections(name as string);
          }
          const out: any[] = this.collections.slice();
          return out;
        })
      )

      this.skuControl.valueChanges.subscribe(value => {
        if (typeof(value)==='object') {
          if (value && 'title' in value) {
            const tune = value as SimpleTuneModel;

            this.selectedTune = tune;
          } else if(value && 'designation' in value) {
            const collection = value as Collection;
            this.selectedCollection = collection;
          }
          this.enableAdd = true;
    //        this.musicianChanged.emit(value as Musician)
        } else {
          this.enableAdd = false;
        }
      })
  }

  updateSkus() {
    if(this.tunes.length>0 && this.partner && this.partner.skus.length>0) {
      this.tuneSkus = this.tunes.filter(t => this.partner.skus.find(sku => sku.tuneid===t.id))
    }
    if(this.collections.length>0 && this.partner && this.partner.skus.length>0) {
      this.collectionSkus = this.collections.filter(t => this.partner.skus.find(sku => sku.collectionid===t.id))
    }

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  savePartner() {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    formData.append('partner', JSON.stringify(this.partner));
    if(this.partnerID>0) {
      this.httpClient.post<Partner>(environment.apiURL+`/partners/${this.partner.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.partner = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/partners');
      });
    } else {
      this.httpClient.put<Partner>(environment.apiURL+`/partners`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.partner = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/partners');
      });
    }
  }

  addSku() {
    if(this.selectedTune) {
      const exists = this.partner.skus.find(sku => sku.tuneid===this.selectedTune?.id);
      if(!exists) {
        this.partner.skus.push({id: -1, partnerid: this.partner.id, tuneid: this.selectedTune.id})
      }
    } else if(this.selectedCollection) {
      const exists = this.partner.skus.find(sku => sku.collectionid===this.selectedCollection?.id);
      if(!exists) {
        this.partner.skus.push({id: -1, partnerid: this.partner.id, collectionid: this.selectedCollection.id})
      }
    }
    console.log(this.partner.skus)
//    this.selectedTune = undefined;
//    this.selectedCollection = undefined;
    this.updateSkus()
  }

  styleDisplayFn(sku: SimpleTuneModel | Collection): string {
    let out = '';
    if (sku && 'title' in sku) {
      out = sku.title;
    } else if (sku && 'designation' in sku) {
      out = sku.designation;
    }
    return out;
  }

  getTuneDesc(tune: SimpleTuneModel): string {
    let out = '';
    if (tune) {
      out = tune.title;
      if (tune.recordingdate) {
        out += ', Recorded:'+this.datePipe.transform(tune.recordingdate, 'dd.MM.yyyy')
      }

    }
    return out;
  }

  getCollectionDesc(collection: Collection): string {
    let out = '';
    if (collection) {
      out = collection.designation;
    }
    return out;
  }

  private _filterTunes(value: string): any[] {
    const filterValue = value.toLowerCase();

    const out: any[] = this.tunes.filter(option => (option.title).toLowerCase().includes(filterValue));
    return out;
  }

  private _filterCollections(value: string): any[] {
    const filterValue = value.toLowerCase();

    const out: any[] = this.collections.filter(option => (option.designation).toLowerCase().includes(filterValue));
    return out;
  }

  removeTune(tune: SimpleTuneModel) {
    let entry = this.partner.skus.find(sku => sku.tuneid===tune.id)
    if(entry) {
      this.partner.skus.splice(this.partner.skus.indexOf(entry), 1)
    }
    this.updateSkus()
  }

  removeCollection(collection: Collection) {
    let entry = this.partner.skus.find(sku => sku.collectionid===collection.id)
    if(entry) {
      this.partner.skus.splice(this.partner.skus.indexOf(entry), 1)
    }
    this.updateSkus()
  }
}
