<app-main-menu title="{{ partner.designation }}" backref="/partners"></app-main-menu>

<div class="content mat-elevation-z8">
  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Bezeichnung</mat-label>
    <input matInput placeholder="Vorname" [(ngModel)]="partner.designation">
  </mat-form-field>
  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Adresse</mat-label>
    <input matInput placeholder="Adresse" [(ngModel)]="partner.address">
  </mat-form-field>
  <h2>Skus</h2>
  <mat-list>
  @for (tune of tuneSkus; track $index) {
    <mat-list-item class="partner-list-item">
      <span matListItemTitle>
        Stück: {{ tune.title }}
        <button mat-raised-button color="warn" (click)="removeTune(tune)" class="mat-list-item-content-right">
          Entfernen
        </button>
      </span>
      <span matListItemLine> </span>
    </mat-list-item>
}
  @for (collection of collectionSkus; track $index) {
    <mat-list-item class="partner-list-item">
      <span matListItemTitle>
        Collection: {{ collection.designation }}
        <button mat-raised-button color="warn" (click)="removeCollection(collection)" class="mat-list-item-content-right">
          Entfernen
        </button>
      </span>
      <span matListItemLine> </span>

    </mat-list-item>
}
  </mat-list>

  <div class="button-row" >
    <button mat-button (click)="addSku()" [disabled]="!enableAdd">
      <mat-icon>arrow_upward</mat-icon>
    </button>
  </div>
  <mat-form-field appearance="fill" style="width:100%">
    <input matInput placeholder="Bezeichnung" [matAutocomplete]="auto" [formControl]="skuControl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="styleDisplayFn">
      <mat-option *ngFor="let tune of filteredTunes | async" [value]="tune">{{ getTuneDesc(tune) }}</mat-option>
      <mat-option *ngFor="let collection of filteredCollections | async" [value]="collection">{{ collection.designation }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>


  <button mat-raised-button color="accent" (click)="savePartner()">Speichern</button>
  <button mat-raised-button routerLink="/partners">Abbrechen</button>
</div>
