import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FolderViewItem } from '../folder-view/folder-view.component';
import { environment } from 'src/environments/environment';
import { AuthenticationService, Musician, Photo } from 'bandon-shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { transliterate } from 'transliteration';
import { Overlay, CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { DeleteImageDialogComponent } from 'src/app/pages/photos/photo-library/delete-image-dialog/delete-image-dialog.component';
import { PhotoDirNode } from '../photo-browser/photo-browser.component';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MusicianAutoInputComponent } from '../../general/musician-auto-input/musician-auto-input.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatIconModule } from '@angular/material/icon';
import { ImgUploadComponent } from '../../general/img-upload/img-upload.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-item-view',
    templateUrl: './item-view.component.html',
    styleUrl: './item-view.component.scss',
    standalone: true,
    imports: [MatCardModule, CdkOverlayOrigin, ImgUploadComponent, MatIconModule, CdkAccordionModule, MusicianAutoInputComponent, MatFormFieldModule, MatInputModule, MatDatepickerModule, ReactiveFormsModule, FormsModule, MatButtonModule, CdkConnectedOverlay]
})
export class ItemViewComponent implements OnDestroy {

  @Input() item: FolderViewItem | undefined;
  @Input() mode: 'select' | 'edit' = 'select';
  @Input() selected = false;

  @Output() onDelete: EventEmitter<PhotoDirNode> = new EventEmitter();

  @ViewChild('overlayTemplate') overlayTemplate: ElementRef | undefined;

  ctxMenuOpened = false;

  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  expandedIndex = 0;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  get name(): string {
    if(this.item) {
      return this.item.name;
    }
    return '';
  }

  get id(): number {
    if(this.item && 'id' in this.item.item) {
      return this.item.item.id;
    }
    return 0;
  }

  get photoPath(): string {
    if(this.item && 'id' in this.item.item) {
      return environment.apiURL+"/photoImg/"+this.item.item.id;
    }
    return ''
  }

  get photo(): Photo | undefined {
    if(this.item && 'id' in this.item.item) {
      return this.item.item as Photo;
    }
    return undefined;
  }

  get date(): Date | undefined {
    if(this.photo) {
      return this.photo.date
    }
    return undefined;
  }

  set date(date: Date) {
    if(this.photo) {
      this.photo.date = date;
    }
  }

  get type(): string {
    if(this.item) {
      return this.item.type;
    }
    return '';
  }

  get authorid() {
    if(this.photo && this.photo.author) {
      return this.photo.author.id
    }
    return -1;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('document:click', ['$event'])
  closeOverlays(event: MouseEvent): void {
    // Close all overlays when clicking outside
    if (!this.isClickInsideOverlay(event)) {
      this.ctxMenuOpened = false;
    }
  }

  private isClickInsideOverlay(event: MouseEvent): boolean {
    if (this.overlayTemplate) {
      const overlayElement = this.overlayTemplate.nativeElement as HTMLElement;
      return overlayElement.contains(event.target as Node);
    }
    return false;
  }

  updatePhoto(photo: Photo | undefined, image: Blob) {
    if(photo) {
      photo.newImage = image;
      this.commitUpdate(photo);
    }
  }

  updatePhotoExt(photo: Photo | undefined, fileExt: string) {
    if(photo) {
      photo.imgSaveFilename = fileExt;
      //    this.tune.newImageExt = fileExt;
    }
  }

  dateChanged(photo: Photo | undefined, event: any) {
    if(photo) {
      this.commitUpdate(photo);
    }
  }

  changeAuthor(photo: Photo | undefined, changedAuthor: Musician) {
    if(photo) {
      if(!photo.author) {
        photo.author = { id: changedAuthor.id, firstname: changedAuthor.firstname,
          surname: changedAuthor.surname, image: changedAuthor.image,
          instrument: changedAuthor.instrument }
      } else {
        photo.author.id = changedAuthor.id;
        photo.author.firstname = changedAuthor.firstname;
        photo.author.surname = changedAuthor.surname;
        photo.author.image = changedAuthor.image;
        photo.author.instrument = changedAuthor.instrument;
      }
      this.commitUpdate(photo);
    }
  }

  commitUpdate(photo: Photo) {
    this.snackBar.open('Speichert Daten')
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    if(photo.newImage) {
      let filename = transliterate(photo.imgSaveFilename!)
      formData.append('img', photo.newImage, filename);
      photo.newImage = undefined;

      let newPath = photo.path.slice(0, photo.path.lastIndexOf('/')+1).concat(filename);
      photo.path = newPath;
    }

    formData.append('photo', JSON.stringify(photo));

    if(photo.id>0) {
      this.httpClient.post<Photo>(environment.apiURL+`/photos/${photo.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.snackBar.dismiss();
//          this.refreshPictures();
          },
        error: error => {
          console.log(error);
          this.snackBar.dismiss();
//          this.refreshPictures();
          }
      });
    } else {
      this.httpClient.put<Photo>(environment.apiURL+`/photos`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.snackBar.dismiss();
//          this.refreshPictures();
          },
        error: error => {
          console.log(error);
          this.snackBar.dismiss();
//          this.refreshPictures();
          }
      });
    }
  }

  handleContextMenu(event: MouseEvent) {
    event.preventDefault();

//    this.ctxMenuOpened = !this.ctxMenuOpened;
  }

  menuAction() {
    this.ctxMenuOpened = false;
  }

  removePhoto() {
    if(this.photo) {
      const dialogRef = this.dialog.open(DeleteImageDialogComponent);

      dialogRef.afterClosed().subscribe(result => {
        if(result==='confirmed') {
          this.snackBar.open('Foto wird gelöscht')
          const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
          const formData = new FormData();
          this.httpClient.delete(environment.apiURL+`/photos/${this.photo!.id}`, {headers})
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: resp => {
              console.log(resp);
              this.snackBar.dismiss();
              this.onDelete.emit(this.item?.item as PhotoDirNode);
            },
            error: error => {
              console.log(error);
              this.snackBar.dismiss();
              this.onDelete.emit(this.item?.item as PhotoDirNode);
            }
          });
        }
      });
    }
  }

}
