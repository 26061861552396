import { AuthenticationService, Instrument, Musician, Photo } from 'bandon-shared';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { transliterate } from 'transliteration'
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatButtonModule } from '@angular/material/button';
import { ImgUploadComponent } from '../../../components/general/img-upload/img-upload.component';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-edit-musician',
    templateUrl: './edit-musician.component.html',
    styleUrls: ['./edit-musician.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatGridListModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatSelectModule, NgFor, MatOptionModule, ImgUploadComponent, MatButtonModule, RouterLink]
})
export class EditMusicianComponent implements OnInit, OnDestroy {

  public musician: Musician;
  public instruments: Instrument[] = [];

  private unsubscribe$ = new Subject<void>();
  private musicianID: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ){
    this.musician = { id: -1, surname: '', firstname: '0', instrument: { id: -1, designation: '', img: '', img_dark: '', instrumentorder: 1}}
  }

  get musicianImgPath() {
    if(this.musician.picture) {
      return environment.apiURL+"/photoImg/"+this.musician.picture.id;
    }
    return '';
  }

  get instrumentID() {
    if(this.musician && this.musician.instrument) {
      return this.musician.instrument.id;
    }
    return -1;
  }

  set instrumentID(value: number) {
    if(this.musician) {
      if(this.musician.instrument) {
        this.musician.instrument.id = value;
      } else {
        this.musician.instrument = { id: value, designation: '', img: '', img_dark: '', instrumentorder: 1}
      }
    }
  }

  ngOnInit(): void {
    this.musicianID = this.activatedRoute.snapshot.paramMap.get('musicianid');
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    if(this.musicianID>0) {
      const loadingDialog = this.dialog.open(LoadingTemplateComponent)
      console.log('load musician: ', this.musicianID);

      //Load Musician
      this.httpClient.get<Musician>(environment.apiURL+'/musicians/'+this.musicianID, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.musician = data;

          //Load Musician Image
  //        this.tuneImgFile = this.getTuneIcon(this.tune);
          console.log('Musician loaded ', this.musician);
          loadingDialog.close();
        });

    } else {
      this.musician = { id: -1, surname: '', firstname: '', instrument: { id: -1, designation: '', img: '', img_dark: '', instrumentorder: 1}}
    }

    //Load all possible Instruments
    this.httpClient.get<Instrument[]>(environment.apiURL+'/instruments', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.instruments = data;
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getInstrumentIcon(instrument: Instrument): string {
    if(instrument && instrument.id>0) {
      return environment.apiURL+"/instrImg/"+instrument.img;
    }
    return '';
  }

  saveMusician() {
    this.snackBar.open('Speichert Daten')
    if(this.musician.newImage &&
      ((this.musician.imgid && this.musician.imgid!<=0) || !this.musician.imgid))
    {
      //Upload Picture and obtain ID
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      const formData = new FormData();
      let photo: Photo = {
        id: -1,
        path: 'Portraits/'+this.musician.newImageExt,
        date: new Date(),
        authorid: -1
      }

      let filename = transliterate(this.musician.newImageExt!)

      formData.append('img', this.musician.newImage, filename);
      this.musician.newImage = undefined;

      formData.append('photo', JSON.stringify(photo));
      console.log(photo);

      this.httpClient.put<Photo>(environment.apiURL+`/photos`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.musician.imgid = resp.id;
          this.sendMusicianData();
        },
        error: error => {
          console.log(error);
          this.snackBar.dismiss();
        }
      });
    } else {
      this.sendMusicianData();
    }
  }

  private sendMusicianData(): void {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    formData.append('musician', JSON.stringify(this.musician));
    if(this.musicianID>0) {
      this.httpClient.post<Musician>(environment.apiURL+`/musicians/${this.musician.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.musician = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/musicians');
      });
    } else {
      this.httpClient.put<Musician>(environment.apiURL+`/musicians`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.musician = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/musicians');
      });
    }
  }

  changeImg(event: Blob) {
    this.musician.newImage = event;
  }

  changeFileExt(event: string) {
    this.musician.newImageExt = event; //event.substring(event.lastIndexOf('.'));
  }

  changeImgId(id: number) {
        this.musician.imgid = id;
        this.musician.newImage = undefined;
  }

}
