import { Component, OnDestroy, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { AbstractControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-email',
  standalone: true,
  imports: [
    MatCardModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    TranslateModule
  ],
  templateUrl: './change-email.component.html',
  styleUrl: './change-email.component.scss'
})
export class ChangeEmailComponent implements OnInit, OnDestroy {
  route = inject(ActivatedRoute)
  httpClient = inject(HttpClient)
  translate = inject(TranslateService)

  get validForm(): boolean {
    if (this.token)
      return true;
    return false;
  }

  token: String | undefined
  errmsg: String | undefined
  confirmed = false
  user_id = ''
  newEmail = '';

  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.user_id = params['user_id'];
        if (this.user_id && params['token']) {
          const headers = new HttpHeaders()
          .set('Authorization', 'Bearer '+environment.apiKey)
          const http_params = new HttpParams()
          .set('token', params['token']);

          this.httpClient.get<any>(`${environment.apiURL}/users/${this.user_id}/emailchange`, {headers, params: http_params})
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: data => {
                this.token = params['token'];
                this.newEmail = data.newemail;
              },
              error: error => {
                this.translate.get('AUTH.INVALIDFORM')
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(value => this.errmsg = value)
                console.log(error);
              }
            })
        } else {
          this.translate.get('AUTH.INVALIDFORM')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(value => this.errmsg = value)
        }
      })
  }

  onSubmit() {
    //TODO: other endpoint
    const headers = new HttpHeaders().set('Authorization', 'Bearer '+this.token);
    this.httpClient.delete(`${environment.apiURL}/users/${this.user_id}/emailchange`, {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: data => {
        this.confirmed = true;
      },
      error: error => {
        console.log(error)
        this.translate.get('AUTH.CHANGEEMAIL.ERROR')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(value => this.errmsg = value)
        this.token = undefined
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  forbiddenFieldValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? {forbiddenField: {value: control.value}} : null;
    };
  }


  closeTab() {
    window.close();
  }

}
