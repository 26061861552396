import { UserDataService } from './../user-data.service';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService, UserPrivilegesService } from 'bandon-shared';

@Injectable({
  providedIn: 'root'
})
export class UserEditGuard  {
  authService = inject(AuthenticationService)
  userService = inject(UserDataService)
  router = inject(Router)
  userPrivilegesService = inject(UserPrivilegesService)

  constructor() { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.userService.user && this.userPrivilegesService.canEditUsers(this.userService.user)) {
      return true;
    }
    this.router.navigate(['/tunes']);
    return false;
  }

}
