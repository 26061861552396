<app-main-menu title="Partner"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Alle Partner</h1>

  <mat-form-field style="width:100%">
    <mat-label>Suchen</mat-label>
    <input matInput placeholder="Suche" [(ngModel)]="searchTerm" (input)="searchMusician($event)">
  </mat-form-field>

  <mat-list>
    <mat-list-item *ngFor="let partner of paginatedPartners" class="partner-list-item">
      <span matListItemTitle>{{ partner.designation }}
        <p class="mat-list-item-content-right">
          <button mat-raised-button color="accent" routerLink="/partners/{{ partner.id }}">
            Edit
          </button>
          <!-- <button mat-raised-button color="warn">
            Löschen
          </button> -->
        </p>
      </span>
      <span matListItemLine> </span>
    </mat-list-item>
  </mat-list>

  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="partners.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>


  <button mat-button routerLink="/partners/-1">
    <mat-icon>add_box</mat-icon>
    Partner hinzufügen
  </button>
</div>
