<mat-card class="container">
  <mat-card-title>
      {{ 'AUTH.CHANGEEMAIL.CHANGETO' | translate: { newemail: newEmail } }}
  </mat-card-title>
  <mat-card-content>
      <div *ngIf="validForm && !confirmed">
        <button mat-raised-button color="primary" (click)="onSubmit()">
          {{ 'AUTH.CHANGEEMAIL.CHANGE' | translate }}
        </button>
      </div>
      <div *ngIf="!validForm && errmsg">
        {{ errmsg }}
      </div>
      <div *ngIf="!validForm && !errmsg">
          <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="confirmed">
        {{ 'AUTH.CHANGEEMAIL.CONFIRMED' | translate: { newemail: newEmail } }}
        <button mat-raised-button color="primary" (click)="closeTab()">
          {{ 'AUTH.CHANGEEMAIL.CLOSE' | translate }}
        </button>
      </div>
  </mat-card-content>
</mat-card>
