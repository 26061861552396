import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-delete-image-dialog',
    templateUrl: './delete-image-dialog.component.html',
    styleUrls: ['./delete-image-dialog.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatDialogContent, MatButtonModule, MatDialogClose]
})
export class DeleteImageDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
