import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from '../../ModalTemplates/help-dialog/help-dialog.component';
import { Location, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
    standalone: true,
    imports: [MatToolbarModule, NgIf, MatButtonModule, MatIconModule]
})
export class MainMenuComponent {

  @Input() title: any;
  @Input() backref: any;

  constructor(
    public dialog: MatDialog,
    private location: Location) {}

  get isTestserver() {
    return environment.apiURL.includes('test.band')
  }

  openHelp(): void {
    const dialogRef = this.dialog.open(HelpDialogComponent);
  }

  back(): void {
    this.location.back();
  }
}
