import { SimpleTuneModel, Language, Photo, Partner, UserGroup, AuthenticationService } from 'bandon-shared';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil, Observable, startWith, map } from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { moveItemInArray, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop'
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DatePipe, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { transliterate } from 'transliteration';
import { PricingCatChangePopupComponent } from '../../pricing/pricingCategories/pricing-cat-change-popup/pricing-cat-change-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { Collection, CollectionTitles, PricingCategory, PricingHistory } from 'bandon-shared';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ImgUploadComponent } from '../../../components/general/img-upload/img-upload.component';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-edit-collection',
    templateUrl: './edit-collection.component.html',
    styleUrls: ['./edit-collection.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, NgIf, ImgUploadComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatDatepickerModule, MatExpansionModule, NgFor, MatSelectModule, MatOptionModule, MatButtonModule, MatIconModule, MatListModule, MatCheckboxModule, CdkDropList, CdkDrag, RouterLink, MatAutocompleteModule, AsyncPipe, DatePipe]
})
export class EditCollectionComponent implements OnInit, OnDestroy {
  public collection: Collection;
  public tunes: SimpleTuneModel[] = [];
  public tuneControl = new FormControl<string | SimpleTuneModel>('');
  public filteredTunes!: Observable<SimpleTuneModel[]>;
  public selectedTune?: SimpleTuneModel;
  public enableAdd = false;

  public languages: Language[] = [];
  public minDate: Date;

  public pricinCategories: PricingCategory[] = [];

  public partners: Partner[] = [];

  public groups: UserGroup[] = [];

  private unsubscribe$ = new Subject<void>();
  private collectionID: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ){
    this.collection = { id: -1, designation: '', description: '', covertext: '' }
    this.minDate = new Date();
  }

  get collectionImgPath() {
    if(this.collection.picture) {
      return environment.apiURL+"/photoImg/"+this.collection.picture.id;
    }
    return '';
  }

  get hasPricingHistory(): boolean {
    if(this.collection && this.collection.newPrice) {
      return true;
    } else if(this.collection && this.collection.pricinghistory) {
      return this.collection.pricinghistory.length>0;
    }
    return false;
  }

  get newPrice(): PricingHistory | undefined {
    if(this.collection && this.collection.newPrice) {
      return this.collection.newPrice;
    }
    return undefined;
  }

  get pricinghistory(): PricingHistory[] {
    if(this.collection && this.collection.pricinghistory) {
      return this.collection.pricinghistory;
    }
    return [];
  }

  get isReleased(): boolean {
    if(this.collection && this.collection.publishdate) {
      const today = new Date();
      const publishDate = new Date(this.collection.publishdate);
      return publishDate<today;
    }
    return false;
  }

  get isSellable(): boolean {
    if(this.collection && this.collection.pricinghistory && this.collection.pricinghistory.length>0) {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    this.collectionID = this.activatedRoute.snapshot.paramMap.get('collectionid');
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    if(this.collectionID>0) {
      const loadingDialog = this.dialog.open(LoadingTemplateComponent)
      console.log('load collection: ', this.collectionID);

      //Load Collection
      this.httpClient.get<Collection>(environment.apiURL+'/collections/'+this.collectionID, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.collection = data;
          this.collection.publishdate

          //Load Musician Image
  //        this.tuneImgFile = this.getTuneIcon(this.tune);
          console.log('Collection loaded ', this.collection);
          loadingDialog.close();
        });

    } else {
      this.collection = { id: -1, designation: '', description: '', covertext: '' }
    }

    //Get all Tunes
    this.httpClient.get<SimpleTuneModel[]>(environment.apiURL+"/tunes", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tunes) => {
          this.tunes.length = 0;
          this.tunes.push(...tunes);
        }
      );

    this.filteredTunes = this.tuneControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value: value?.title;
          return name ? this._filter(name as string) : this.tunes.slice();
        })
      )

    this.tuneControl.valueChanges.subscribe(value => {
      if (typeof(value)==='object') {
        const tune = value as SimpleTuneModel;

        this.selectedTune = tune;
        this.enableAdd = true;
//        this.musicianChanged.emit(value as Musician)
      } else {
        this.enableAdd = false;
      }
    })

    //Get all Languages
    this.httpClient.get<Language[]>(environment.apiURL+"/languages", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (language) => {
        this.languages.length = 0;
        this.languages.push(...language);
      }
    );

    //Load all Pricing Categories
    this.httpClient.get<PricingCategory[]>(environment.apiURL+"/pricing/categories", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (categories) => {
        this.pricinCategories.length = 0;
        this.pricinCategories = categories;
      }
    );

    //Load all partners
    this.httpClient.get<Partner[]>(environment.apiURL+"/partners", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (partners) => {
        console.log(partners)
        this.partners.length = 0;
        this.partners.push(...partners);
      }
    );

    //Load your groups
    this.httpClient.get<UserGroup[]>(environment.apiURL+'/usergroups', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      this.groups = data;
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  drop(event: any) {
    if(this.collection.tunes) {
      moveItemInArray(this.collection.tunes, event.previousIndex, event.currentIndex)
      console.log(this.collection.tunes)
    }
  }

  updateCollectionImg(image: Blob) {
    this.collection.newImage = image;
  }

  updateCollectionImgExt(fileExt: string) {
    this.collection.newImageExt = fileExt;//.substring(fileExt.lastIndexOf('.'));
  }

  updateCollectionImgId(id: number) {
//    console.log(id);
    this.collection.imgid = id;
    this.collection.newImage = undefined;
  }

  addTune() {
    if (this.selectedTune) {
      if(!this.collection.tunes) {
        this.collection.tunes = []
      }

      this.collection.tunes.push(this.selectedTune)
    }
  }

  newTune() {

  }

  removeTune(tune: SimpleTuneModel) {
    if(this.collection.tunes) {
      const index = this.collection.tunes.indexOf(tune);
      this.collection.tunes.splice(index, 1)
    }
  }

  styleDisplayFn(tune: SimpleTuneModel): string {
    let out = '';
    if (tune) {
      out = tune.title;
    }
    return out;
  }

  getTuneDesc(tune: SimpleTuneModel): string {
    let out = '';
    if (tune) {
      out = tune.title;
      if (tune.recordingdate) {
        out += ', Recorded:'+this.datePipe.transform(tune.recordingdate, 'dd.MM.yyyy')
      }

    }
    return out;
  }

  addTranslation() {
    if (!this.collection.translations) {
      this.collection.translations = []
    }

    this.collection.translations.push({languageid: 'en', designation: '', description: '', covertext: ''})
  }

  deleteTranslation(translation: CollectionTitles) {
    if (this.collection.translations) {
      const index = this.collection.translations.indexOf(translation);
      this.collection.translations.splice(index, 1);
    }
  }

  getLanguageDesignation(languageid: string): string {
    const language = this.languages.find(e => e.id==languageid);
    if (language) {
      return language.designation
    }
    return '';
  }

  openPriceChange() {
    const dialogRef = this.dialog.open(PricingCatChangePopupComponent, {
      data: { collectionid: this.collection.id, pricingType: 1} });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.collection.newPrice = result;
      }
    });
  }

  getCatTitle(catID: number) {
    const category = this.pricinCategories.find(c => c.id===catID);
    return category?.title;
  }

  saveCollection() {
    this.snackBar.open('Speichert Daten')
    if(this.collection.newImage &&
      ((this.collection.imgid && this.collection.imgid<=0) || !this.collection.imgid))
    {
      //Upload Picture and obtain ID
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      const formData = new FormData();
      let photo: Photo = {
        id: -1,
        path: 'Covers/'+this.collection.newImageExt,
        date: new Date(),
        authorid: -1
      }

      let filename = transliterate(this.collection.newImageExt!)
      console.log(filename)
      formData.append('img', this.collection.newImage, filename);
      this.collection.newImage = undefined;

      formData.append('photo', JSON.stringify(photo));
      console.log(photo);

      this.httpClient.put<Photo>(environment.apiURL+`/photos`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.collection.imgid = resp.id;
          this.sendCollectionData();
        },
        error: error => {
          console.log(error);
          this.snackBar.dismiss();
        }
      });
    } else {
      this.sendCollectionData();
    }
  }

  adjustDate(date: Date) {
    const timeZoneOffset = date.getTimezoneOffset();
    return new Date(date.getTime() - timeZoneOffset * 60000);
  }

  isProductionPartner(partner: Partner) {
    return partner.skus.find(sku => sku.collectionid===this.collection.id);
  }

  setProductionPartner(partner: Partner, checked: boolean) {
    if(checked) {
      const exists = partner.skus.find(sku => sku.collectionid===this.collection.id);
      if(!exists) {
        partner.skus.push({id: -1, partnerid: partner.id, collectionid: this.collection.id})
      }
      partner.skus.push()
    } else {
      let entry = partner.skus.find(sku => sku.collectionid===this.collection.id)
      if(entry) {
        partner.skus.splice(partner.skus.indexOf(entry), 1)
      }
    }
    this.savePartner(partner)
  }

  savePartner(partner: Partner) {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    formData.append('partner', JSON.stringify(partner));
    if(partner.id>0) {
      this.httpClient.post<Partner>(environment.apiURL+`/partners/${partner.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        console.log(resp);
      });
    }
  }

  private sendCollectionData(): void {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();

    if(this.collection.publishdate && this.collection.publishdate instanceof Date) {
      this.collection.publishdate = this.adjustDate(this.collection.publishdate);
    }

    formData.append('collection', JSON.stringify(this.collection));
    console.log(JSON.stringify(this.collection))
    if(this.collectionID>0) {
      this.httpClient.post<Collection>(environment.apiURL+`/collections/${this.collection.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.collection = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/collections');
      });
    } else {
      this.httpClient.put<Collection>(environment.apiURL+`/collections`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.collection = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/collections');
      });
    }
  }

  private _filter(value: string): SimpleTuneModel[] {
    const filterValue = value.toLowerCase();

    return this.tunes.filter(option => (option.title).toLowerCase().includes(filterValue))
  }

}
