import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService, SheetType } from 'bandon-shared';

@Injectable({
  providedIn: 'root'
})
export class SheetTypesService {

  sheetTypesSubject = new BehaviorSubject<SheetType[]>([]);
  sheetTypes$ = this.sheetTypesSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) { }

  public getSheetTypes(forceRefresh = false) {
    if(this.sheetTypesSubject.getValue().length===0 || forceRefresh) {
    //Get all Tunes
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<SheetType[]>(environment.apiURL+"/sheettypes", {headers})
      .subscribe(
        (tunes) => {
          this.sheetTypesSubject.next(tunes);
//          console.log('got musicians: ', tunes)
//          this.musicians.length = 0;
//          this.musicians.push(...tunes);
        }
      );
    } else {
      this.sheetTypesSubject.next(this.sheetTypesSubject.getValue())
//      console.log('didnt get Musicians')
    }
  }}
