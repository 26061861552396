<h1 mat-dialog-title>Neuen Preis festlegen</h1>
<div class="content">
  <mat-form-field appearance="fill">
    <mat-label>Änderungs-Datum</mat-label>
    <input matInput [matDatepicker]="recpicker" [(ngModel)]="pricingHistory.start">
    <mat-hint>MM.DD.YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="recpicker"></mat-datepicker-toggle>
    <mat-datepicker #recpicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Preiskategorie</mat-label>
    <mat-select [(value)]="pricingHistory.pricingcategoryid">
      <mat-option *ngFor="let category of categories" [value]="category.id">{{ category.title }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button [mat-dialog-close]="pricingHistory" color="primary">Speichern</button>
  </div>
</div>
