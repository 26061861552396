<app-main-menu title="Rollen"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Alle Rollen</h1>

  <mat-list>
    <mat-list-item *ngFor="let role of roles" class="role-list-item">
      <span matListItemTitle>{{ role.designation }}
        <button mat-raised-button color="accent" class="mat-list-item-content-right" routerLink="/roles/{{ role.id }}">
          Edit
        </button>
      </span>
    </mat-list-item>
  </mat-list>

  <button mat-button routerLink="/roles/-1">
    <mat-icon>add_box</mat-icon>
    Rolle hinzufügen
  </button>
</div>
