import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-tempo-delete-alert',
    templateUrl: './tempo-delete-alert.component.html',
    styleUrls: ['./tempo-delete-alert.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButtonModule, MatDialogClose]
})
export class TempoDeleteAlertComponent {

  constructor(
    public dialogRef: MatDialogRef<TempoDeleteAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, message: string}
  ) {}

  cancel(): void {
    this.dialogRef.close()
  }
}
