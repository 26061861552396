<app-main-menu title="App Versionen"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Alle Versionen</h1>

  <mat-form-field style="width:100%">
    <mat-label>Suchen</mat-label>
    <input matInput placeholder="Suche" [(ngModel)]="searchTerm" (input)="searchVersion($event)">
  </mat-form-field>

  <mat-list>
    <mat-list-item *ngFor="let version of paginatedVersions" class="version-list-item">
      <span matListItemTitle>{{ version.version }}
        <button mat-raised-button color="accent" class="mat-list-item-content-right" routerLink="/versions/{{ version.id }}">
          Edit
        </button>
      </span>
    </mat-list-item>
  </mat-list>

  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="versions.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>


  <button mat-button routerLink="/versions/-1">
    <mat-icon>add_box</mat-icon>
    Version hinzufügen
  </button>
</div>
