import { PhotoSelectComponent } from './../../../pages/photos/photo-select/photo-select.component';
import { CropperDialogComponent } from './cropper-dialog/cropper-dialog.component';
import { Component, Input, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-img-upload',
    templateUrl: './img-upload.component.html',
    styleUrls: ['./img-upload.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, ExtendedModule, MatButtonModule, MatIconModule]
})
export class ImgUploadComponent implements OnChanges {

  @Input() hasCrop: boolean = true;
  @Input() title!: string;
  @Input() imgPath!: string;
  @Input() forcedAspectRatio: string = 'free'
  @Input() showMediaSelect: boolean = true;
  @Input() showUpload: boolean = true;
  @Output() imgIdChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() imgChanged: EventEmitter<Blob> = new EventEmitter<Blob>();
  @Output() fileExt: EventEmitter<string> = new EventEmitter<string>();
  @Input() hasBackground: boolean = false;
  @Input() disabled: boolean = false;

  public imgFile: any;
  public filename: any;
  imageChangedEvent: any = '';
  cropperHidden = true;

  constructor(
    private dialog: MatDialog
  ) {}

  get showCropperHideButton():boolean {
    if(this.imageChangedEvent && !this.cropperHidden) {
      return true;
    }
    return false;
  }

  get showCropperShowButton(): boolean {
    if(this.imageChangedEvent && this.imgFile && this.cropperHidden) {
      return true;
    }
    return false;
  }

  get showCropperCancelButton(): boolean {
    if(this.imageChangedEvent && this.imgFile && this.cropperHidden) {
      return true;
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    const imgFile = this.imgPath.substring(this.imgPath.lastIndexOf('/')+1);
    if(imgFile && imgFile!='null' && imgFile!='0') {
      this.imgFile = this.getImage(this.imgPath);
    }
  }

  getImage(path: string): string {
    return path;
  }

  async onImageChange(e: any) {
    if(this.hasCrop) {
      const dialogRef = this.dialog.open(CropperDialogComponent, {
        data: { imageChangedEvent: e, forcedAspectRatio: this.forcedAspectRatio }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.imgFile = result.imgFile;
          this.imgChanged.emit(result.file);
          }
      });

      this.imageChangedEvent = e;
      this.cropperHidden = false;
    }


    if(e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      this.fileExt.emit(file.name);
      this.filename = file.name;

      if(!this.hasCrop) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        this.imgChanged.emit(file);
        reader.onload = () => {
          this.imgFile = reader.result as string;
        };
      }
    }
  }

  hideCropper() {
    this.cropperHidden = true;
  }

  showCropper() {
    this.cropperHidden = false;
  }

  openMediaSelect() {
    const dialogRef = this.dialog.open(PhotoSelectComponent);

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.imgFile = environment.apiURL+'/photoImg/'+result.id;
        this.imgIdChanged.emit(result.id);
      }
    });

  }
}
