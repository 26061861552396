<app-main-menu title="Sortierung"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Sortierung</h1>
  <p>Hinweise:</p>
  <ul>
    <li>Achtung: diese Sortierung hat Auswirkung auf alle Stücke. Änderungen mit grosser Vorsicht vornehmen.</li>
    <li>Die Instrumente im Mixer werden nach folgenden Prioritäten sortiert:
      <ol>
        <li>Instrumentengruppe</li>
        <li>Instrument</li>
        <li>Stimme (Einzelstimmen sind immer ganz oben)</li>
      </ol>
    </li>
  </ul>
  <div class="container"
      fxLayout fxLayoutGap="10px">
    <div flex="33">
      <p><strong>Gruppen</strong></p>
      <div cdkDropList class="sorting-list" (cdkDropListDropped)="dropGroup($event)">
        <div class="sort-item" *ngFor="let group of instrGroups" [class.selected]="group==selectedGroup"
            cdkDrag (click)="selectGroup(group)">
          <b>{{ group.designation }}</b><!--{{ group.grouporder }}-->
        </div>
      </div>
    </div>
    <div flex="33">
      <p><strong>Instrumente</strong></p>
      <div cdkDropList class="sorting-list" (cdkDropListDropped)="dropInstrument($event)">
        <div class="sort-item" *ngFor="let instrument of instruments" cdkDrag>
          <b>{{ instrument.designation }}</b><!--{{ instrument.instrumentorder }}-->
        </div>
      </div>
    </div>
    <div flex="33">
      <p *ngIf="selectedGroup"><strong>Stimmen für {{ selectedGroup.designation }}</strong></p>
      <div cdkDropList class="sorting-list" (cdkDropListDropped)="dropVoice($event)" *ngIf="selectedVoices.length>0" >
        <div class="sort-item" *ngFor="let voice of selectedVoices" cdkDrag>
          <b>{{ voice.designation }}</b><!--{{ voice.voiceorder }}-->
        </div>
      </div>
      <div *ngIf="selectedVoices.length==0">
        Keine Stimmen verfügbar.
      </div>
    </div>
  </div>
</div>
