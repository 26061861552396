import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AuthenticationService, Musician } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { Subject, takeUntil, Observable, startWith } from 'rxjs';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-composer-auto-input',
    templateUrl: './composer-auto-input.component.html',
    styleUrls: ['./composer-auto-input.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, FormsModule, NgIf, MatFormFieldModule, MatInputModule, MatAutocompleteModule, NgFor, MatOptionModule, MatButtonModule, AsyncPipe]
})
export class ComposerAutoInputComponent implements OnInit, OnDestroy, OnChanges {

  @Input() composer: Musician;
  @Input() disabled: boolean = false;
  @Output() composerChanged: EventEmitter<Musician> = new EventEmitter<Musician>();
  @Output() deleteComposer: EventEmitter<Musician> = new EventEmitter<Musician>();

  public composerControl = new FormControl<string | Musician>('');
  public filteredComposers!: Observable<Musician[]>;
  public composers: Musician[] = [];
  public errorMsg: string = '';

  private unsubscribe$ = new Subject<void>();

  get name(): string {
    if(this.composer && this.composer.firstname) {
      return this.composer.firstname
    }
    return '';
  }

  get surname(): string {
    if(this.composer && this.composer.surname) {
      return this.composer.surname
    }
    return '';
  }

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) {
    this.composer = { id: -1, firstname: '', surname: '', year_of_birth: 0, year_of_death: 0}
  }

  ngOnInit(): void {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    //Load all composers
    this.httpClient.get<Musician[]>(environment.apiURL+'/musicians', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.composers = data;
    });

    this.filteredComposers = this.composerControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value: value?.firstname+' '+value?.surname;
        return name ? this._filter(name as string) : this.composers.slice();
      })
    )

    this.composerControl.valueChanges.subscribe(value => {
        if (typeof(value)==='object') {
          this.composerChanged.emit(value as Musician)
          this.errorMsg = ""
        } else {
          this.errorMsg = "Komponist*in unbekannt -> wird nicht übernommen";
        }
    })

  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.composerControl.setValue(this.composer);
  }


  composerDisplayFn(composer: Musician): string {
    if (composer && composer.firstname && composer.surname)
      return composer.firstname+' '+composer.surname
    else if (composer && composer.firstname)
      return composer.firstname
    return ''

//    return composer && composer.name && composer.surname ? composer.name+' '+composer.surname : '';
  }

  removeComposer(composer: Musician) {
    this.deleteComposer.emit(composer);
  }

  private _filter(value: string): Musician[] {
    const filterValue = value.toLowerCase();

    return this.composers.filter(option => (option.firstname+' '+option.surname).toLowerCase().includes(filterValue))
  }

}

