<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!-- Image Column -->
  <ng-container matColumnDef="bild">
    <th mat-header-cell *matHeaderCellDef> Bild </th>
    <td mat-cell *matCellDef="let musician">
      <img matListItemIcon [src]="getMusicianImg(musician)">
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let musician">
      <app-musician-auto-input [musicianid]="musician.tunemusician.id" (musicianChanged)="changeMusician(musician, $event)" [disabled]="disabled"></app-musician-auto-input>
    </td>
  </ng-container>

  <!-- Instrument Column -->
  <ng-container matColumnDef="instrument">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let musician">
      <mat-select [(value)]="musician.tminstrument.id" [disabled]="disabled">
        <mat-option *ngFor="let instrument of instruments"  [value]="instrument.id">
          <img [src]="getInstrumentIcon(instrument)" class="instrument-icon">
          {{ instrument.designation }}
        </mat-option>
      </mat-select>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef> Instrument </th>
    <td mat-cell *matCellDef="let musician">
      <button mat-raised-button color="warn" type="button" (click)="deleteMusician(musician)" *ngIf="!disabled" >Löschen</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<button mat-button (click)="addMusician()" *ngIf="!disabled" >
  <mat-icon>add_box</mat-icon>
  Musiker*in hinzufügen
</button>
