<mat-card class="container">
  <mat-card-title>
      BandON-Admin Login
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="formData" (ngSubmit)="onSubmit()" class="flex-form">
        <mat-form-field appearance="fill">
            <mat-label>E-Mail</mat-label>
            <input matInput formControlName="email" type="email" autocomplete="email">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" type="password" autocomplete="current-password">
      </mat-form-field>
      <input formControlName="mailadd" style="display:none">
      <button mat-raised-button type="submit" [disabled]="!formData.valid" color="primary">Login</button>
    </form>
    <div class="alert-container" [hidden]="!showAlert">
      Sie haben keine Berechtigung für dieses Tool
    </div>
  </mat-card-content>
</mat-card>
