<app-main-menu title="Komponist*innen"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Alle Komponist*innen</h1>

  <mat-form-field style="width:100%">
    <mat-label>Suchen</mat-label>
    <input matInput placeholder="Suche" [(ngModel)]="searchTerm" (input)="searchComposer($event)">
  </mat-form-field>

  <mat-list>
    <mat-list-item *ngFor="let composer of paginatedComposers" class="musician-list-item">
      <span matListItemTitle>{{ composer.name }} {{ composer.surname }}
        <p class="mat-list-item-content-right">
          <button mat-raised-button color="accent" routerLink="/composers/{{ composer.id }}">
            Edit
          </button>
          <!-- <button mat-raised-button color="warn">
            Löschen
          </button> -->
        </p>
      </span>
      <span matListItemLine>{{ composer.born }} - {{ composer.died }}</span>
    </mat-list-item>
  </mat-list>

  <mat-paginator
      (page)="handlePageEvent($event)"
      [length]="composers.length"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>

  <button mat-button routerLink="/composers/-1">
    <mat-icon>add_box</mat-icon>
    Komponist*in hinzufügen
  </button>
</div>
