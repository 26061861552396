import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

@Directive({
    selector: '[appTimeInput]',
    providers: [
        { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: TimeInputDirective },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeInputDirective),
            multi: true
        }
    ],
    standalone: true
})
export class TimeInputDirective {

  @Input() showZero = true

  constructor(private elementRef: ElementRef<HTMLInputElement>) {
//    console.log('Directive initialized');
  }

  private _value: string | null = null;

  get value(): string | null {
    return this._value;
  }

  @Input('value')
  set value(value: string | null) {
    if(value) {
      this._value = value;
      this.formatValue(value);
    }
  }

  private formatValue(value: string | null) {
    if (value && value !== null && value.length>0) {
      const millis = this.readValue(value);

      this.elementRef.nativeElement.value = this.numberWithCommas(millis);
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private readValue(value: string): number {
    if(value.includes(':')) {
      const [minutes, seconds] = value.split(':')
      return Math.round(Number(minutes)*60000+Number(seconds)*1000);
    } else if(value.includes('.')) {
      return Math.round(Number(value)*1000);
    }
    return Math.round(Number(value));
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: any) {
    // here we cut any non numberical symbols
    if(value) {
      this._value = this.readValue(value.toString()).toString();
      if(!this.showZero && this._value=="0") {
        this._value = null;
      }
      this._onChange(this._value); // here to notify Angular Validators
    } else {
      this._value = null;
      this._onChange(this._value); // here to notify Angular Validators
    }
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus() {
//    this.unFormatValue()
  }

  _onChange(value: any): void {
  }

  writeValue(value: any) {
    if(value || (value==0 && this.showZero)) {
      this._value = value.toString();
      this.formatValue(this._value); // format Value
    } else {
      this._value = null;
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {
  }

  numberWithCommas(x: any) {
    let millis = Number(x);
    if (millis || (millis==0 && this.showZero)) {
      let minutes = 0;
      if (millis>=60000) {
        minutes = Math.floor(millis/60000);
      }
      let seconds = Math.floor((millis-minutes*60000)/1000.0);
      let milliseconds = millis-minutes*60000-seconds*1000;
      return minutes.toFixed(0)+':'+seconds.toString().padStart(2, '0')+'.'+milliseconds.toString().padStart(3, '0');
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

