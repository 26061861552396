import { Speed, Tune } from 'bandon-shared';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { TimeInputDirective } from '../../../directives/time-input.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

export interface AudioFileData {
  file: Blob
  filename: string
}

@Component({
    selector: 'app-tempo-card',
    templateUrl: './tempo-card.component.html',
    styleUrls: ['./tempo-card.component.scss'],
    standalone: true,
    imports: [MatCheckboxModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, TimeInputDirective, NgIf, MatButtonModule, MatIconModule]
})
export class TempoCardComponent implements OnInit {

  @Input() tune: Tune | undefined;
  @Input() tempo: Speed | undefined;
  @Input() disabled: boolean = false;

  @Output() delete = new EventEmitter();
  @Output() fileChanged: EventEmitter<AudioFileData> = new EventEmitter<AudioFileData>();


  main = false;
  filename: string = '';

  get mainTempo(): boolean {
    return this.tempo?.main==1;
  }

  set mainTempo(main: boolean) {
    if(main && this.tempo) {
      this.tempo.main = 1;
    } else if(this.tempo) {
      this.tempo.main = 0;
    }
  }

  get speed(): string {
    if(this.tempo?.speed)
      return this.tempo?.speed.toString();
    return "";
  }

  set countoffbeats(beats: string) {
    if(this.isNumber(beats) && this.tempo)
      this.tempo.countoffbeats = Number(beats);
  }

  get countoffbeats(): string {
    if(this.tempo?.speed)
      return this.tempo?.countoffbeats.toString();
    return "";
  }

  set speed(speed: string) {
    if(this.isNumber(speed) && this.tempo)
      this.tempo.speed = Number(speed);
  }
  get start(): string {
    if(this.tempo?.start) {
      return this.tempo.start.toString();
    }
    return "";
  }

  set start(start: string) {
    if(this.isNumber(start) && this.tempo) {
      this.tempo.start = Number(start);
    }
  }

  ngOnInit(): void {
    if (this.tempo) {
      this.filename = this.tempo.countofffile;
    }
  }

  isNumber(value: string | number): boolean {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }

  deleteTempo() {
    if(this.tempo) {
      this.delete.emit();
    }
  }

  onFileChange(e: any) {
    const reader = new FileReader();

    if(e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      this.filename = file.name;
      if(this.tempo) {
        this.tempo.countofffile = this.filename;
      }

      this.fileChanged.emit({ file, filename: file.name })
/*      reader.onload = () => {
        this.tuneImgFile = reader.result as string;
        this.uploadForm.patchValue({
          imgSrc: reader.result
        });*/

//      };
    }
  }
}
