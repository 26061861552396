import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, Composer } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-composers-list',
    templateUrl: './composers-list.component.html',
    styleUrls: ['./composers-list.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatListModule, NgFor, MatButtonModule, RouterLink, MatPaginatorModule, MatIconModule]
})
export class ComposersListComponent {
  public searchTerm = '';
  public composers: Composer[] = [];
  _searchedComposers: Composer[] = [];

  private unsubscribe$ = new Subject<void>();

  private pageSize = 10;
  private pageIndex = 0;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) {
  }

  get paginatedComposers(): Composer[] {
    return this.searchedComposers.slice(this.pageIndex*this.pageSize, (this.pageIndex+1)*this.pageSize);
  }

  get searchedComposers(): Composer[] {
    if(this.searchTerm && this.searchTerm.trim() !== '') {
      return this._searchedComposers;
    }
    return this.composers;
  }

  ngOnInit(): void {
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    //Get all Tunes
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Composer[]>(environment.apiURL+"/composers", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tunes) => {
          this.composers.length = 0;
          this.composers.push(...tunes);
          loadingDialog.close();
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handlePageEvent(event: PageEvent) {

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchComposer(event: any) {
    const text = event.target.value;
    this._searchedComposers = this.composers;
    if(text && text.trim() !== '') {
      this._searchedComposers = this._searchedComposers.filter((composer: Composer) => {
        const des = composer.name+' '+composer.surname+' '+composer.born+' '+composer.died;
        return (des.toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
    }
    this.pageIndex = 0;
  }

}
