import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { Tune, Tag, AuthenticationService } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-tags-input-field',
    templateUrl: './tags-input-field.component.html',
    styleUrls: ['./tags-input-field.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormFieldModule, MatChipsModule, NgFor, MatIconModule, MatAutocompleteModule, ReactiveFormsModule, MatOptionModule, AsyncPipe]
})
export class TagsInputFieldComponent implements OnInit, OnDestroy {
  @Input() tune!: Tune
  @Input() disabled: boolean = false;

  allTags: Tag[] = [];
  public tagControl = new FormControl<string | Tag>('');
  public filteredTags!: Observable<Tag[]>;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.updateTagsList();

    this.filteredTags = this.tagControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value: value?.designation;
        return name ? this._filter(name as string) : this.allTags.slice();
      })
    )

    this.tagControl.valueChanges.subscribe(value => {
      if (typeof(value)==='object') {
        const tag = value as Tag;

        if(!this.tune.tags) {
          this.tune.tags = []
        }

        if (tag) {
          this.tune.tags.push(tag)
        }
//        this.musicianChanged.emit(value as Musician)
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateTagsList(): void {
    //Get all Tunes
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Tag[]>(environment.apiURL+"/tags", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tunes) => {
          this.allTags.length = 0;
          this.allTags.push(...tunes);
        }
      );
  }

  removeTag(style: Tag) {
    if(this.tune.tags) {
      const index = this.tune.tags.indexOf(style);
      this.tune.tags.splice(index, 1)
    }
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim()

    if (value) {
      const existing_Tag = this.allTags.find(e => e.designation==value)
      if(!this.tune.tags) {
        this.tune.tags = []
      }

      if (existing_Tag) {
        this.tune.tags.push(existing_Tag)
      }
    }

    // Clear the input value
    event.chipInput!.clear()
  }

  tagDisplayFn(tag: Tag): string {
    return tag && tag.designation ? tag.designation : '';
  }

  private _filter(value: string): Tag[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(option => (option.designation).toLowerCase().includes(filterValue))
  }
}
