import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DatePipe, NgFor } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthenticationService, Musician, Sheet, SheetType } from 'bandon-shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { transliterate } from 'transliteration';
import { Overlay, CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { DeleteImageDialogComponent } from 'src/app/pages/photos/photo-library/delete-image-dialog/delete-image-dialog.component';
import { FolderViewItem } from '../sheet-folder-view/sheet-folder-view.component';
import { PhotoDirNode } from '../../photo-library/photo-browser/photo-browser.component';
import { PdfPreviewComponent } from 'src/app/pages/sheets/sheets-library/pdf-preview/pdf-preview.component';
import { SheetTypesService } from 'src/app/services/data/sheet-types.service';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MusicianAutoInputComponent } from '../../general/musician-auto-input/musician-auto-input.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-sheet-item-view',
    templateUrl: './sheet-item-view.component.html',
    styleUrl: './sheet-item-view.component.scss',
    standalone: true,
    imports: [MatCardModule, CdkOverlayOrigin, MatIconModule, CdkAccordionModule, MusicianAutoInputComponent, MatFormFieldModule, MatInputModule, MatDatepickerModule, ReactiveFormsModule, FormsModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, CdkConnectedOverlay]
})
export class SheetItemViewComponent implements OnInit, OnDestroy {

  @Input() item: FolderViewItem | undefined;
  @Input() mode: 'select' | 'edit' = 'select';
  @Input() selected = false;

  @Output() onDelete: EventEmitter<PhotoDirNode> = new EventEmitter();
  @Output() onAssign: EventEmitter<Sheet> = new EventEmitter();

  @ViewChild('overlayTemplate') overlayTemplate: ElementRef | undefined;

  sheetTypes: SheetType[] = [];

  ctxMenuOpened = false;

  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  expandedIndex = 0;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private overlay: Overlay,
    private sheetTypeService: SheetTypesService
  ) {}

  get name(): string {
    if(this.item) {
      return this.item.name;
    }
    return '';
  }

  get id(): number {
    if(this.item && 'id' in this.item.item) {
      return this.item.item.id;
    }
    return 0;
  }

  get sheetPath(): string {
    if(this.item && 'id' in this.item.item) {
      return environment.apiURL+"/sheetFile/"+this.item.item.id;
    }
    return ''
  }

  get sheet(): Sheet | undefined {
    if(this.item && 'id' in this.item.item) {
      return this.item.item as Sheet;
    }
    return undefined;
  }

  get sheetTypeID(): number | undefined {
    if(this.sheet) {
      return this.sheet.type?.id
    }
    return undefined;
  }

  set sheetTypeID(id: number) {
    if(this.sheet && this.sheet.type) {
      this.sheet.type.id = id;
    }
  }

  get date(): Date | undefined {
    if(this.sheet) {
      return this.sheet.date
    }
    return undefined;
  }

  set date(date: Date) {
    if(this.sheet) {
      this.sheet.date = date;
    }
  }

  get type(): string {
    if(this.item) {
      return this.item.type;
    }
    return '';
  }

  get authorid() {
    if(this.sheet && this.sheet.author) {
      return this.sheet.author.id
    }
    return -1;
  }

  ngOnInit(): void {
    this.sheetTypeService.sheetTypes$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (types) => {
        this.sheetTypes.length = 0;
        this.sheetTypes.push(...types);
      }
    );

    this.sheetTypeService.getSheetTypes();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('document:click', ['$event'])
  closeOverlays(event: MouseEvent): void {
    // Close all overlays when clicking outside
    if (!this.isClickInsideOverlay(event)) {
      this.ctxMenuOpened = false;
    }
  }

  private isClickInsideOverlay(event: MouseEvent): boolean {
    if (this.overlayTemplate) {
      const overlayElement = this.overlayTemplate.nativeElement as HTMLElement;
      return overlayElement.contains(event.target as Node);
    }
    return false;
  }

  changeAuthor(sheet: Sheet | undefined, changedAuthor: Musician) {
    if(sheet) {
      if(!sheet.author) {
        sheet.author = { id: changedAuthor.id, firstname: changedAuthor.firstname,
          surname: changedAuthor.surname, image: changedAuthor.image,
          instrument: changedAuthor.instrument }
      } else {
        sheet.author.id = changedAuthor.id;
        sheet.author.firstname = changedAuthor.firstname;
        sheet.author.surname = changedAuthor.surname;
        sheet.author.image = changedAuthor.image;
        sheet.author.instrument = changedAuthor.instrument;
      }
      this.commitUpdate(sheet);
    }
  }

  updateType(sheet: Sheet | undefined, newType: number) {
    if(sheet && sheet.type && newType!=sheet.type?.id) {
      sheet.type.id = newType;
      this.commitUpdate(sheet);
    }
  }

  dateChanged(sheet: Sheet | undefined, event: any) {
    if(sheet) {
      this.commitUpdate(sheet);
    }
  }

  updateSheet(sheet: Sheet, file: Blob) {
    sheet.newFile = file;
    this.commitUpdate(sheet);
    //TODO: save img
//    this.tune.newImage = image;
  }


  commitUpdate(sheet: Sheet) {
    this.snackBar.open('Speichert Daten')
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    if(sheet.newFile) {
      let filename = transliterate(sheet.imgSaveFilename!)
      formData.append('file', sheet.newFile, filename);
      sheet.newFile = undefined;

      let newPath = sheet.path.slice(0, sheet.path.lastIndexOf('/')+1).concat(filename);
      sheet.path = newPath;
    }

//    console.log(sheet);
    formData.append('sheet', JSON.stringify(sheet));

    if(sheet.id>0) {
      this.httpClient.post<Sheet>(environment.apiURL+`/sheets/${sheet.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.snackBar.dismiss();
//          this.refreshSheets();
          },
        error: error => {
          console.log(error);
          this.snackBar.dismiss();
//          this.refreshSheets();
          }
      });
    } else {
      this.httpClient.put<Sheet>(environment.apiURL+`/sheets`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.snackBar.dismiss();
//          this.refreshSheets();
          },
        error: error => {
          console.log(error);
          this.snackBar.dismiss();
//          this.refreshSheets();
          }
      });
    }
  }

  removeSheet(sheet: Sheet | undefined) {
    if(sheet) {
      const dialogRef = this.dialog.open(DeleteImageDialogComponent);

      dialogRef.afterClosed().subscribe(result => {
        if(result==='confirmed') {
          this.snackBar.open('Sheet wird gelöscht')
          const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
          const formData = new FormData();
          this.httpClient.delete(environment.apiURL+`/sheets/${sheet.id}`, {headers})
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: resp => {
              console.log(resp);
              this.snackBar.dismiss();
              this.onDelete.emit(this.item?.item as PhotoDirNode);
            },
            error: error => {
              console.log(error);
              this.snackBar.dismiss();
              this.snackBar.open("Das Sheet konnte nicht gelöscht werden. Vermutlich wird es nocht verwendet.", "schliessen");
            }
          });
        }
      });
    }
  }

  assignSheet(sheet: Sheet | undefined) {
    if(sheet) {
      this.onAssign.emit(sheet);
    }
  }

  handleContextMenu(event: MouseEvent) {
    event.preventDefault();

//    this.ctxMenuOpened = !this.ctxMenuOpened;
  }

  menuAction() {
    this.ctxMenuOpened = false;
  }

  previewFile(sheet: Sheet | undefined) {
    if(sheet) {
      const dialogRef = this.dialog.open(PdfPreviewComponent, {
        data: { sheet: sheet },
        height: '80%',
        width: '80%'
      });
    }
  }

}
