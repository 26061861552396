import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from 'src/app/components/navigation/main-menu/main-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppVersion, AuthenticationService } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { environment } from 'src/environments/environment';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-app-version-list',
  standalone: true,
  imports: [
    CommonModule,
    MainMenuComponent,
    FormsModule,
    MatInputModule,
    MatListModule,
    RouterLink,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule
  ],
  templateUrl: './app-version-list.component.html',
  styleUrl: './app-version-list.component.scss'
})
export class AppVersionListComponent {
  authService = inject(AuthenticationService)
  dialog = inject(MatDialog)
  httpClient = inject(HttpClient)

  public searchTerm = '';
  public versions: AppVersion[] = [];
  _searchedVersion: AppVersion[] = [];

  private unsubscribe$ = new Subject<void>();

  private pageSize = 10;
  private pageIndex = 0;

  get paginatedVersions(): AppVersion[] {
    return this.searchedVersions.slice(this.pageIndex*this.pageSize, (this.pageIndex+1)*this.pageSize);
  }

  get searchedVersions(): AppVersion[] {
    if(this.searchTerm && this.searchTerm.trim() !== '') {
      return this._searchedVersion;
    }
    return this.versions;
  }

  ngOnInit(): void {
    //Get all Tunes
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<AppVersion[]>(environment.apiURL+"/app-version", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (versions) => {
          this.versions.length = 0;
          this.versions.push(...versions);
          loadingDialog.close();
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchVersion(event: any) {
    const text = event.target.value;
    this._searchedVersion = this.versions;
    if(text && text.trim() !== '') {
      this._searchedVersion = this._searchedVersion.filter((version: AppVersion) => {
        const des = version.version;
        return (des.toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
    }
    this.pageIndex = 0;
  }
}
