import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { AuthenticationService, LoginParams } from 'bandon-shared';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class LoginComponent {
  formData: FormGroup;
  private unsubscribe$ = new Subject<void>();
  showAlert = false;

  constructor(
    private authService: AuthenticationService,
    private router: Router)
  {
      this.formData = new FormGroup({
        email: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
        mailadd: new FormControl('', this.forbiddenFieldValidator())
      });
  }

  onSubmit() {
    let loginParams: LoginParams = {
      email: this.formData.controls['email'].value,
      password: this.formData.controls['password'].value
    }
    this.authService.login(loginParams)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: response => {
//        loading.dismiss();
        this.router.navigateByUrl('tunes', { replaceUrl: true });
        this.showAlert = false;
      },
      error: error => {
        this.showAlert = true;
//        loading.dismiss();
//        this.showAlert('Login failed', 'Please try again!');
      }
    });
  }

  forbiddenFieldValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? {forbiddenField: {value: control.value}} : null;
    };
  }

}


