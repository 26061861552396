<app-main-menu title="{{ session.designation }}" backref="/sessions"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>{{ session.designation }}</h1>

  <mat-form-field appearance="fill" style="width: 50%;">
    <mat-label>Bezeichnung</mat-label>
    <input matInput placeholder="Bezeichnung" [(ngModel)]="session.designation">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Session-Datum</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="session.date">
    <mat-hint>MM.DD.YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field><br>

  <h2>Produktion</h2>
  <p *ngFor="let producer of session.producers">
    <app-musician-auto-input [musicianid]="producer.id" (musicianChanged)="updateProducer(producer, $event)"></app-musician-auto-input>
    <button mat-raised-button color="warn" type="button" (click)="deleteProducer(producer)">Löschen</button>
  </p>
<button mat-button (click)="addProducer()">
  <mat-icon>add_box</mat-icon>
  Produzent*in hinzufügen
</button>

  <h2>Stücke</h2>
  <div cdkDropList class="session-tune-list">
    <div class="tune-item" *ngFor="let tune of session.tunes">
      <p><b>{{ tune.title }}</b><br>
      <i>{{ tune.tonalities }}; {{ tune.speeds }}</i></p>
      <button mat-raised-button color="warn" (click)="removeTune(tune)">
        Entfernen
      </button>
    </div>
  </div>

  <div class="button-row">
    <button mat-button (click)="addTune()" [disabled]="!enableAdd">
      <mat-icon>arrow_upward</mat-icon>
    </button>
  </div>
  <mat-form-field appearance="fill" style="width:100%">
    <input matInput placeholder="Bezeichnung" [matAutocomplete]="auto" [formControl]="tuneControl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="styleDisplayFn">
      <mat-option *ngFor="let tune of filteredTunes | async" [value]="tune">{{ getTuneDesc(tune) }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <h2>Fotos</h2>
  <mat-grid-list cols="4">
    <mat-grid-tile *ngFor="let photo of session.photos">
      <img [src]="getPhotoPath(photo)" class="session-photo">
      <div>
        <button mat-mini-fab class="remove-button" (click)="removePhoto(photo)" type="button">
          <mat-icon>disabled_by_default</mat-icon>
        </button>
      </div>

    </mat-grid-tile>
    <mat-grid-tile (click)="addPhoto()">
      <mat-icon>photo_library</mat-icon>
    </mat-grid-tile>
  </mat-grid-list>

  <div class="button-row">
    <button mat-raised-button color="accent" (click)="saveSession()">Speichern</button>
    <button mat-raised-button routerLink="/sessions">Abbrechen</button>
    </div>

</div>
