<app-main-menu title="{{ collection.designation }}" backref="/collections"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>{{ collection.designation }}</h1>
  <p *ngIf="isReleased" ><i>Diese Kollektion ist in band-on veröffentlicht. Daher können keine Stücke hinzugefügt oder gelöscht werden.</i></p>

  <app-img-upload title="Bild"
    [imgPath]="collectionImgPath"
    forcedAspectRatio="1"
    (imgChanged)="updateCollectionImg($event)"
    (imgIdChanged)="updateCollectionImgId($event)"
    (fileExt)="updateCollectionImgExt($event)"></app-img-upload>
  <mat-form-field appearance="fill" style="width: 50%;">
    <mat-label>Bezeichnung</mat-label>
    <input matInput placeholder="Bezeichnung" [(ngModel)]="collection.designation">
  </mat-form-field>

  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Beschreibung</mat-label>
    <textarea matInput placeholder="Beschreibung" [(ngModel)]="collection.description"></textarea>
  </mat-form-field>

  <mat-form-field style="width: 50%;">
    <mat-label>Eigentümer</mat-label>
    <mat-select [(value)]="collection.ownerid">
      @for (group of groups; track $index) {
        <mat-option [value]="group.id">{{ group.designation }}</mat-option>
      }
    </mat-select>
  </mat-form-field><br>

  <mat-form-field appearance="fill" style="width: 50%;">
    <mat-label>Video</mat-label>
    <input matInput placeholder="Bezeichnung" [(ngModel)]="collection.videourl">
  </mat-form-field><br>

<!--       <h2>Cover-Text</h2>
  <p>Um einen Formatierten Text einzugeben diese Seite (<a href="https://wordtohtml.net/" target="_blank">https://wordtohtml.net/</a>)
    verwenden und den HTML-Code hier einfügen:</p>
  <mat-form-field style="width: 100%">
    <mat-label>Cover-Text</mat-label>
    <textarea matInput maxlength="2000" [(ngModel)]="collection.covertext"></textarea>
  </mat-form-field>
-->
  <mat-form-field appearance="fill">
    <mat-label>Veröffentlichung</mat-label>
    <input matInput [min]="minDate" [matDatepicker]="picker" [(ngModel)]="collection.publishdate" [disabled]="isReleased">
    <mat-hint>MM.DD.YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field><br>

  <mat-accordion>
    <mat-expansion-panel *ngFor="let translation of collection.translations" >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ getLanguageDesignation(translation.languageid) }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field appereance="fill" style="width: 50%">
        <mat-label>Sprache</mat-label>
        <mat-select [(value)]="translation.languageid">
          <mat-option *ngFor="let language of languages" [value]="language.id">
            {{ language.designation }}
          </mat-option>
        </mat-select>
      </mat-form-field><br>

      <mat-form-field appearance="fill" style="width: 50%;">
        <mat-label>Bezeichnung</mat-label>
        <input matInput placeholder="Bezeichnung" [(ngModel)]="translation.designation">
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Beschreibung</mat-label>
        <textarea matInput placeholder="Beschreibung" [(ngModel)]="translation.description"></textarea>
      </mat-form-field>

<!--           <p>Um einen Formatierten Text einzugeben diese Seite (<a href="https://wordtohtml.net/" target="_blank">https://wordtohtml.net/</a>)
        verwenden und den HTML-Code hier einfügen:</p>
      <mat-form-field style="width: 100%">
        <mat-label>Cover-Text</mat-label>
        <textarea matInput maxlength="2000" [(ngModel)]="translation.covertext"></textarea>
      </mat-form-field>
-->

      <button mat-raised-button color="warn" (click)="deleteTranslation(translation)">Löschen</button>
    </mat-expansion-panel>
  </mat-accordion>
  <button mat-button (click)="addTranslation()">
    <mat-icon>add_box</mat-icon>
    Übersetzung hinzufügen
  </button>

  <h2>Preiskategorie</h2>
  <mat-accordion *ngIf="hasPricingHistory" >
    <mat-expansion-panel expanded="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Alte Preise
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list>
        <mat-list-item *ngFor="let item of pricinghistory">
          <span matListItemTitle>{{ getCatTitle(item.pricingcategoryid) }}</span>
          <span matListItemLine>{{ item.start| date: 'd.M.y' }}</span>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
    <mat-expansion-panel expanded="true" *ngIf="newPrice" >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Neuer Preis
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list>
        <mat-list-item>
          <span matListItemTitle>{{ getCatTitle(newPrice!.pricingcategoryid) }}</span>
          <span matListItemLine>{{ newPrice!.start| date: 'd.M.y' }}</span>
        </mat-list-item>
      </mat-list>

    </mat-expansion-panel>
  </mat-accordion><br>
  <mat-checkbox [(ngModel)]="collection.isdemo">ist Demo</mat-checkbox><br>
  <p *ngIf="!hasPricingHistory">Kein Preis definiert</p>
  <button mat-raised-button color="primary" type="button" (click)="openPriceChange()" *ngIf="!collection.isdemo" >Anpassen</button>

  <h2>Produktionspartner</h2>
  <mat-accordion>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Produktionspartner
        </mat-panel-title>
      </mat-expansion-panel-header>
      <section class="example-section">
        @for (partner of partners; track $index) {
          <mat-checkbox class="example-margin"
            [checked]="isProductionPartner(partner)"
            (change)="setProductionPartner(partner, $event.checked)">
            {{ partner.designation }}
          </mat-checkbox><br>
        }
      </section>
    </mat-expansion-panel>
  </mat-accordion>

  <h2>Stücke</h2>
  <div cdkDropList class="collection-list" (cdkDropListDropped)="drop($event)">
    <div class="tune-item" *ngFor="let tune of collection.tunes" cdkDrag>
      <p><b>{{ tune.title }}</b><br>
      <i>{{ tune.tonalities }}; {{ tune.speeds }}</i></p>
      <div class="mat-list-item-content-right">
        <button mat-raised-button color="accent" routerLink="/tunes/{{ tune.id }}">
          Edit
        </button>
        <button mat-raised-button color="warn" (click)="removeTune(tune)" *ngIf="!isReleased || !isSellable" >
          Entfernen
        </button>
      </div>
    </div>
  </div>

  @if (!isReleased || !isSellable) {
    <div class="button-row">
      <button mat-button (click)="addTune()" [disabled]="!enableAdd">
        <mat-icon>arrow_upward</mat-icon>
      </button>
      <button mat-button routerLink="/tunes/-1/{{ collection.id }}">
        <mat-icon>add_box</mat-icon>
        Neues Stück
      </button>
  </div>
}
<mat-form-field appearance="fill" style="width:100%" *ngIf="!isReleased || !isSellable">
    <input matInput placeholder="Bezeichnung" [matAutocomplete]="auto" [formControl]="tuneControl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="styleDisplayFn">
      <mat-option *ngFor="let tune of filteredTunes | async" [value]="tune">{{ getTuneDesc(tune) }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="button-row">
    <button mat-raised-button color="accent" (click)="saveCollection()">Speichern</button>
    <button mat-raised-button routerLink="/collections">Abbrechen</button>
  </div>

</div>
