import { Component, OnInit } from '@angular/core';
import { SideNavToggle, SideMenuComponent } from './components/navigation/side-menu/side-menu.component';
import { BodyComponent } from './components/general/body/body.component';
import { LanguageService } from './services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, LibConfigService } from 'bandon-shared';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [SideMenuComponent, BodyComponent]
})
export class AppComponent implements OnInit {
  title = 'BandON-Admin';

  isSideNavCollapsed = false;
  screenWidth = 0;
  isAuth = false;

  constructor(
    private authService: AuthenticationService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private libConfigService: LibConfigService
  ) {
    languageService.setInitialAppLanguage();

    authService.isAuthenticated.subscribe(auth => {
      this.isAuth = auth;
      if(!auth) {
        this.isSideNavCollapsed = false;
      }
    });
  }

  ngOnInit(): void {
    this.authService.checkAuthenticated();
  }

  onToggleSidenav(data: SideNavToggle) {
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
  }
}
