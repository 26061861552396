import { Track, Tune, Instrument, InstrumentGroup, Tonality, Speed, Voice, Part, Level, Musician, Photo, Sheet, Language, TuneTexts, TuneMeter, Partner, Collection, SimpleTuneModel, UserGroup, AuthenticationService, MidiImportService, TagInputDirective } from 'bandon-shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { transliterate } from 'transliteration';
import { AudioFileData } from 'src/app/components/tuneEdit/track-file-cell/track-file-cell.component';
import { SheetSelectComponent } from '../../sheets/sheet-select/sheet-select.component';
import { MatDialog } from '@angular/material/dialog';
import { PdfPreviewComponent } from '../../sheets/sheets-library/pdf-preview/pdf-preview.component';
import { PricingCatChangePopupComponent } from '../../pricing/pricingCategories/pricing-cat-change-popup/pricing-cat-change-popup.component';
import { PricingCategory, PricingHistory } from 'bandon-shared';
import { SavingTemplateComponent } from 'src/app/components/ModalTemplates/saving-template/saving-template.component';
import { TempoDeleteAlertComponent } from 'src/app/components/ModalTemplates/tempo-delete-alert/tempo-delete-alert.component';
import { Editor, Toolbar, NgxEditorModule } from 'ngx-editor';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { BandTableComponent } from '../../../components/tuneEdit/band-table/band-table.component';
import { ArrangementPartsTableComponent } from '../../../components/tuneEdit/arrangement-parts-table/arrangement-parts-table.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TrackFileCellComponent } from '../../../components/tuneEdit/track-file-cell/track-file-cell.component';
import { MatDividerModule } from '@angular/material/divider';
import { TempoCardComponent } from '../../../components/tuneEdit/tempo-card/tempo-card.component';
import { StylesInputFieldComponent } from '../../../components/general/styles-input-field/styles-input-field.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { TagsInputFieldComponent } from '../../../components/general/tags-input-field/tags-input-field.component';
import { MatOptionModule } from '@angular/material/core';
import { MusicianAutoInputComponent } from '../../../components/general/musician-auto-input/musician-auto-input.component';
import { MatIconModule } from '@angular/material/icon';
import { ComposerAutoInputComponent } from '../../../components/general/composer-auto-input/composer-auto-input.component';
import { ImgUploadComponent } from '../../../components/general/img-upload/img-upload.component';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';
import { AudioFileUploadComponent } from 'src/app/components/tuneEdit/audio-file-upload/audio-file-upload.component';

@Component({
    selector: 'app-edit-tune',
    templateUrl: './edit-tune.component.html',
    styleUrls: ['./edit-tune.component.scss'],
    standalone: true,
    imports: [
      MainMenuComponent,
      MatExpansionModule,
      MatFormFieldModule,
      MatInputModule,
      ReactiveFormsModule,
      FormsModule,
      MatDatepickerModule,
      NgIf,
      MatButtonModule,
      ImgUploadComponent,
      NgFor,
      ComposerAutoInputComponent,
      MatIconModule,
      MusicianAutoInputComponent,
      NgxEditorModule,
      MatSelectModule,
      MatOptionModule,
      TagsInputFieldComponent,
      MatListModule,
      MatCheckboxModule,
      StylesInputFieldComponent,
      TempoCardComponent,
      MatDividerModule,
      MatTableModule,
      MatSortModule,
      TrackFileCellComponent,
      MatPaginatorModule,
      MatSlideToggleModule,
      ArrangementPartsTableComponent,
      BandTableComponent,
      DatePipe,
      TagInputDirective,
      AudioFileUploadComponent
    ]
})
export class EditTuneComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('midiInput') midiInput: any;

  public tune: Tune;
  public tuneImgFile: any;
  public newTonality: Tonality | undefined;
  public tonalities: Tonality[] = [];
  public tunetonalities: Tonality[] = [];
  public tempi: Speed[] = [];
  public tunetempi: Speed[] = [];
  public instruments:  Instrument[] = []
  public instrumentGroups:  InstrumentGroup[] = []
  public voices: Voice[] = []
  public levels: Level[] = []
  public minDate: Date;
  public displayedColumns: string[] = ['path', 'tonality', 'speed', 'instrumentgroup',
    'instrument', 'voice', 'mainvoice', 'level', 'musician', 'sheets', 'delete'];
  public dataSource = new MatTableDataSource<Track>(this.tracks);
  public taktData = false;
  public partners: Partner[] = [];

  public generalErrMsg = "";
  public ipWarning = "";
  public tonalitiesErrMsg = "";
  public tempiErrMsg = "";
  public tracksErrMsg = "";
  public arrangementWarnMsg = "";
  public bandWarnMsg = "";
  public dataCheckOK = false;

  public languages: Language[] = [];
  public pricinCategories: PricingCategory[] = [];

  public previewURL: SafeUrl = '';

  public arrangementEditTSID: Speed | undefined;

  public groups: UserGroup[] = [];

  editor!: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
  ];
  translationEditors: Editor[] = [];


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('newTonalitySelect') newTonalitySelect!: MatSelect;
  @ViewChild('newTempo') newTempo!: MatInput;
  @ViewChild(MatSort) sort!: MatSort;

  private tuneId: any;
  private collectionId: any;
  private collection: Collection | undefined;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private _liveAnnouncer: LiveAnnouncer,
    private sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private midiImporter: MidiImportService
  ){
    this.minDate = new Date();
    this.tune = { id: -1, title: '', coordinatelat: 0, coordinatelong: 0, isdemo: false }

  }

  get tracks(): Track[] {
    let out: Track[] = [];
    if (this.tune?.tracks)
      out = this.tune.tracks;
    return out;
  }

  get tuneMeter(): string {
    if (this.tune?.meters && this.tune.meters.length>0 && this.tune.meters[0].meter) {
      return this.tune.meters[0].meter.toString();
    }
    return "";
  }

  set tuneMeter(meter: string) {
    if (this.tune?.meters && this.tune.meters[0]) {
      this.tune.meters[0].meter = Number(meter);
    } else if(this.tune) {
      console.log('Add Tune Meter: '+meter)
      let newMeter: TuneMeter = { id: -1, barstart: 0, meter: Number(meter) };
      if(!this.tune.meters)
        this.tune.meters = [];
      this.tune.meters?.push(newMeter);
    }
  }

  get tuneImgPath() {
    if(this.tune.picture) {
      return environment.apiURL+"/photoImg/"+this.tune.picture.id;
    }
    return '';
  }

  get hasPricingHistory(): boolean {
    if(this.tune && this.tune.newPrice) {
      return true;
    } else if(this.tune && this.tune.pricinghistory) {
      return this.tune.pricinghistory.length>0;
    }
    return false;
  }

  get newPrice(): PricingHistory | undefined {
    if(this.tune && this.tune.newPrice) {
      return this.tune.newPrice;
    }
    return undefined;
  }

  get pricinghistory(): PricingHistory[] {
    if(this.tune && this.tune.pricinghistory) {
      return this.tune.pricinghistory;
    }
    return [];
  }

  get hasPreview(): boolean {
    if(/*this.tune && this.tune.previewpath && */this.previewURL) {
      return true;
    }
    return false;
  }

  get shownSpeed(): Speed | undefined {
    if(!this.taktData) {
      return this.arrangementEditTSID;
    }
    return undefined;
  }

  get isReleased(): boolean {
    if(this.tune && this.tune.publishdate) {
      const today = new Date();
      const publishDate = new Date(this.tune.publishdate);
      return publishDate<today;
    }
    return false;
  }


  ngOnInit(): void {
    this.tuneId = this.activatedRoute.snapshot.paramMap.get('tuneid');
    this.collectionId = this.activatedRoute.snapshot.paramMap.get('collectionid');
    console.log(this.collectionId);
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    //Load Tune
    if(this.tuneId>0) {
      const loadingDialog = this.dialog.open(LoadingTemplateComponent)

      this.httpClient.get<Tune>(environment.apiURL+'/tunes/'+this.tuneId, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.tune = data;

          const groupedList: Tonality[] = [];
          if (this.tune) {
            this.tune.tracks?.forEach((track) =>{
              if (track.tonality && track.tonality.id!==-1 && !groupedList.find(e => e.id===track.tonality?.id)) {
                groupedList.push(track.tonality);
              }
            });
          }
          this.tunetonalities = groupedList;

          const groupedTempiList: Speed[] = [];
          if (this.tune) {
            this.tune.tracks?.forEach((track) =>{
              if (track.speed && track.speed.id!==-1 && !groupedTempiList.find(e => e.id===track.speed?.id)) {
                groupedTempiList.push(track.speed);
              } else {
                track.speed = groupedTempiList.find(e => e.id===track.speed?.id)
              }
            });
          }
          this.tunetempi = groupedTempiList.sort((e1, e2) => e1.speed - e2.speed);
//          if(this.tunetempi.length>1) {
          if(this.tunetempi[0] && (this.tune.parts && this.tune.parts?.filter(p => {
            return p.tunespeedid && p.tunespeedid===this.tunetempi[0].id;
          }).length>0
          )) {
            this.arrangementEditTSID = this.tunetempi[0];
          }

          this.dataSource.data = this.tracks;

          //Check if there are Taktzahlen
          this.tune.parts?.forEach(e => {
            if (e.barend) {
              this.taktData = true;
            }
          });

          //Load preview
          if(this.tune.previewpath) {
            this.httpClient.get(`${environment.apiURL}/tunes/${this.tune.id}/preview`, { responseType: 'blob', headers })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: data => {
//                this.previewURL = URL.createObjectURL(data);
                this.previewURL = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data));

              },
              error: error => {
                console.error('There was an error on: ', `${environment.apiURL}/tunes/${this.tune.id}/preview`, error);
              }
            });
          }

          //Load translation Editors
          this.tune.translations?.forEach(t => {
            this.translationEditors.push(new Editor());
          });

          console.log('tune loaded ', this.tune);
          loadingDialog.close();
        });
    }

    //Load all possible Tonalities
    this.httpClient.get<Tonality[]>(environment.apiURL+'/tonalities', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.tonalities = data;
    });

    //Load all possible Instruments
    this.httpClient.get<Instrument[]>(environment.apiURL+'/instruments', { headers })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async data => {
        this.instruments = data;
      });

    //Load all possible IntrumentGroups
    this.httpClient.get<InstrumentGroup[]>(environment.apiURL+'/instrumentgroups', { headers })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async data => {
        this.instrumentGroups = data;
      });

    //Load all possible Voices
    this.httpClient.get<Voice[]>(environment.apiURL+'/voices', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.voices = data;
    });

    //Load all musicians

    //Load all Levels
    this.httpClient.get<Level[]>(environment.apiURL+'/levels', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.levels = data;
    });

    //Get all Languages
    this.httpClient.get<Language[]>(environment.apiURL+"/languages", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (language) => {
        this.languages.length = 0;
        this.languages.push(...language);
      }
    );

    //Load all Pricing Categories
    this.httpClient.get<PricingCategory[]>(environment.apiURL+"/pricing/categories", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (categories) => {
        this.pricinCategories.length = 0;
        this.pricinCategories = categories;
      }
    );

    //Load all partners
    this.httpClient.get<Partner[]>(environment.apiURL+"/partners", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (partners) => {
        console.log(partners)
        this.partners.length = 0;
        this.partners.push(...partners);
      }
    );


    this.editor = new Editor();

    //react to Midi Import
    this.midiImporter.addedTempo$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( tempo => {
      this.addTempo(tempo);
    });

    //Load Collection

    if(this.collectionId>0) {
      //Load Collection
      this.httpClient.get<Collection>(environment.apiURL+'/collections/'+this.collectionId, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.collection = data;
        });

    }

    //Load your groups
    this.httpClient.get<UserGroup[]>(environment.apiURL+'/usergroups', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      this.groups = data;
    });

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.editor?.destroy();

    this.midiImporter.clearTuneData();
  }

  getInstrumentIcon(instrument: Instrument): string {
    return environment.apiURL+'/instrImg/'+instrument.img;
  }

  addTonality() {
    console.log('Add Tonality: ', this.newTonality);
    if(this.newTonality)
      this.tunetonalities.push(this.newTonality);

    if(this.tunetonalities.length===1 && this.tune.tracks) {
      for (const track of this.tune.tracks) {
        track.tonality = this.newTonality;
      }
    }

    this.newTonality = undefined;
    this.newTonalitySelect.value = undefined;
  }

  removeTonality(tonality: Tonality) {
    const index = this.tunetonalities.indexOf(tonality);
    this.tunetonalities.splice(index, 1);
  }

  addTempo(newSpeed: Speed = { id: -1, speed: 0, start: 0, main: 0, countoffbeats: 0, countofffile: '', countoffchecksum: ''}) {
//    const newSpeed: Speed = { id: -1, speed: 0, start: 0, main: 0, countoffbeats: 0, countofffile: '', countoffchecksum: ''};
    if(this.tunetempi.length === 0) {
      newSpeed.main = 1;
    }

    this.tunetempi.push(newSpeed);
    if(this.tunetempi.length===1 && this.tune.tracks) {
      for (const track of this.tune.tracks) {
        track.speed = newSpeed;
      }
    }
    this.arrangementEditTSID = newSpeed
  }

  deleteTempo(tempo: Speed) {
    const dialogRef = this.dialog.open(TempoDeleteAlertComponent, {
      data: { title: 'Achtung', message: 'Achtung: Wenn du das Tempo löscht, werden alle damit verbundenen Tracks und Arrangement-Teile auch entfernt.'}
    });
    dialogRef.afterClosed()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => {
      if (this.tune && result==='confirmed') {
        const trackRemoves: Track[] = []
        this.tune.tracks?.forEach(track => {
          if(track.speed?.id===tempo?.id || track.speed?.speed==tempo.speed) {
            trackRemoves.push(track);
          }
        })
        for (const t of trackRemoves) {
          this.tune.tracks?.splice(this.tune.tracks.indexOf(t), 1);
        }
        this.dataSource.data = this.tracks;


        const partRemoves: Part[] = []
        this.tune.parts?.forEach(part => {
          if(part.speed?.speed==tempo?.speed || part.tunespeedid==tempo.id) {
            partRemoves.push(part);
          }
        });
        for (const p of partRemoves) {
          this.tune.parts?.splice(this.tune.parts.indexOf(p), 1);
        }

        const index : number = this.tunetempi.indexOf(tempo);
        this.tunetempi.splice(index, 1);

        if(this.tunetempi.length===1) {
          this.tune.tracks?.forEach(track => track.speed=this.tunetempi[0]);
        }
      }
  });

  }

  getSpeedString(tempo: Speed): string {
    return String(tempo.speed);
  }

  onAudioFilesChanged(files: any) {
    console.log(files)
  }

  addTrack() {
    const newTrack: Track = { id:-1, path: "", mainvoice: 1, variation: 0 };
    newTrack.tonality = { id: -1, designation: "Tonart wählen", accidentals: 0, minor: 0 };
    if (this.tunetonalities.length===1) {
      newTrack.tonality = this.tunetonalities[0]
    }
    newTrack.instrumentgroup = { id: -1, designation: "Section wählen" , linked: true, grouporder: 1, groupLevel: 0, muted: false, solo: false};
    newTrack.instrument = { id: -1, designation: "Instrument wählen", abbreviation: '', img: '', img_dark: '', instrumentorder: 1};
    newTrack.speed = { id: -1, speed: 0, main: 0, start: 0, countoffbeats: 0, countofffile: '', countoffchecksum: ''}
    newTrack.level = { id: 0, designation: 'All', short: '', description: ''}
    newTrack.voice = { id: 0, designation: 'Einzelstimme', voiceorder: 1}
    newTrack.voiceid = 0
    if (this.tunetempi.length===1) {
      newTrack.speed = this.tunetempi[0]
    }
    if (!this.tune.tracks) {
      this.tune.tracks = []
    }
    this.tune?.tracks?.push(newTrack);
    this.dataSource.data = this.tracks;
    this.paginator.pageIndex = this.paginator.length-1;
//    console.log('Add Track', newTrack);
  }

  deleteTrack(track: Track) {
    if (this.tune && this.tune.tracks) {
      const index : number = this.tune.tracks.indexOf(track);
      this.tune.tracks.splice(index, 1);
      this.dataSource.data = this.tracks;
    }
  }

  addPart() {
    const newPart: Part = { id:-1, designation: "", barstart: 0, barend: 0, pickup: 0 };
    if(!this.tune.parts) {
      this.tune.parts = [];
    }
    this.tune?.parts?.push(newPart);
  }

  deletePart(part: Part) {
    if (this.tune && this.tune.parts) {
      const index : number = this.tune.parts.indexOf(part);
      this.tune.parts.splice(index, 1);
    }
  }

  onImageChange(e: any) {
    const reader = new FileReader();

    if(e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.tuneImgFile = reader.result as string;
/*        this.uploadForm.patchValue({
          imgSrc: reader.result
        });*/

      };
    }
  }

  onPreviewChange(e: any) {
    const reader = new FileReader();

    if(e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      this.tune.newPreviewExt = file.name;
      this.tune.newPreview = file;

      this.previewURL = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
    }
  }

  updateTrackAudio(track: Track, audioFile: AudioFileData) {
    track.newAudio = audioFile.file
    track.newAudioFilename = audioFile.filename
  }

  updateCountoffFile(tempo: Speed, audioFile: AudioFileData) {
    tempo.newCountOff = audioFile.file
    tempo.newCountOffFilename = audioFile.filename
  }

  updateTuneImg(image: Blob) {
    this.tune.newImage = image;
  }

  updateTuneImgExt(fileExt: string) {
    this.tune.newImageExt = fileExt;
  }

  updateTuneImgId(id: number) {
    this.tune.imgid = id;
    this.tune.newImage = undefined;
  }


  addComposer() {
    const composer: Musician = { id: -1, surname: '', firstname: '' }
    if(!this.tune.composers) {
      this.tune.composers = [];
    }
    this.tune.composers?.push(composer);
  }

  deleteComposer(composer: Musician) {
    if(this.tune.composers) {
      const index = this.tune.composers.indexOf(composer);
      this.tune.composers.splice(index, 1);
    }
  }

  updateComposer(composer: Musician, updatedComposer: Musician) {
    composer.id = updatedComposer.id;
    composer.firstname = updatedComposer.firstname;
    composer.surname = updatedComposer.surname;
    composer.year_of_birth = updatedComposer.year_of_birth;
    composer.year_of_death = updatedComposer.year_of_death;
  }

  addArranger() {
    const arranger: Musician = { id: -1, firstname: '', surname: '', instrument: { id: 0, designation: '', img: '', img_dark: '', instrumentorder: 1} }
    if(!this.tune.arrangers) {
      this.tune.arrangers = [];
    }
    this.tune.arrangers?.push(arranger);
  }

  deleteArranger(arranger: Musician) {
    if(this.tune.arrangers) {
      const index = this.tune.arrangers.indexOf(arranger);
      this.tune.arrangers.splice(index, 1);
    }
  }

  updateArranger(arranger: Musician, updatedArranger: Musician) {
    if(updatedArranger) {
      arranger.id = updatedArranger.id;
      arranger.firstname = updatedArranger.firstname;
      arranger.surname = updatedArranger.surname;
      arranger.homepage = updatedArranger.homepage;
      arranger.image = updatedArranger.image;
      arranger.instrument = updatedArranger.instrument;
      }
  }

  getVoices(track: Track) {
    if(track.instrumentgroup) {
      return this.voices.filter(e => {
        if (!track.instrumentgroup || track.instrumentgroup.id===0 || !e.instrumentgroup ||
            e.instrumentgroup.id===0 || e.instrumentgroup.id===track.instrumentgroup?.id) {
          return true;
        }
        return false;
      });
    }
    return this.voices;
  }

  addSheet(track: Track) {
    const dialogRef = this.dialog.open(SheetSelectComponent);

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        if(!track.sheets) {
          track.sheets = [];
        }
        track.sheets.push(result);
      }
    });
  }

  removeSheet(track: Track, sheet: Sheet) {
    if(track.sheets) {
      track.sheets.splice(track.sheets.indexOf(sheet), 1);
    }
  }

  previewSheet(sheet: Sheet) {
    const dialogRef = this.dialog.open(PdfPreviewComponent, {
      data: { sheet: sheet },
      height: '80%',
      width: '80%'
    });
  }

  sheetFilename(sheet: Sheet) {
    return sheet.path.substring(sheet.path.lastIndexOf('/')+1);
  }


  getLinkedText(group: InstrumentGroup): string {
    if(group.linked) {
      return ' (linked)';
    }
    return '';
  }

  addTranslation() {
    if (!this.tune.translations) {
      this.tune.translations = []
    }

    this.tune.translations.push({languageid: 'en', covertext: ''})
    this.translationEditors.push(new Editor());
  }

  deleteTranslation(translation: TuneTexts) {
    if (this.tune.translations) {
      const index = this.tune.translations.indexOf(translation);
      this.tune.translations.splice(index, 1);
      this.translationEditors.splice(index, 1);
    }
  }

  getLanguageDesignation(languageid: string): string {
    const language = this.languages.find(e => e.id==languageid);
    if (language) {
      return language.designation
    }
    return '';
  }

  getTranslationEditor(translation: TuneTexts): Editor {
    const index = this.tune.translations!.indexOf(translation);
    return this.translationEditors[index];
  }


  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  saveTune() {
    this._snackBar.open('Speichert Daten')

    if(this.tune.newImage &&
      ((this.tune.imgid && this.tune.imgid!<=0) || !this.tune.imgid))
    {
      //Upload Picture and obtain ID
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      const formData = new FormData();
      let photo: Photo = {
        id: -1,
        path: 'TuneImg/'+this.tune.newImageExt,
        date: new Date(),
        authorid: -1
      }

      let filename = transliterate(this.tune.newImageExt!)

      formData.append('img', this.tune.newImage, filename);
      this.tune.newImage = undefined;

      formData.append('photo', JSON.stringify(photo));
      console.log(photo);

      this.httpClient.put<Photo>(environment.apiURL+`/photos`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.tune.imgid = resp.id;
          this.sendTuneData();
        },
        error: error => {
          console.log(error);
          this._snackBar.dismiss();
        }
      });
    } else {
      this.sendTuneData();
    }
  }

  adjustDate(date: Date) {
    const timeZoneOffset = date.getTimezoneOffset();
    return new Date(date.getTime() - timeZoneOffset * 60000);
  }

  openPriceChange() {
    const dialogRef = this.dialog.open(PricingCatChangePopupComponent, {
      data: { tuneid: this.tune.id, pricingType: 0} });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.tune.newPrice = result;
      }
    });

  }

  getCatTitle(catID: number) {
    const category = this.pricinCategories.find(c => c.id===catID);
    return category?.title;
  }

  sendTuneData() {
    this._snackBar.open('Speichert Daten')

    const savingDialog = this.dialog.open(SavingTemplateComponent)

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();

    if(this.tune.recordingdate && this.tune.recordingdate instanceof Date) {
      this.tune.recordingdate = this.adjustDate(this.tune.recordingdate);
    }
    if(this.tune.publishdate && this.tune.publishdate instanceof Date) {
      console.log(this.tune.publishdate);
      this.tune.publishdate = this.adjustDate(this.tune.publishdate);
    }
    if(this.tune.newPrice && this.tune.newPrice.start instanceof Date) {
      this.tune.newPrice.start = this.adjustDate(this.tune.newPrice.start);
    }

    if(this.tune.newPreview && this.tune.newPreviewExt) {
      const fileExt = this.tune.newPreviewExt.substring(this.tune.newPreviewExt.lastIndexOf('.'))
      const filename = transliterate(`TunePreview_${this.tune.id}${fileExt}`)
      console.log(filename);
      formData.append(this.tune.newPreviewExt, this.tune.newPreview, filename)
    }

    this.tune.tracks?.forEach(t => {
      if(t.newAudio && t.newAudioFilename) {
        if(t.level) {
          const level = this.levels.find(e => e.id==t.level?.id)
          t.level.short = String(level?.short)
        }
        const fileExt = t.newAudioFilename.substring(t.newAudioFilename.lastIndexOf('.'))
        let filename = transliterate(`Track_${t.id}_${this.tune.title}_${t.tonality?.id}_${t.speed?.speed}_${t.level?.short}_${t.instrument?.id}_${fileExt}`)
        console.log(filename);
        formData.append(t.newAudioFilename, t.newAudio, t.newAudioFilename);
      }
    })
    this.tunetempi.forEach(t => {
      if(t.newCountOff && t.newCountOffFilename) {
        const fileExt = t.newCountOffFilename.substring(t.newCountOffFilename.lastIndexOf('.'))
        let filename = transliterate(`TuneSpeed_${t.id}_${t.speed}Bpm${fileExt}`)
        formData.append(t.newCountOffFilename, t.newCountOff, filename);
      }
    })

    formData.append('tune', JSON.stringify(this.tune));
    console.log(JSON.stringify(this.tune))
    if(this.tuneId>0) {
      this.httpClient.post<Tune>(environment.apiURL+`/tunes/${this.tune.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.tune = resp;
          console.log(resp);
          if(this.collection) {
            this.saveCollection()
          } else {
            this._snackBar.dismiss();
            savingDialog.close();
    //        this.router.navigateByUrl('/tunes');
            this.dataCheckOK = false;
            window.location.reload();
          }
          savingDialog.close();
        },
        error: err => {
          this._snackBar.dismiss();
          savingDialog.close();
          this._snackBar.open("Es gab einen Fehler beim Speichern", "schliessen");
          this.dataCheckOK = false;
        }
      });
    } else {
      this.httpClient.put<Tune>(environment.apiURL+`/tunes`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this.tune = resp;
          console.log(resp);
          if(this.collection) {
            this.saveCollection()
          } else {
            this._snackBar.dismiss();
            savingDialog.close();
            this.dataCheckOK = false;
            window.location.reload();
          }
          savingDialog.close();
        },
        error: err => {
          this._snackBar.dismiss();
          savingDialog.close();
          this._snackBar.open("Es gab einen Fehler beim Speichern", "schliessen");
          this.dataCheckOK = false;
        }
//        this.router.navigateByUrl('/tunes');
      });
    }
  }

  checkTune() {
    let dataOK = true;
    let errorMsg = 'Es liegen Fehler vor!';
    this.generalErrMsg = "";
    this.tonalitiesErrMsg = "";
    this.tempiErrMsg = "";
    this.tracksErrMsg = "";
    this.arrangementWarnMsg = "";
    this.bandWarnMsg = "";

    //Check Allgemeine Daten
    if (!this.tune.title || this.tune.title.length===0) {
      dataOK = false;
      this.generalErrMsg += 'Stücktitel ist nicht definiert, ';
    }
    if (!this.tune.publishdate) {
      dataOK = false;
      this.generalErrMsg += 'Veröffentlichung ist nicht definiert, ';
    }
    if (!this.tune.newPreview && !this.tune.previewpath) {
      this.generalErrMsg += 'keine Stückvorschau definiert, '
    }
    if ((!this.tune.pricinghistory || this.tune.pricinghistory.length<1) && !this.tune.newPrice && !this.tune.isdemo) {
      this.generalErrMsg += 'kein Preis definiert, '
    }
    //Check Tonarten
    if (this.tunetonalities.length==0) {
      dataOK = false;
      this.tonalitiesErrMsg += 'keine Tonarten definiert, ';
    }
    //Check Tempi
    if (this.tunetempi.length==0) {
      dataOK = false;
      this.tempiErrMsg += 'keine Tempi definiert, ';
    }
    let hasMainTempo = false;
    this.tunetempi.forEach(e => {
      if(!e.speed || e.speed==0) {
        dataOK = false;
        this.tempiErrMsg += 'es gibt ein Tempo 0, ';
      }
      if(e.main) {
        hasMainTempo = true;
      }

      let hasTrack = false;
      this.tune.tracks?.forEach(t => {
        if(t.speed?.speed==e.speed) {
          hasTrack = true;
        }
      });
      if(!hasTrack) {
        dataOK = false;
        this.tracksErrMsg = "Tempo "+e.speed+" hat kein Track, "
      }
    });
    if(!hasMainTempo) {
      dataOK = false;
      this.tempiErrMsg += 'es gibt kein Haupttempo, ';
    }
    //Check Tracks
    if(!this.tune.tracks || this.tune.tracks?.length===0) {
      dataOK = false;
      this.tracksErrMsg += 'es gibt keine Tracks, ';
    }
    let filenames: string[] = [];
    this.tune.tracks?.forEach(t => {
      const index = this.tune.tracks?.indexOf(t);
      if(!t.path || t.path.length==0) {
        dataOK = false;
        this.tracksErrMsg += 'Track '+index+': kein File, ';
      }
      if(!t.tonality || t.tonality.id==-1) {
        dataOK = false;
        this.tracksErrMsg += 'Track '+index+': keine Tonart, ';
      }
      if(!t.speed || t.speed.speed==0) {
        dataOK = false;
        this.tracksErrMsg += 'Track '+index+': kein Tempo, ';
      }
      if(!t.instrument || t.instrument.id==-1) {
        dataOK = false;
        this.tracksErrMsg += 'Track '+index+': kein Instrument, ';
      }
      if(!t.instrumentgroup || t.instrumentgroup.id==-1) {
        dataOK = false;
        this.tracksErrMsg += 'Track '+index+': keine Section, ';
      }
      if(t.newAudio && t.newAudioFilename) {
        if (filenames.includes(t.newAudioFilename)) {
          dataOK = false;
          this.tracksErrMsg += 'Derselbe Filename wird zweimal verwendet: '+t.newAudioFilename
        }
        filenames.push(t.newAudioFilename)
      }
    });


    //Check Arrangement
    if ((!this.tune.meters && this.taktData) || (this.taktData && this.tune.meters && this.tune.meters[0].meter<1)) {
      this.arrangementWarnMsg += 'keine Taktart definiert, '
      dataOK = false;
    }
    if (!this.tune.parts || this.tune.parts.length<1) {
      this.arrangementWarnMsg += 'keine Arrangement-Teile definiert, '
    } else {
      for (let part of this.tune.parts) {
        if(!part.barend && !part.timeend) {
          dataOK = false;
          this.arrangementWarnMsg += 'Arrangement-Teil hat kein Ende'
        }
      }
    }

    if(!this.tune.musicians || this.tune.musicians.length<1) {
      this.bandWarnMsg = 'keine Band definiert';
    }

    if (dataOK && !this.generalErrMsg && !this.tonalitiesErrMsg &&
        !this.tempiErrMsg && !this.tracksErrMsg && !this.arrangementWarnMsg && !this.bandWarnMsg) {
      this._snackBar.open('Alle Daten sind komplett', 'ok');
      this.dataCheckOK = true;
    } else if (dataOK) {
      this._snackBar.open('Es gibt Warnungen. Für eine Veröffentlichung bitte nochmals prüfen', 'ok');
      this.dataCheckOK = true;
    } else {
      this._snackBar.open(errorMsg, "ok", { duration: 3000 });
      this.dataCheckOK = false;
    }

    console.log(this.tune);
  }

  cancel() {
    this.dataCheckOK = false;
  }

  startMidiImport(event: any) {
    const file = event.target.files[0];
    if (file) {
      const loadingDialog = this.dialog.open(LoadingTemplateComponent)
      this.midiImporter.importMidi(file, this.tune);
      loadingDialog.close();
    }
    if(this.midiInput) {
      this.midiInput.nativeElement.value = '';
    }
  }

  isProductionPartner(partner: Partner) {
    return partner.skus.find(sku => sku.tuneid===this.tune.id);
  }

  setProductionPartner(partner: Partner, checked: boolean) {
    if(checked) {
      const exists = partner.skus.find(sku => sku.tuneid===this.tune.id);
      if(!exists) {
        partner.skus.push({id: -1, partnerid: partner.id, tuneid: this.tune.id})
      }
      partner.skus.push()
    } else {
      let entry = partner.skus.find(sku => sku.tuneid===this.tune.id)
      if(entry) {
        partner.skus.splice(partner.skus.indexOf(entry), 1)
      }
    }
    this.savePartner(partner)
  }

  savePartner(partner: Partner) {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    formData.append('partner', JSON.stringify(partner));
    if(partner.id>0) {
      this.httpClient.post<Partner>(environment.apiURL+`/partners/${partner.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        console.log(resp);
      });
    }
  }

  changeTrackMusician(track: Track, event: any) {
//    if(event) {
      track.trackmusician = event
//    }
  }

  private saveCollection(): void {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();

    if(this.collectionId>0) {
      this.httpClient.get<SimpleTuneModel[]>(environment.apiURL+"/tunes", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tunes) => {
          const tune = tunes.find(t => t.id===this.tune.id);
          if(tune) {
            this.collection?.tunes?.push(tune);
            formData.append('collection', JSON.stringify(this.collection));
            this.httpClient.post<Collection>(environment.apiURL+`/collections/${this.collectionId}`, formData, {headers})
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: resp => {
                this.collection = resp;
                console.log(resp);
                this._snackBar.dismiss();
                this.dataCheckOK = false;
                this.router.navigateByUrl(`/tunes/${tune.id}`);
              },
              error: err => {
                this._snackBar.dismiss();
        //      this.router.navigateByUrl('/tunes');
                this.dataCheckOK = false;
                window.location.reload();
              }
            });
          }
        }
      );
    }
  }

}
