<h2 mat-dialog-title>band-on FAQs</h2>

<mat-dialog-content class="mat-typography">
  <strong>Cache</strong>
  <p>Wenn das Admin-Tool nicht so funktioniert wie gewünscht. Immer erst folgender Ablauf versuchen:</p>
    <ol>
      <li>Cache leeren (<a href='https://studyflix.de/informatik/was-ist-cache-5960'>Was ist der Cache?</a>)</li>
      <li>Seite neu laden</li>
    </ol>

  <strong>MIDI Import: Es werden keine Arrangement-Teile angelegt</strong>
  <p>Wenn die Arrangement-Teile nicht angelegt werden erst in der Arrangement-Sicht im Admin die korrekte Geschwindigkeit auswählen. Mit dem MIDI Import werden die Informationen immer zum korrekten Tempo geschrieben und nicht in "Alle Tempi".</p>

  <strong>Einzähler</strong>
  <p>Der Einzähler wird pro Tempo angegeben. Die Länge des Einzählers wird in Anzahl Schlägen in diesem Tempo angegeben. Die Länge in Sekunden ist daher: 60/Tempo * Anzahl Schläge.</p>
  <p>Beispiel: Tempo 120, 8 Schläge Einzähler (2 Takte) ergibt 60/120 * 8 = 4s Einzähler</p>
  <p>Es wird also im gesamten Stück immer 4s bevor ein Teil beginnt das Einzählfile abgespielt. Wenn ein Pickup angegeben wird, wird der Einzähler um die entsprechende Zeit gekürzt (z.B. 2 Schläge Pickup kürzt den Einzähler bei 120 um 1s). Wenn ein Timestamp für das Pickup angegeben wird, so ist dies der Zeitpunkt, zu welchem das Pickup startet.</p>

  <strong>Zeit Tempo Start</strong>
  <p>Die Angabe "Zeit Tempo Start" gibt an, zu welchem Zeitpunkt der Schlag 1 vom ersten Takt liegt. Auftakte werden dabei nicht berücksichtigt.</p>
  <p>Gibt es also in einem Audio-File erst 2s Stille und dann 1s Auftakt, dann ist "Zeit Tempo Start" 3s.</p>
  <p>Diese Information brauchen wir insbesondere bei der Angabe des Arrangements über Taktzahlen. So wie es aktuell aufgesetzt ist, muss aber diese Angabe immer gemacht werden.</p>

  <strong>Neue Taktzahl</strong>
  <p>Wenn die Nummerierung der Taktzahlen in einem Teil nicht fortlaufend sondern mit einer neuen Taktzahl beginnen soll, kann dies hier eingetragen werden.</p>

  <strong>Zeige Takte</strong>
  <p>Wenn der Haken hier gesetzt ist, dann werden im Arrangement-Tab die Takte für die Einstellung der Loops verwendet. Sonst ist der Slider zeitbasiert.</p>

  <strong>Ich kann das Veröffentlichungsdatum nicht ändern?</strong>
  <p>Wenn ein Stück oder eine Collection veröffentlicht wurde (also das Veröffentlichungsdatum älter ist, als das Aktuelle) dann kann dieses nicht mehr verändert werden (ausser direkt in der Datenbank durch Lea). Dies ist daher so gemacht, da ein Stück evtl. schon verkauft wurde. Wenn dann dass Veröffentlichungsdatum wieder geändert wird, gehen auch diese Käufe verloren.</p>

  <strong>Ich kann keine Stücke zu einer Collection hinzufügen/entfernen?</strong>
  <p>Wenn eine Collection veröffentlicht wurde (also das Veröffentlichungsdatum älter ist, als das Aktuelle) dann können die Stücke darin nicht mehr entfernt/hinzugefügt werden. Dies, da die Collection evtl. schon verkauft wurde und wir den Inhalt der Verkäufe nachträglich verhindern müssen.</p>

  <strong>Kann ich die Reihenfolge der Instrumente definieren?</strong>
  <p>Nein. Die Reihenfolge ist direkt in der Datenbank definiert und hängt von Instrumentengruppen, Stimmen und Instrumenten ab.</p>

  <strong>Metronom</strong>
  <p>Für die Metronom-Spuren gibt es eine separate Instrumentengruppe und eine separate Stimme. Das Metronom muss immer in der Gruppe "Metronom" eingetragen werden. Wenn mehrere Metronom-Optionen vorhanden sind, immer die Stimme "Metronom" verwenden und darauf auchten, dass nur eine Hauptstimme für die verschiedenen Metronome definiert ist.</p>

  <strong>Sonderzeichen</strong>
  <p>Sonderzeichen stellen ein Problem für Linux dar (Server-Betriebssystem). Um das zu Umgehen, muss die Serversoftware kritische Zeichen erkennen und ersetzen. Dies habe ich für Umlaute und französische Zeichen gemacht. Sollten weitere Sonderzeichen kommen, bitte bei mir melden.</p>

  <strong>Bekannte Fehler</strong>
  <ul>
    <li><b>Covertext</b>: Aktuell scheint die Eingabe der Covertexte gestört, wenn noch keiner angegeben wurde. Um das Problem zu beheben: das Feld Covertext leeren und einmal speichern. Danach sollte es gem. meiner Erfahrung klappen.</li>
    <li><b>Ist Demo</b>: Es gibt einen Fehler, dass die Angabe "Ist Demo" nicht entfernt werden kann.</li>
    <li><b>Hauptstimme</b>: Es gibt einen Fehler, dass die Angabe "Hauptstimme" nicht entfernt werden kann.</li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>schliessen</button>
</mat-dialog-actions>
