<app-main-menu title="Testaccount"></app-main-menu>

<div class="content mat-elevation-z8">
  <h1>Testaccount eröffnen</h1>

  <p>Ein Testaccount gibt den Zugriff auf alle veröffentlichten Inhalte. Der Zugriff ist drei Monate gültig, danach wir der Account auf einen Standard-Account geändert.</p>
  <p>Es wird automatisch ein E-Mail mit dem Passwort an die angegebene E-Mail Adresse geschickt.</p>

  <form [formGroup]="formData" (ngSubmit)="onSubmit()" class="flex-form">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" type="email">
    </mat-form-field>
    <div *ngIf="name!.invalid && (name?.touched || name?.dirty)" class="alert alert-danger">
      <small *ngIf="name && name.errors && name.errors['required']" >Bitte gib einen Namen ein.</small>
      <!--question mark(?) is a safe navigation operator-->
      <small *ngIf="name && name.errors && name.errors['minlength']" >Der Name muss mind. 3 Zeichen enthalten</small>
    </div>

    <mat-form-field appearance="fill">
      <mat-label>E-Mail</mat-label>
      <input matInput formControlName="email" type="email">
    </mat-form-field>
    <div *ngIf="email?.invalid && (email?.dirty || email?.touched)"
        class="alert alert-danger">
        <small *ngIf="email && email.errors && email.errors['required']" >Bitte gib einen Namen ein.</small>
        <small *ngIf="email && email.errors && email.errors['pattern']" >E-Mail Adresse ist ungültig.</small>
    </div>

    <p>Sprache (wir keine Sprache ausgewählt wird standardmässig Englisch eingetragen)</p>
    <mat-form-field>
      <mat-label>Sprache</mat-label>
      <mat-select formControlName="language">
        @for (language of languages; track $index) {
          <mat-option [value]="language.id">{{language.designation}}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <p>E-Mail Kopie der Registrierungsmail senden an (das Passwort wird nicht an die Kopie geschickt):</p>
    <mat-form-field appearance="fill">
      <mat-label>E-Mail Kopie</mat-label>
      <input matInput formControlName="copyemail" type="email">
    </mat-form-field>
    <div *ngIf="copyemail?.invalid && (copyemail?.dirty || copyemail?.touched)"
        class="alert alert-danger">
        <small *ngIf="copyemail && copyemail.errors && copyemail.errors['pattern']" >E-Mail Adresse ist ungültig.</small>
    </div>


    <input formControlName="mailadd" style="display:none">
    <button mat-raised-button type="submit" [disabled]="!formData.valid" color="primary">Eröffnen</button>
  </form>
</div>
