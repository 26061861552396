import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, PricingCategory, PricingHistory } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-pricing-cat-change-popup',
    templateUrl: './pricing-cat-change-popup.component.html',
    styleUrls: ['./pricing-cat-change-popup.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatFormFieldModule, MatInputModule, MatDatepickerModule, ReactiveFormsModule, FormsModule, MatSelectModule, NgFor, MatOptionModule, MatDialogActions, MatButtonModule, MatDialogClose]
})
export class PricingCatChangePopupComponent implements OnInit, OnDestroy {

  @Input() pricingHistory: PricingHistory = {id: -1, pricingcategoryid: -1, start: new Date()};

  categories: PricingCategory[] = [];

  private unsubscribe$ = new Subject<void>();
  private pricingType = 0;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<PricingCatChangePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(data && data.tuneid) {
      this.pricingHistory.tuneid = data.tuneid;
    }
    if(data && data.collectionid) {
      this.pricingHistory.collectionid = data.collectionid;
    }
    if(data && data.pricingType) {
      this.pricingType = data.pricingType;
    }
  }

  ngOnInit(): void {
    this.refreshCategories();
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  refreshCategories(): void {
    //Get all Tunes
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<PricingCategory[]>(environment.apiURL+"/pricing/categories", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (categories) => {
          this.categories.length = 0;
          this.categories = categories.filter(c => c.type===this.pricingType);
        }
      );
  }
}
