import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-cropper-dialog',
    templateUrl: './cropper-dialog.component.html',
    styleUrls: ['./cropper-dialog.component.scss'],
    standalone: true,
    imports: [MatCardModule, ImageCropperComponent, NgIf, MatFormFieldModule, MatSelectModule, MatOptionModule, MatButtonModule, MatDialogClose]
})
export class CropperDialogComponent {

  public imgFile: any;
  public filename: any;
  public file: any;
  public originalAspectRatio = 1;
  public selectedAspectRatio = 1;

  public forcedAspectRatio = 'free';

  constructor(
    public dialogRef: MatDialogRef<CropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.forcedAspectRatio = data.forcedAspectRatio;
    if(this.forcedAspectRatio!=='free') {
      this.selectedAspectRatio = Number(this.forcedAspectRatio);
    }

    const file = data.imageChangedEvent.target.files[0];

    // Get the file type and name
    const fileType = file.type;
    const fileName = file.name;

    // Use a FileReader to read the content of the file
    const reader = new FileReader();
    reader.readAsDataURL(file);

    // Get the width and height of the image from the FileReader's result
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        this.originalAspectRatio = width/height;
        if(this.forcedAspectRatio==='free') {
          this.selectedAspectRatio = this.originalAspectRatio;
        }
      };
    };
  }

  get showRatioSelect(): boolean {
    if(this.forcedAspectRatio === 'free') {
      return false;
    }
    return true;
  }

  cancelCropper(): void {
    this.dialogRef.close();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imgFile = event.base64;

    let arr = this.imgFile.split(',');
    let mime = arr[0].match(/:(.*);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    this.file = new File([u8arr], this.filename, {type: mime});

  }
}
