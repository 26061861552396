<mat-card class="container">
  <mat-card-title>
      {{ 'AUTH.CONFIRMEMAIL.CONFIRM' | translate: { email: email } }}
  </mat-card-title>
  <mat-card-content>
      <div *ngIf="validForm && !confirmed">
        <button mat-raised-button color="primary" (click)="onSubmit()">
          {{ 'AUTH.CONFIRMEMAIL.CHANGE' | translate }}
        </button>
      </div>
      <div *ngIf="!validForm && errmsg">
        {{ errmsg }}
      </div>
      <div *ngIf="!validForm && !errmsg">
          <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="confirmed">
        {{ 'AUTH.CONFIRMEMAIL.CONFIRMED' | translate: { email: email } }}
        <button mat-raised-button color="primary" (click)="closeTab()">
          {{ 'AUTH.CONFIRMEMAIL.CLOSE' | translate }}
        </button>
      </div>
  </mat-card-content>
</mat-card>
