<app-main-menu title="{{ tune.title }}" backref="/tunes"></app-main-menu>

<div class="content mat-elevation-z8">

  <p><b>Achtung:</b> Speichern nicht vergessen!</p>
  <table class="tracks-table">
    <tr>
      <th>Instrument</th>
      <th>Level</th>
      <th>Tempo</th>
      <th>Empty-Sheet</th>
      <th>Rhythm-Sheet</th>
      <th>Part-Sheet</th>
    </tr>
    @for (track of tune.tracks; track $index) {
      <tr>
        <td>{{ track.instrument?.designation }}</td>
        <td>
          @if(track.level?.id!==0) {
            {{ track.level?.designation }}
          }
        </td>
        <td>
          {{ track.speed?.speed }}
        </td>
        <td>
          @if(getSheets(track, 1).length===0) {
            kein Sheet
          }
          @else {
            @for (sheet of getSheets(track, 1); track $index) {
              <button mat-button (click)="previewFile(sheet)">Empty Sheet</button>
              <button mat-button color="warn" (click)="removeSheet(track, sheet)">Entfernen</button>
            }
          }
        </td>
        <td>
          @if(getSheets(track, 2).length===0) {
            Kein Sheet
          }
          @else {
            @for (sheet of getSheets(track, 2); track $index) {
              <button mat-button (click)="previewFile(sheet)">Rhythm Sheet</button>
              <button mat-button color="warn" (click)="removeSheet(track, sheet)">Entfernen</button>
            }
          }
        </td>
        <td>
          @if(getSheets(track, 3).length===0) {
            Kein Sheet
          }
          @else {
            @for (sheet of getSheets(track, 3); track $index) {
              <button mat-button (click)="previewFile(sheet)">Part Sheet</button>
              <button mat-button color="warn" (click)="removeSheet(track, sheet)">Entfernen</button>
            }
          }
        </td>
      </tr>
    }
  </table>

  <h2>Sheets</h2>
  <div style="flex: 1; height: 400px;">
    <app-sheet-browser mode="select" [tune]="tune" (onSheetAdd)="onSheetAdd($event)"></app-sheet-browser>
  </div>

  <div class="button-row">
    <button mat-raised-button routerLink="/tunes" style="margin-right: 16px">Abbrechen</button>
    <button mat-raised-button color="accent" (click)="saveTune()">Speichern</button>
  </div>

</div>
