import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { AuthenticationService, Voice } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { environment } from 'src/environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-voices-list',
    templateUrl: './voices-list.component.html',
    styleUrls: ['./voices-list.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatListModule, NgFor, MatButtonModule, RouterLink, MatPaginatorModule, MatIconModule]
})
export class VoicesListComponent {
  public searchTerm = '';
  public voices: Voice[] = [];
  _searchedVoices: Voice[] = [];

  private unsubscribe$ = new Subject<void>();

  private pageSize = 10;
  private pageIndex = 0;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) {
  }

  get paginatedvoices(): Voice[] {
    return this.searchedVoices.slice(this.pageIndex*this.pageSize, (this.pageIndex+1)*this.pageSize);
  }

  get searchedVoices(): Voice[] {
    if(this.searchTerm && this.searchTerm.trim() !== '') {
      return this._searchedVoices;
    }
    return this.voices;
  }

  ngOnInit(): void {
    //Get all Tunes
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Voice[]>(environment.apiURL+"/voices", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (voices) => {
          this.voices.length = 0;
          this.voices.push(...voices);
          loadingDialog.close();
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchVoice(event: any) {
    const text = event.target.value;
    this._searchedVoices = this.voices;
    if(text && text.trim() !== '') {
      this._searchedVoices = this._searchedVoices.filter((voice: Voice) => {
        const des = voice.designation + voice.instrumentgroup?.designation;
        return (des.toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
    }
    this.pageIndex = 0;
  }
}
