import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthenticationService, UserModel } from 'bandon-shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  authService = inject(AuthenticationService)
  httpClient = inject(HttpClient)

  user: UserModel | undefined;

  constructor() {
    this.authService.isAuthenticated.subscribe(auth => {
      if(auth) {
        this.getUserData();
      } else {
        this.user = undefined;
      }
    })
  }

  public getUserData() {
    // Get user Data
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const token = this.authService.getToken();
    if(token) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const user_id = this.authService.getUserID();
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      this.httpClient.get<UserModel>(environment.apiURL+`/users/${user_id}`, {headers})
        .subscribe(resp => {
          this.user = resp;
        });
    }
  }
}
