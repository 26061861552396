import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, Composer } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-edit-composers',
    templateUrl: './edit-composers.component.html',
    styleUrls: ['./edit-composers.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatGridListModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatButtonModule, RouterLink]
})
export class EditComposersComponent {
  public composer: Composer;

  private unsubscribe$ = new Subject<void>();
  private composerID: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ){
    this.composer = { id: -1, surname: '', name: '', born: 0, died: 0}
  }

  ngOnInit(): void {
    this.composerID = this.activatedRoute.snapshot.paramMap.get('composerid');
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    if(this.composerID>0) {
      const loadingDialog = this.dialog.open(LoadingTemplateComponent)
      console.log('load composer: ', this.composerID);

      //Load Musician
      this.httpClient.get<Composer>(environment.apiURL+'/composers/'+this.composerID, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.composer = data;

          //Load Musician Image
  //        this.tuneImgFile = this.getTuneIcon(this.tune);
          console.log('Composer loaded ', this.composer);
          loadingDialog.close();
        });

    } else {
      this.composer = { id: -1, surname: '', name: '', born: 0, died: 0}
    }

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveComposer() {
    this.snackBar.open('Speichert Daten')
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();

    formData.append('composer', JSON.stringify(this.composer));
    if(this.composerID>0) {
      this.httpClient.post<Composer>(environment.apiURL+`/composers/${this.composer.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.composer = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/composers');
      });
    } else {
      this.httpClient.put<Composer>(environment.apiURL+`/composers`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.composer = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/composers');
      });
    }
  }

}
