import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MainMenuComponent } from 'src/app/components/navigation/main-menu/main-menu.component';
import { AuthenticationService, Collection, GroupRole, GroupUser, SimpleTuneModel, UserGroup, UserModel } from 'bandon-shared';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { environment } from 'src/environments/environment';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-edit-group',
  standalone: true,
  imports: [
    CommonModule,
    MainMenuComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatPaginatorModule
  ],
  templateUrl: './edit-group.component.html',
  styleUrl: './edit-group.component.scss'
})
export class EditGroupComponent implements OnInit, OnDestroy {
  activatedRoute = inject(ActivatedRoute);
  httpClient = inject(HttpClient);
  authService = inject(AuthenticationService);
  router = inject(Router);
  snackBar = inject(MatSnackBar);
  datePipe = inject(DatePipe);
  dialog = inject(MatDialog);
  userService = inject(UserDataService)

  public group: UserGroup;

  public usersControl = new FormControl<string | UserModel>('');

  public users: UserModel[] = [];
  public filteredUsers!: Observable<UserModel[]>;
  public enableUserAdd = false;
  public selectedUser: UserModel | undefined;

  public roles: GroupRole[] = [];

  private unsubscribe$ = new Subject<void>();
  private groupID = -1;

  private tunesPageSize = 10;
  private tunesPageIndex = 0;

  private collectionsPageSize = 10;
  private collectionsPageIndex = 0;

  private usersPageSize = 10;
  private usersPageIndex = 0;

  public searchUserTerm = '';
  _searchedUsers: GroupUser[] = [];

  constructor() {
    this.group = { id: -1, designation: 'Neue Gruppe', description: '', identifier: '' };
  }

  get paginatedCollections(): Collection[] {
    if(this.group.collections) {
      return this.group.collections.slice(this.collectionsPageIndex*this.collectionsPageSize, (this.collectionsPageIndex+1)*this.collectionsPageSize);
    }
    return [];
  }

  get searchedUsers(): GroupUser[] {
    if(this.searchUserTerm && this.searchUserTerm.trim() !== '') {
      return this._searchedUsers;
    }
    let search = this.group.users;
    if(search) {
      return search;
    }
    return [];
  }
  get paginatedUsers(): GroupUser[] {
    return this.searchedUsers.slice(this.usersPageIndex*this.usersPageSize, (this.usersPageIndex+1)*this.usersPageSize);
  }

  get paginatedTunes(): SimpleTuneModel[] {
    if(this.group.tunes) {
      return this.group.tunes.slice(this.tunesPageIndex*this.tunesPageSize, (this.tunesPageIndex+1)*this.tunesPageSize);
    }
    return [];
  }

  get loggedInUser(): UserModel | undefined {
    return this.userService.user;
  }

  get canAdd(): boolean {
    if (this.group.userlimit && this.group.users) {
      return this.group.users.length<this.group.userlimit;
    }
    return true;
  }

  get canEditGroups(): boolean {
    if (this.userService.user && this.userService.user.roles) {
      for(let role of this.userService.user.roles) {
        if(role.privileges) {
          for(let p of role.privileges) {
            if(p.designation==='edit_groups') {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  ngOnInit(): void {
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    let gid = this.activatedRoute.snapshot.paramMap.get('groupid');
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    if(gid && Number(gid)>0) {
      this.groupID = Number(gid);

      //Load Group
      this.httpClient.get<UserGroup>(environment.apiURL+'/usergroups/'+gid, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
          this.group = data;

          console.log(data);
          loadingDialog.close();
        });
    }

    //Load all users
    this.httpClient.get<UserModel[]>(environment.apiURL+"/users", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (users) => {
        this.users.length = 0;
        this.users.push(...users);
          loadingDialog.close();
      }
    );

    this.filteredUsers = this.usersControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        if(value) {
          const name = typeof value === 'string' ? value: this.styleDisplayFn(value);
          return this._filterUsers(name as string);
        }
        const out: any[] = this.users.slice();
        return out;
      })
    )

    this.usersControl.valueChanges.subscribe(value => {
      if (value) {
        this.enableUserAdd = true;
        this.selectedUser = value as UserModel;
      } else {
        this.enableUserAdd = false;
      }
    })

    //Load all roles
    this.httpClient.get<GroupRole[]>(environment.apiURL+"/usergroups/roles", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (roles) => {
        this.roles.length = 0;
        this.roles.push(...roles);
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addUser(user: UserModel | undefined) {
    if(user) {
      if(!this.group.users) {
        this.group.users = [];
      }
      this.group.users.push({user});
    }
  }

  removeUser(userid: string) {
    if(this.group.users) {
      const user = this.group.users.find(u => u.user.uid===userid);
      if(user) {
        this.group.users.splice(this.group.users.indexOf(user), 1);
      }
    }
  }

  setOwner(userid: string) {
    if(this.group.users) {
      const user = this.group.users.find(u => u.user.uid===userid);
      if(user) {
        const owner = this.group.users.find(u => u.role?.designation==="Owner");
        const adminRole = this.roles.find(r => r.designation==="Administrator");
        if(owner) {
          owner.role = undefined;
          if(adminRole) {
            owner.role = adminRole;
          }
        }
        //TODO: read from DB
        const ownerRole = this.roles.find(r => r.designation==="Owner");
        if(ownerRole) {
          user.role = ownerRole;
          this.save(false);
        }
      }
    }
  }

  isOwner(userid: string): boolean {
    if(this.group.users) {
      const user = this.group.users.find(u => u.user.uid===userid);
      if(user) {
        const owner = this.group.users.find(u => u.role?.designation==="Owner");
        if(owner && user.role?.id===owner.role?.id) {
          return true;
        }
      }
    }
    return false;
  }

  isAdmin(userid: string): boolean {
    if(this.group.users) {
      const user = this.group.users.find(u => u.user.uid===userid);
      if(user) {
        const owner = this.group.users.find(u => u.role?.designation==="Administrator");
        if(owner && user.role?.id===owner.role?.id) {
          return true;
        }
      }
    }
    return false;
  }

  changeAdmin(userid: string) {
    if(this.group.users) {
      const user = this.group.users.find(u => u.user.uid===userid);
      if(user) {
        const adminRole = this.roles.find(r => r.designation==="Administrator");
        if(adminRole) {
          if (user.role?.id===adminRole.id) {
            user.role = undefined;
          } else {
            user.role = adminRole;
          }
          this.save(false);
        }
//        this.group.users.splice(this.group.users.indexOf(user), 1);
      }
    }

  }

  save(goBack = true) {
    this.snackBar.open('Speichert Daten')

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    formData.append('group', JSON.stringify(this.group));
    if(this.groupID>0) {
      this.httpClient.post<UserGroup>(environment.apiURL+`/usergroups/${this.group.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.group = resp;
        console.log(resp);
        this.snackBar.dismiss();
        if(goBack) {
          this.router.navigateByUrl('/usergroups');
        }
      });
    } else {
      this.httpClient.put<UserGroup>(environment.apiURL+`/usergroups`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.group = resp;
        console.log(resp);
        this.snackBar.dismiss();
        if(goBack) {
          this.router.navigateByUrl('/usergroups');
        }
      });
    }
  }

  styleDisplayFn(user: UserModel): string {
    let out = '';
    if(user) {
      out = user.name+', '+user.email;
    }
    return out;
  }

  collectionImgPath(collection: Collection) {
    if(collection.picture) {
      return environment.apiURL+"/photoImg/"+collection.picture.id;
    }
    return '';
  }

  tuneImgPath(tune: SimpleTuneModel) {
    if(tune.picture) {
      return environment.apiURL+"/photoImg/"+tune.picture.id;
    }
    return '';
  }

  handleCollectionsPageEvent(event: PageEvent) {
    this.collectionsPageSize = event.pageSize;
    this.collectionsPageIndex = event.pageIndex;
  }

  handleTunesPageEvent(event: PageEvent) {
    this.tunesPageSize = event.pageSize;
    this.tunesPageIndex = event.pageIndex;
  }

  handleUsersPageEvent(event: PageEvent) {
    this.usersPageSize = event.pageSize;
    this.usersPageIndex = event.pageIndex;
  }

  private _filterUsers(value: string): any[] {
    const filterValue = value.toLowerCase();

    const out: any[] = this.users.filter(user => {
      const existing = this.group.users?.find(u => user.uid===u.user.uid);
      if(existing) {
        return false;
      }
      return (user.name+user.email).toLowerCase().includes(filterValue)
    });
    return out;
  }

  searchUser(event: any) {
    const text = event.target.value;
    let search = this.group.users;
    if(search) {
      this._searchedUsers = search;
    }
    if(text && text.trim() !== '') {
      this._searchedUsers = this._searchedUsers.filter((gu: GroupUser) => {
        const des = (gu.user.name+gu.user.email).toLowerCase();
        return (des.toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
    }
    this.usersPageIndex = 0;
  }

}
