<mat-card>
  <mat-card-header>
    <mat-card-title>Foto wählen</mat-card-title>
  </mat-card-header>
  <div class="main-view">

    <div style="flex:1; background-color: aqua; height: calc(100vh - 230px)">
      <app-photo-browser mode="select" (onSelect)="onSelect($event)"></app-photo-browser>
    </div>
  </div>


  <!--<div class="content">

    <button mat-button (click)="showAll()">Zeige alle</button>
    <div class="structure-container mat-elevation-z8">
      <div class="hierarchyLevel-div" *ngFor="let level of [].constructor(hierarchyLevelsCount); let i = index">
        <mat-list>
          <mat-list-item *ngFor="let item of getFolders(i)" (click)="openFolder(i, item)">
            <mat-icon matListItemIcon>folder</mat-icon>
            <div matListItemTitle>{{ item }}</div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>

    <mat-grid-list cols="4" rowHeight="2:1">
      <mat-grid-tile *ngFor="let photo of shownPhotos" [mat-dialog-close]="photo">
        <img [src]="getPhotoPath(photo)" class="img-icon">
      </mat-grid-tile>
    </mat-grid-list>

  </div>-->

  <mat-card-actions class="button-container">
    <button mat-button (click)="onCancelClick()">Abbrechen</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="selectedPhoto" [disabled]="!selectedPhoto">Auswählen</button>
  </mat-card-actions>

</mat-card>
