import { AuthenticationService, Instrument } from 'bandon-shared';
import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-instrument-list',
    templateUrl: './instrument-list.component.html',
    styleUrls: ['./instrument-list.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatListModule, NgFor, MatButtonModule, RouterLink, MatPaginatorModule, MatIconModule]
})
export class InstrumentListComponent {

  public searchTerm = '';
  public instruments: Instrument[] = [];
  _searchedInstruments: Instrument[] = [];

  private unsubscribe$ = new Subject<void>();

  private pageSize = 10;
  private pageIndex = 0;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) {
  }

  get paginatedInstruments(): Instrument[] {
    return this.searchedInstruments.slice(this.pageIndex*this.pageSize, (this.pageIndex+1)*this.pageSize);
  }

  get searchedInstruments(): Instrument[] {
    if(this.searchTerm && this.searchTerm.trim() !== '') {
      return this._searchedInstruments;
    }
    return this.instruments;
  }

  ngOnInit(): void {
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    //Get all Instruments
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Instrument[]>(environment.apiURL+"/instruments", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tunes) => {
          this.instruments.length = 0;
          this.instruments.push(...tunes);
          loadingDialog.close();
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getInstrumentImg(instrument: Instrument): string {
    return environment.apiURL+'/instrImg/'+instrument.img;
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  searchInstrument(event: any) {
    const text = event.target.value;
    this._searchedInstruments = this.instruments;
    if(text && text.trim() !== '') {
      this._searchedInstruments = this._searchedInstruments.filter((instrument: Instrument) => {
        //TODO: Add Recording Date, Add Tags, Add Instruments
        const des = instrument.designation;
        return (des.toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
    }
    this.pageIndex = 0;
  }

}
