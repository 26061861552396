import { AuthenticationService, UserModel } from 'bandon-shared';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatPaginator, PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatButtonModule, RouterLink, MatPaginatorModule]
})
export class UsersListComponent implements OnInit, OnDestroy, AfterViewInit {
  public searchTerm = '';
  public users: UserModel[] = [];
  _searchedUsers: UserModel[] = [];

  displayedColumns: string[] = ['email', 'name', 'registerdate', 'roles', 'edit'];
  public dataSource = new MatTableDataSource<UserModel>(this.users);

  private unsubscribe$ = new Subject<void>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    //Get all Tunes
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<UserModel[]>(environment.apiURL+"/users", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (users) => {
          this.users.length = 0;
          this.users.push(...users);
          this.dataSource.data = this.users;
          loadingDialog.close();
        }
      );

    this.dataSource.filterPredicate = (data: UserModel, filter: string) => {
      const modelValue = data.name+' '+data.email+' '+this.getDateString(data.registerdate)+' '+
        this.getRolesString(data);
      return modelValue.toLowerCase().includes(filter);
      };
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getDateString(inputString: string | undefined) {
    if (inputString) {
      const date = new Date(inputString);
      return this.datePipe.transform(date, 'dd.MM.yyyy');
    }
    return "";
  }

  getRolesString(user: UserModel) {
    let out = '';
    if (user.roles) {
      let i = 0;
      for (let role of user.roles) {
        if(i>0)
          out += ', ';
        out += role.designation;
        if (role.removeDate) {
          const date = new Date(role.removeDate)
          out += ' (bis '+this.datePipe.transform(date, 'dd.MM.yyyy')+')'
        }
        i++;
      }
    }
    return out;
  }
}
