import { AuthenticationService, Instrument, InstrumentTitles, Language } from 'bandon-shared';
import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { transliterate } from 'transliteration';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatDialog } from '@angular/material/dialog';
import { ImgUploadComponent } from '../../../components/general/img-upload/img-upload.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgFor } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-edit-instrument',
    templateUrl: './edit-instrument.component.html',
    styleUrls: ['./edit-instrument.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatCheckboxModule, MatExpansionModule, NgFor, MatSelectModule, MatOptionModule, MatButtonModule, MatIconModule, ImgUploadComponent, RouterLink]
})
export class EditInstrumentComponent {
  public instrument: Instrument;

  public languages: Language[] = [];

  private unsubscribe$ = new Subject<void>();
  private instrumentID: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ){
    this.instrument = { id: -1, designation: '', img: '0', img_dark: '', instrumentorder: 1}
  }

  get instrImgPath(): string {
    if(this.instrument.img) {
      return environment.apiURL+'/instrImg/'+this.instrument.img;
    }
    return '';
  }

  get instrImgDarkPath(): string {
    if(this.instrument.img) {
      return environment.apiURL+'/instrImg/'+this.instrument.img_dark;
    }
    return '';
  }

  ngOnInit(): void {
    this.instrumentID = this.activatedRoute.snapshot.paramMap.get('instrumentid');
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    if(this.instrumentID>0) {
      const loadingDialog = this.dialog.open(LoadingTemplateComponent)
      console.log('load instrument: ', this.instrumentID);

      //Load Musician
      this.httpClient.get<Instrument>(environment.apiURL+'/instruments/'+this.instrumentID, { headers })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async data => {
          this.instrument = data;

          //Load Musician Image
  //        this.tuneImgFile = this.getTuneIcon(this.tune);
          console.log('Instrument loaded ', this.instrument);
          loadingDialog.close();
        });

    } else {
      this.instrument = { id: -1, designation: '', img: '', img_dark: '', instrumentorder: 1}
    }

    //Get all Languages
    this.httpClient.get<Language[]>(environment.apiURL+"/languages", {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (language) => {
        this.languages.length = 0;
        this.languages.push(...language);
      }
    );

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getInstrumentIcon(instrument: Instrument): string {
    return environment.apiURL+instrument.img;
  }

  getInstrumentIconDark(instrument: Instrument): string {
    return environment.apiURL+instrument.img_dark;
  }

  saveInstrument() {
    this.snackBar.open('Speichert Daten')
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    if(this.instrument.newImage) {
      let filename = transliterate(`Instrument_${this.instrument.designation}${this.instrument.newImageExt}`)
      formData.append('img', this.instrument.newImage, filename);
    }
    if(this.instrument.newImageDark) {
      let filename = transliterate(`Instrument_Dark_${this.instrument.designation}${this.instrument.newImageDarkExt}`)
      formData.append('imgDark', this.instrument.newImageDark, filename);
    }

    formData.append('instrument', JSON.stringify(this.instrument));
    if(this.instrumentID>0) {
      this.httpClient.post<Instrument>(environment.apiURL+`/instruments/${this.instrument.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.instrument = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/instruments');
      });
    } else {
      this.httpClient.put<Instrument>(environment.apiURL+`/instruments`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.instrument = resp;
        console.log(resp);
        this.snackBar.dismiss();
        this.router.navigateByUrl('/instruments');
      });
    }
  }

  changeImg(event: Blob) {
    this.instrument.newImage = event;
  }

  changeImgDark(event: Blob) {
    this.instrument.newImageDark = event;
  }

  changeFileExt(event: string) {
    this.instrument.newImageExt = event.substring(event.lastIndexOf('.'));
  }

  changeFileDarkExt(event: string) {
    this.instrument.newImageDarkExt = event.substring(event.lastIndexOf('.'));
  }

  addTranslation() {
    if (!this.instrument.translations) {
      this.instrument.translations = []
    }

    this.instrument.translations.push({languageid: 'en', designation: ''})
  }

  deleteTranslation(translation: InstrumentTitles) {
    if (this.instrument.translations) {
      const index = this.instrument.translations.indexOf(translation);
      this.instrument.translations.splice(index, 1);
    }
  }

  getLanguageDesignation(languageid: string): string {
    const language = this.languages.find(e => e.id==languageid);
    if (language) {
      return language.designation
    }
    return '';
  }

}
