import { Tune, Part, Speed, Tonality, Meter, AuthenticationService } from 'bandon-shared';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TimeInputDirective } from '../../../directives/time-input.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';
import { MidiImportService } from 'bandon-shared';

@Component({
    selector: 'app-arrangement-parts-table',
    templateUrl: './arrangement-parts-table.component.html',
    styleUrls: ['./arrangement-parts-table.component.scss'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatTableModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, TimeInputDirective, MatSelectModule, NgFor, MatOptionModule, MatCheckboxModule, MatIconModule]
})
export class ArrangementPartsTableComponent implements OnInit, OnChanges {

  @Input() tune!: Tune;

  @Input() taktData = false;
  @Input() tempo: Speed | undefined;
  @Input() disabled: boolean = false;

  displayedColumns: string[] = ['bezeichnung', 'start', 'ende', 'pickup', 'meter', 'tempo', 'tonality', 'barnumber', 'showbarslider', 'delete'];

  dataSource = new MatTableDataSource<Part>();

  filteredParts: Part[] = [];

  public tonalities: Tonality[] = [];
  public meters: Meter[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private midiImporter: MidiImportService
  ) {}

  ngOnInit(): void {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    //Load all possible Tonalities
    this.httpClient.get<Tonality[]>(environment.apiURL+'/tonalities', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.tonalities = data;
    });

    //Load all possible Meters
    this.httpClient.get<Meter[]>(environment.apiURL+'/meters', { headers })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async data => {
      this.meters = data;
    });

    this.midiImporter.update$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.ngOnChanges())
  }

  ngOnChanges() {
    if(this.tune.parts) {
      this.sortParts();
      this.filteredParts = this.tune.parts.filter( p => {
        if(this.tempo) {
          if(this.tempo.id>=0 && (this.tempo.id==p.tunespeedid || this.tempo.id==p.speed?.id)) {
            return true;
          } else if(this.tempo.id==-1 && (this.tempo.speed==p.speed?.speed)) {
            return true;
          }
          return false;
        } else if(!this.tempo && !p.speed && !p.tunespeedid) {
          return true;
        }
        return false;
      });
      this.dataSource.data = this.filteredParts;
    }
    this.tune.parts?.forEach(p => {
      if(this.taktData) {
        p.timestart=0
        p.timeend=0
        p.timepickup=0
      } else {
        p.barstart=0
        p.barend=0
        p.pickup=0
      }
    })
  }

  addPart() {
    const newPart: Part = { id:-1, designation: "", barstart: 0, barend: 0, pickup: 0, timestart: 0, timeend: 0, timepickup: 0 };
    if (this.tempo) {
      newPart.speed = this.tempo;
    }
    if(this.tune && !this.tune.parts)
      this.tune.parts = []
    this.tune?.parts?.push(newPart);
    console.log(newPart);
    this.ngOnChanges();
  }

  deletePart(part: Part) {
    if (this.tune && this.tune.parts) {
      const index : number = this.tune.parts.indexOf(part);
      this.tune.parts.splice(index, 1);
      this.ngOnChanges();
    }
  }

  sortAndAddEnds() {
    if(this.tune.parts) {
      let tempoIDs: number[] = [];
      this.tune.parts.forEach(p => {
        if(p.tunespeedid && !tempoIDs.includes(p.tunespeedid)) {
          tempoIDs.push(p.tunespeedid);
        }
      });

      let sortByBeat = this.sortParts();
      this.ngOnChanges();

      if(tempoIDs.length>0) {
        tempoIDs.forEach(ti => this.addEndsForTempo(sortByBeat, ti))
      } else {
        this.addEndsForTempo(sortByBeat)
      }
/*      for (var _i = 0; _i < this.tune.parts.length-1; _i++) {
        var part = this.tune.parts[_i];
        var nextPart = this.tune.parts[_i+1];
        if(sortByBeat && nextPart.barstart) {
          part.barend = Number(nextPart.barstart)-1
        } else if(nextPart.timestart){
          part.timeend = Number(nextPart.timestart);
        }
      }*/
    }
  }

  sortParts() {
    let sortByBeat = false;
    if(this.tune.parts && this.tune.parts[0]) {
      if (this.tune.parts[0].barend>0 || this.tune.parts[0].barstart) {
        sortByBeat = true;
      }
      this.tune.parts = this.tune.parts.sort((p1, p2) => {
        if(p1.tunespeedid && p2.tunespeedid && p1.tunespeedid!==p2.tunespeedid) {
          return p1.tunespeedid-p2.tunespeedid;
        }
        if(sortByBeat) {
          return Number(p1.barstart)-Number(p2.barstart);
        }
        if((p1.timestart || p1.timestart == 0) && (p2.timestart || p2.timestart == 0))
          return p1.timestart-p2.timestart;
        return 0;
      });
    }
    return sortByBeat;
  }

  addEndsForTempo(sortByBeat: boolean, tuneSpeedID = 0) {
    if(this.tune.parts) {
      let parts = this.tune.parts;
      if (tuneSpeedID>0) {
        parts = parts.filter(p => p.tunespeedid===tuneSpeedID);
      }
      for (var _i = 0; _i < parts.length-1; _i++) {
        var part = parts[_i];
        var nextPart = parts[_i+1];
        if(sortByBeat && nextPart.barstart) {
          part.barend = Number(nextPart.barstart)-1
        } else if(nextPart.timestart){
          part.timeend = Number(nextPart.timestart);
        }
      }
    }
  }

  getNominator(nn: number) {
    return Math.pow(2,nn);
  }

  midiTest(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.midiImporter.importMidi(file, this.tune);
    }

    this.ngOnChanges();
  }

}
