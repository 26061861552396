import { Component } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-saving-template',
    templateUrl: './saving-template.component.html',
    styleUrls: ['./saving-template.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatProgressSpinnerModule,
    ],
})
export class SavingTemplateComponent {
  constructor(public dialogRef: MatDialogRef<SavingTemplateComponent>) {}
}
