import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, takeUntil } from 'rxjs';
import { Collection, ShopWindow, Photo, AuthenticationService } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { transliterate } from 'transliteration';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ImgUploadComponent } from '../../../components/general/img-upload/img-upload.component';
import { NgFor } from '@angular/common';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-shop-windows',
    templateUrl: './shop-windows.component.html',
    styleUrls: ['./shop-windows.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, NgFor, ImgUploadComponent, MatFormFieldModule, MatSelectModule, MatOptionModule, MatButtonModule, MatIconModule]
})
export class ShopWindowsComponent implements OnInit, OnDestroy {

  shopWindows: ShopWindow[] = [];
  collections: Collection[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    //Get all Collections
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Collection[]>(environment.apiURL+"/collections", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (collections) => {
          console.log(collections);
          this.collections.length = 0;
          this.collections.push(...collections);
          loadingDialog.close();
        }
      );

    this.updateWindowsList();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateWindowsList(): void {
    //Get all Tunes
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<ShopWindow[]>(environment.apiURL+"/shop/windows", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (windows) => {
          this.shopWindows.length = 0;
          this.shopWindows.push(...windows);
          for(const window of this.shopWindows) {
            if(window.link) {
              window.collectionid = Number(window.link.substring(window.link.lastIndexOf('/')+1));
            }
          }
          console.log(this.shopWindows);
        }
      );
  }

  addWindow() {
    const data = {
      imgid: 'NULL',
      link: ''
    };

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    this.httpClient.put(environment.apiURL+`/shop/windows`, data, {headers})
    .subscribe(res => {
      console.log(res);
      this.updateWindowsList();
    });

  }

  changeWindow(window: ShopWindow): void {
    console.log(window);
    let imgid = 'NULL'
    if(window.imgid) {
      imgid = String(window.imgid);
    } else if(window.picture) {
      imgid = String(window.picture.id);
    }
    const data = {
      imgid: imgid,
      link: 'collection/'+window.collectionid
    };

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    this.httpClient.post(environment.apiURL+`/shop/windows/${window.id}`, data, {headers})
    .subscribe(res => {
      this._snackBar.open('Schaufenster wurde angepasst', 'ok')
    });
  }

  deleteWindow(window: ShopWindow) {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    this.httpClient.delete(environment.apiURL+`/shop/windows/${window.id}`, {headers})
    .subscribe({
      next: (resp) => {
        this._snackBar.open('Schaufenster wurde gelöscht', 'ok')
        this.updateWindowsList();
      },
      error: (error) => {
        this._snackBar.open('Schaufenster konnte nicht gelöscht werden. Vermutlich wird er bei Stücken verwendet.', 'ok')
      }
    });
  }

  windowImgPath(window: ShopWindow) {
    if(window.picture) {
      return environment.apiURL+"/photoImg/"+window.picture.id;
    }
    return '';
  }

  updateImg(window: ShopWindow, image: Blob) {
    window.newImage = image;
    console.log('Image updated');

    const photo: Photo = {
      id: -1,
      path: 'Marketing/shopWindows/'+window.newImageExt,
      authorid: -1,
      author: {id: -1, surname: '', firstname: '', instrument: { id: -1, designation: '', img: '', img_dark: '', instrumentorder: 1}},
      date: new Date(),
      newImage: image,
      imgSaveFilename: window.newImageExt
    }
    this.commitPhoto(window, photo);
  }

  commitPhoto(window: ShopWindow, photo: Photo) {
    this._snackBar.open('Speichert Daten')
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    const formData = new FormData();
    if(photo.newImage) {
      let filename = transliterate(photo.imgSaveFilename!)
      formData.append('img', photo.newImage, filename);
      photo.newImage = undefined;

      let newPath = photo.path.slice(0, photo.path.lastIndexOf('/')+1).concat(filename);
      photo.path = newPath;
    }

    formData.append('photo', JSON.stringify(photo));

    if(photo.id>0) {
      this.httpClient.post<Photo>(environment.apiURL+`/photos/${photo.id}`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this._snackBar.dismiss();
          window.picture = resp;
          window.imgid = resp.id
          this.changeWindow(window);
          },
        error: error => {
          console.log(error);
          this._snackBar.dismiss();
          this.updateWindowsList();
          }
      });
    } else {
      this.httpClient.put<Photo>(environment.apiURL+`/photos`, formData, {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: resp => {
          this._snackBar.dismiss();
          window.picture = resp;
          window.imgid = resp.id
          this.changeWindow(window);
//          this.updateWindowsList();
          },
        error: error => {
          console.log(error);
          this._snackBar.dismiss();
          this.updateWindowsList();
          }
      });
    }
  }

  updateImgExt(window: ShopWindow, fileExt: string) {
    window.newImageExt = fileExt;
  }

  updateImgId(window: ShopWindow, id: number) {
    window.imgid = id;
    window.newImage = undefined;
    this.changeWindow(window);
  }

}
