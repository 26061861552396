import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService, Style } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoadingTemplateComponent } from 'src/app/components/ModalTemplates/loading-template/loading-template.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MainMenuComponent } from '../../../components/navigation/main-menu/main-menu.component';

@Component({
    selector: 'app-styles-list',
    templateUrl: './styles-list.component.html',
    styleUrls: ['./styles-list.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, MatListModule, NgFor, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule]
})
export class StylesListComponent {
  public styles: Style[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.updateStylesList();
  }

  private updateStylesList(): void {
    //Get all Tunes
    const loadingDialog = this.dialog.open(LoadingTemplateComponent)
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<Style[]>(environment.apiURL+"/styles", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (tunes) => {
          this.styles.length = 0;
          this.styles.push(...tunes);
          loadingDialog.close();
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addStyle() {
    const data = {
      designation: ''
    };

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    this.httpClient.put(environment.apiURL+`/styles`, data, {headers})
    .subscribe(res => {
      console.log(res);
      this.updateStylesList();
    });

  }

  changeStyle(style: Style, event: any): void {
    if(event.target.value) {
      const data = {
        designation: event.target.value
      };

      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      this.httpClient.post(environment.apiURL+`/styles/${style.id}`, data, {headers})
      .subscribe(res => {
        this._snackBar.open('Style wurde angepasst', 'ok')
      });
    }
  }

  deleteStyle(style: Style) {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    this.httpClient.delete(environment.apiURL+`/styles/${style.id}`, {headers})
    .subscribe({
      next: (resp) => {
        this._snackBar.open('Style wurde gelöscht', 'ok')
        this.updateStylesList();
      },
      error: (error) => {
        this._snackBar.open('Style konnte nicht gelöscht werden. Vermutlich wird er bei Stücken verwendet.', 'ok')
      }
    });
}
}
